import React, { ChangeEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';

interface TextFieldProps {
  placeholder: string;
}

type FieldProps<T extends FieldValues> = UseControllerProps<T> & TextFieldProps;

const TextField = <T extends FieldValues>(props: FieldProps<T>) => {
  const { name, control, rules, ...rest } = props;
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules,
  });

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(event.target.value);
  };

  const { i18n } = useTranslation();

  return (
    <>
      <StyledTextField value={field.value || ''} onChange={handleChange} {...rest} />
      <Error $isVisible={!!fieldState.error} $language={i18n.language}>
        {fieldState.error && fieldState.error.message}
      </Error>
    </>
  );
};

const StyledTextField = styled.textarea`
  background-color: ${(props) => props.theme.colors.lightBckg};
  padding: 16px 20px;
  border-radius: 24px;
  color: ${(props) => props.theme.colors.accent};
  resize: none;
  border: none;
  min-height: 124px;
  :focus {
    outline: none;
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    min-height: 140px;
  }
`;

const Error = styled.div<{ $isVisible: boolean; $language: string }>`
  color: ${({ theme: { colors } }) => colors.red};
  font-size: ${(props) => props.theme.fonts.desktop[props.$language].smallText.fontSize};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  height: 16px;

  @media only screen and ${deviceBreakpoints.mobile} {
    font-size: ${(props) => props.theme.fonts.mobile[props.$language].smallText.fontSize};
  }
`;

export default TextField;
