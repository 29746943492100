import React from 'react';

const MonitorIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M53.3334 8H10.6667C7.70671 8 5.33337 10.3733 5.33337 13.3333V45.3333C5.33337 46.7478 5.89528 48.1044 6.89547 49.1046C7.89566 50.1048 9.25222 50.6667 10.6667 50.6667H21.3334V56H42.6667V50.6667H53.3334C56.2667 50.6667 58.6667 48.2667 58.6667 45.3333V13.3333C58.6667 11.9188 58.1048 10.5623 57.1046 9.5621C56.1044 8.5619 54.7479 8 53.3334 8ZM53.3334 45.3333H10.6667V13.3333H53.3334V45.3333Z'
        fill='url(#paint0_linear_1640_3094)'
      />
      <path
        d='M16 21.9998H37.3333V25.9998H16V21.9998ZM44 25.9998H48V21.9998H44V18.6665H40V29.3332H44V25.9998ZM26.6667 32.6665H48V36.6665H26.6667V32.6665ZM20 39.9998H24V29.3332H20V32.6665H16V36.6665H20V39.9998Z'
        fill='url(#paint1_linear_1640_3094)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1640_3094'
          x1='61.9355'
          y1='49.7931'
          x2='-22.5335'
          y2='37.2447'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1640_3094'
          x1='49.9613'
          y1='37.2412'
          x2='0.164288'
          y2='27.2544'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MonitorIcon;
