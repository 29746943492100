import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import backgroundImage from '../../../assets/productImages/realStt/speechToTextBox.png';
import CloseIcon from '../../../icons/Close';
import SheetMicrophoneIcon from '../../../icons/SheetMicrophone';
import { deviceBreakpoints } from '../../../Theme';
import AudioPlayer from '../../atoms/AudioPlayer/AudioPlayer';
import Spinner from '../../atoms/Spinner/Spinner';
import Text from '../../atoms/Text/Text';
import Transcript from '../Transcript/Transcript';

const SpeechToTextBox: React.FC = () => {
  const [transcriptIsVisible, setTranscriptIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasPlayedOnce, setHasPlayedOnce] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { t } = useTranslation(['products']);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setTranscriptIsVisible(true);
        setIsLoading(false);
      }, 1500);
    }
  }, [isLoading]);

  return (
    <SpeechToTextBoxWrapper $showBackgroundImage={transcriptIsVisible}>
      <Header>
        <Text type='h3'>{t('realStt.mainAudioExampleTitle')}</Text>
        {transcriptIsVisible && (
          <div onClick={() => setTranscriptIsVisible(false)}>
            <CloseIcon size={windowWidth < 1024 ? 'small' : 'default'} />
          </div>
        )}
      </Header>
      <AudioPlayer />

      <TranscribeButton
        onClick={() => {
          if (!hasPlayedOnce) {
            setHasPlayedOnce(true);
            setIsLoading(true);
          } else {
            setTranscriptIsVisible(true);
          }
        }}
      >
        <SheetMicrophoneIcon size='small' />
        <Text type='link'>Transcribe File</Text>
      </TranscribeButton>
      {isLoading && <Spinner />}
      {transcriptIsVisible && !isLoading && <Transcript />}
    </SpeechToTextBoxWrapper>
  );
};

const SpeechToTextBoxWrapper = styled.div<{ $showBackgroundImage: boolean }>`
  ${(props) =>
    props.$showBackgroundImage &&
    `   background-image: url(${backgroundImage});
        background-position-y: bottom;
        background-repeat: no-repeat;
    `}

  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 24px;
  background-color: ${(props) => props.theme.colors.lightBckg};
  box-shadow: 0px 16px 32px rgba(79, 82, 255, 0.2);
  border-radius: 40px;
  width: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    border-radius: 24px;
    padding: 16px 15px 24px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TranscribeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.lightBckg};
  padding: 8px 16px;
  cursor: pointer;
`;

export default SpeechToTextBox;
