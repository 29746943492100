import React from 'react';

const LockIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M33.872 5.853L52.5387 12.853C53.5555 13.2342 54.4317 13.9166 55.0503 14.8091C55.6688 15.7017 56.0001 16.7618 56 17.8477V32.149C56.0001 36.6061 54.759 40.9752 52.4158 44.7667C50.0726 48.5582 46.7199 51.6223 42.7333 53.6157L33.7893 58.0877C33.2338 58.3655 32.6212 58.5102 32 58.5102C31.3788 58.5102 30.7662 58.3655 30.2107 58.0877L21.2667 53.6157C17.2801 51.6223 13.9274 48.5582 11.5842 44.7667C9.24099 40.9752 7.9999 36.6061 8 32.149V17.8477C7.99986 16.7618 8.33121 15.7017 8.94974 14.8091C9.56827 13.9166 10.4445 13.2342 11.4613 12.853L30.128 5.853C31.335 5.40056 32.665 5.40056 33.872 5.853ZM32 10.8477L13.3333 17.8477V32.149C13.3339 35.6153 14.2997 39.013 16.1224 41.9614C17.9452 44.9098 20.5528 47.2924 23.6533 48.8423L32 53.021L40.3467 48.8477C43.448 47.2973 46.0562 44.9139 47.879 41.9644C49.7017 39.015 50.667 35.6162 50.6667 32.149V17.8477L32 10.8477ZM32 21.333C33.1383 21.3325 34.2469 21.6963 35.1637 22.371C36.0804 23.0458 36.7572 23.9962 37.0951 25.0832C37.433 26.1702 37.4143 27.3368 37.0416 28.4123C36.669 29.4879 35.962 30.416 35.024 31.061L34.6667 31.2877V39.9997C34.6659 40.6793 34.4057 41.3331 33.9391 41.8273C33.4725 42.3215 32.8348 42.619 32.1563 42.6588C31.4778 42.6986 30.8097 42.4779 30.2884 42.0416C29.7672 41.6054 29.4323 40.9866 29.352 40.3117L29.3333 39.9997V31.2877C28.3153 30.7012 27.5194 29.795 27.0693 28.7098C26.6191 27.6246 26.5399 26.4211 26.8439 25.2863C27.1479 24.1514 27.8181 23.1487 28.7505 22.4339C29.6828 21.719 30.8251 21.332 32 21.333Z'
        fill='url(#paint0_linear_1855_41711)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1855_41711'
          x1='58.9419'
          y1='51.6572'
          x2='-17.6349'
          y2='42.3841'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LockIcon;
