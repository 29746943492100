import React from 'react';

const PlayIcon: React.FC = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 3.76619C5 2.9889 5.84797 2.50878 6.5145 2.9087L13.5708 7.14251C14.2182 7.53091 14.2182 8.46909 13.5708 8.85749L6.5145 13.0913C5.84797 13.4912 5 13.0111 5 12.2338V3.76619Z'
        fill='white'
      />
    </svg>
  );
};

export default PlayIcon;
