import React from 'react';

const OperationsIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29.5335 39.8667L34.4668 33.1334L39.3335 39.8667L48.0002 28.0667L43.7335 24.9334L39.3335 30.8667L34.4002 24.1334L29.5335 30.8667L24.6002 24.1334L16.0002 35.9334L20.2668 39.0667L24.6002 33.1334L29.5335 39.8667ZM32.0002 58.6667C28.3113 58.6667 24.8446 57.9663 21.6002 56.5654C18.3557 55.1645 15.5335 53.2649 13.1335 50.8667C10.7335 48.4667 8.83394 45.6445 7.43483 42.4C6.03572 39.1556 5.33527 35.6889 5.3335 32C5.3335 28.3112 6.03394 24.8445 7.43483 21.6C8.83572 18.3556 10.7353 15.5334 13.1335 13.1334C15.5335 10.7334 18.3557 8.83382 21.6002 7.43471C24.8446 6.0356 28.3113 5.33515 32.0002 5.33337C35.6891 5.33337 39.1557 6.03382 42.4002 7.43471C45.6446 8.8356 48.4668 10.7352 50.8668 13.1334C53.2668 15.5334 55.1673 18.3556 56.5682 21.6C57.9691 24.8445 58.6686 28.3112 58.6668 32C58.6668 35.6889 57.9664 39.1556 56.5655 42.4C55.1646 45.6445 53.2651 48.4667 50.8668 50.8667C48.4668 53.2667 45.6446 55.1672 42.4002 56.568C39.1557 57.9689 35.6891 58.6685 32.0002 58.6667ZM32.0002 53.3334C37.9557 53.3334 43.0002 51.2667 47.1335 47.1334C51.2668 43 53.3335 37.9556 53.3335 32C53.3335 26.0445 51.2668 21 47.1335 16.8667C43.0002 12.7334 37.9557 10.6667 32.0002 10.6667C26.0446 10.6667 21.0002 12.7334 16.8668 16.8667C12.7335 21 10.6668 26.0445 10.6668 32C10.6668 37.9556 12.7335 43 16.8668 47.1334C21.0002 51.2667 26.0446 53.3334 32.0002 53.3334Z'
        fill='url(#paint0_linear_2071_46231)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2071_46231'
          x1='61.9357'
          y1='51.7702'
          x2='-22.8813'
          y2='40.4301'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default OperationsIcon;
