import React from 'react';
import styled from 'styled-components';

interface DropdownItemProps {
  itemValue: string | React.ReactNode;
  isSelected: boolean;
  isDefault?: boolean;
  delayAnimation: number;

  onClickAction: () => void;
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  itemValue,
  isSelected,
  onClickAction,
  delayAnimation,
}) => {
  return (
    <DropdownItemWrapper
      delayAnimation={delayAnimation}
      isSelected={isSelected}
      onClick={() => onClickAction()}
    >
      {itemValue}
    </DropdownItemWrapper>
  );
};

const DropdownItemWrapper = styled.div<Partial<DropdownItemProps>>`
  display: flex;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.lightBckg : props.theme.colors.transparent};
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: ${(props) => (props.isSelected ? props.theme.colors.purple : props.theme.colors.text)};

  animation: flyIn ${(props) => props.delayAnimation}s linear;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBckg};
    color: ${(props) => props.theme.colors.purple};
  }

  @keyframes flyIn {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`;

export default DropdownItem;
