import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import blogLogo from '../../../assets/sns/blog.png';
import facebookLogo from '../../../assets/sns/facebook.png';
import instagramLogo from '../../../assets/sns/instagram.png';
import Text from '../../atoms/Text/Text';

interface VerticalMenuProps {
  closeMenu: () => void;
}

const VerticalMenu: React.FC<VerticalMenuProps> = ({ closeMenu }) => {
  const { t } = useTranslation(['main']);

  return (
    <VerticalMenuWrapper>
      <MenuItem>
        <Text type='link' color='text'>
          {t('products')}
        </Text>
        <SubMenu>
          <SubMenuItem
            href='/products/real-gpt'
            onClick={() => {
              closeMenu();
            }}
          >
            <Text type='text'>Real-GPT</Text>
            <Text type='text' color='textLight'>
              Generative Pre-trained Transformer
            </Text>
          </SubMenuItem>

          <SubMenuItem
            href='/products/real-stt'
            onClick={() => {
              closeMenu();
            }}
          >
            <Text type='text'>Real-STT</Text>
            <Text type='text' color='textLight'>
              Speech-To-Text
            </Text>
          </SubMenuItem>

          <SubMenuItem
            href='/products/real-sid'
            onClick={() => {
              closeMenu();
            }}
          >
            <Text type='text'>Real-SID</Text>
            <Text type='text' color='textLight'>
              Speaker Identification
            </Text>
          </SubMenuItem>

          <SubMenuItem
            href='/products/real-fds'
            onClick={() => {
              closeMenu();
            }}
          >
            <Text type='text'>Real-FDS</Text>
            <Text type='text' color='textLight'>
              Fraud Detection System
            </Text>
          </SubMenuItem>

          <SubMenuItem
            href='/products/real-si'
            onClick={() => {
              closeMenu();
            }}
          >
            <Text type='text'>Real-SI</Text>
            <Text type='text' color='textLight'>
              System Integration
            </Text>
          </SubMenuItem>
        </SubMenu>
      </MenuItem>
      <MenuItem
        href='/about-us'
        onClick={() => {
          closeMenu();
        }}
      >
        <Text type='link' color='text'>
          {t('aboutUs')}
        </Text>
      </MenuItem>
      <MenuItemIconWrapper>
        <img
          width={24}
          height={24}
          src={blogLogo}
          onClick={() => window.open('https://blog.naver.com/darwinai_100', '_blank')}
        />
        <img
          width={24}
          height={24}
          src={instagramLogo}
          onClick={() => window.open('https://www.instagram.com/darwinaiintelligence/', '_blank')}
        />
        <img
          width={24}
          height={24}
          src={facebookLogo}
          onClick={() => window.open('https://facebook.com/darwinintelligence', '_blank')}
        />
      </MenuItemIconWrapper>
    </VerticalMenuWrapper>
  );
};

const VerticalMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
`;

const MenuItem = styled.a`
  width: 100%;
  padding: 24px;
  border-top: ${(props) => `1px solid ${props.theme.colors.lightBckg}`};
  border-bottom: ${(props) => `1px solid ${props.theme.colors.lightBckg}`};
  text-decoration: none;
`;

const MenuItemIconWrapper = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
  padding: 24px;
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;

const SubMenuItem = styled.a`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-left: 12px;
  text-decoration: none;
`;

export default VerticalMenu;
