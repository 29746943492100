import React from 'react';

const SpeechBubbleIcon: React.FC = () => {
  return (
    <svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.666648 27C0.666648 12.272 12.6053 0.333344 27.3333 0.333344C42.0613 0.333344 54 12.272 54 27C54 41.728 42.0613 53.6667 27.3333 53.6667H0.666648L8.47731 45.856C5.99741 43.3825 4.03074 40.4434 2.69033 37.2074C1.34992 33.9715 0.662182 30.5026 0.666648 27ZM13.5413 48.3333H27.3333C31.5526 48.3333 35.6772 47.0822 39.1855 44.738C42.6937 42.3939 45.4281 39.0621 47.0427 35.1639C48.6574 31.2658 49.0799 26.9763 48.2567 22.8381C47.4336 18.6998 45.4018 14.8986 42.4183 11.9151C39.4347 8.93155 35.6335 6.89974 31.4952 6.07659C27.357 5.25344 23.0676 5.67591 19.1694 7.29058C15.2712 8.90525 11.9394 11.6396 9.5953 15.1478C7.25116 18.6561 5.99998 22.7807 5.99998 27C5.99998 32.7387 8.26931 38.1067 12.248 42.0853L16.0186 45.856L13.5413 48.3333ZM24.6666 11H30V43H24.6666V11ZM14 19H19.3333V35H14V19ZM35.3333 19H40.6666V35H35.3333V19Z'
        fill='url(#paint0_linear_202_1026)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_202_1026'
          x1='57.2688'
          y1='46.7701'
          x2='2.48988'
          y2='39.8898'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SpeechBubbleIcon;
