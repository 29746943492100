import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';
import DropdownItem from '../../atoms/DropdownItem/DropdownItem';
import DropdownItemWithIcon from '../../atoms/DropdownItemWithIconItem/DropdownWithIconItem';
import Text from '../../atoms/Text/Text';

export type DropdownItemType = {
  key: string;
  value: string | React.ReactNode;
  text?: string;
  url?: string;
};

interface DropdownProps {
  items: DropdownItemType[];
  currentlySelectedItem?: DropdownItemType;
  setCurrentlySelectedItem?: (value: DropdownItemType) => void;
  withIcon?: boolean;
  title?: string;
}

const Dropdown: React.FC<DropdownProps & { className?: string }> = ({
  items,
  currentlySelectedItem,
  setCurrentlySelectedItem,
  className,
  withIcon = false,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <DropdownWrapper ref={ref} isOpen={isOpen} className={className} withIcon={withIcon}>
      {withIcon ? (
        <>
          <DropdownItemWithIcon
            delayAnimation={0}
            itemValue={currentlySelectedItem?.value || ''}
            itemText={currentlySelectedItem?.text || ''}
            isDefault
            isSelected={false}
            isCentered
            onClickAction={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
          />

          {isOpen && (
            <ItemWrapper withIcon={withIcon}>
              {items.map((item, index) => (
                <DropdownItemWithIcon
                  delayAnimation={(index / 10) * 2 + 0.2}
                  key={item.key}
                  itemValue={item.value}
                  itemText={item.text}
                  isSelected={
                    currentlySelectedItem ? currentlySelectedItem.key === item.key : false
                  }
                  isCentered={false}
                  onClickAction={() => {
                    setCurrentlySelectedItem && setCurrentlySelectedItem(item);

                    setIsOpen(false);
                  }}
                  isOpen={isOpen}
                />
              ))}
            </ItemWrapper>
          )}
        </>
      ) : (
        <>
          <DropdownItemWithIcon
            delayAnimation={0}
            itemValue={<Text type='link'>{title}</Text>}
            isDefault
            isSelected={false}
            isCentered
            onClickAction={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
          />

          {isOpen && (
            <ItemWrapper withIcon={withIcon}>
              {items.map((item, index) => (
                <DropdownItem
                  delayAnimation={(index / 10) * 2 + 0.5}
                  key={item.key}
                  itemValue={item.value}
                  isSelected={
                    currentlySelectedItem ? currentlySelectedItem.key === item.key : false
                  }
                  onClickAction={() => {
                    if (item.url) {
                      window.open(item.url, '_blank');
                    }
                    setCurrentlySelectedItem && setCurrentlySelectedItem(item);
                    setIsOpen(false);
                  }}
                />
              ))}
            </ItemWrapper>
          )}
        </>
      )}
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.div<{ isOpen: boolean; withIcon: boolean }>`
  position: relative;

  @media only screen and ${deviceBreakpoints.mobile} {
    position: relative;
    right: unset;
  }
`;

const ItemWrapper = styled.div<{ withIcon: boolean }>`
  position: absolute;
  height: max-content;
  background-color: #ffffff;
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  padding: ${(props) => (props.withIcon ? '4px' : '0')};
  z-index: 10;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  overflow: hidden;
`;

export default Dropdown;
