import React from 'react';

const ShoppingCartIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1861_57548)'>
        <path
          d='M18.667 53.6667C15.7337 53.6667 13.3603 56.0667 13.3603 59C13.3603 61.9333 15.7337 64.3333 18.667 64.3333C21.6003 64.3333 24.0003 61.9333 24.0003 59C24.0003 56.0667 21.6003 53.6667 18.667 53.6667ZM45.3337 53.6667C42.4003 53.6667 40.027 56.0667 40.027 59C40.027 61.9333 42.4003 64.3333 45.3337 64.3333C48.267 64.3333 50.667 61.9333 50.667 59C50.667 56.0667 48.267 53.6667 45.3337 53.6667ZM21.0304 41.3695C21.3819 40.7304 22.0535 40.3333 22.7829 40.3333H41.467C43.467 40.3333 45.227 39.24 46.1337 37.5867L55.4631 20.6438C55.9956 19.6768 55.6439 18.4613 54.6773 17.928L53.5366 17.2986C52.5701 16.7654 51.3543 17.1161 50.8202 18.0821L42.0377 33.9677C41.6856 34.6046 41.0152 35 40.2874 35H24.0131C23.24 35 22.5361 34.5544 22.2053 33.8557L11.9286 12.1443C11.5979 11.4456 10.894 11 10.1209 11H4.66699C3.56242 11 2.66699 11.8954 2.66699 13V14.3333C2.66699 15.4379 3.56242 16.3333 4.66699 16.3333H6.73538C7.5079 16.3333 8.21135 16.7782 8.54242 17.4762L17.1605 35.646C17.4366 36.2282 17.4154 36.9076 17.1035 37.4714L14.0003 43.08C12.0537 46.6533 14.6137 51 18.667 51H48.667C49.7716 51 50.667 50.1046 50.667 49V47.6667C50.667 46.5621 49.7716 45.6667 48.667 45.6667H22.0495C20.5283 45.6667 19.564 44.0357 20.2971 42.7028L21.0304 41.3695Z'
          fill='url(#paint0_linear_1861_57548)'
        />
        <path
          d='M34.4347 26.0987C34.056 26.4773 33.5778 26.6667 33 26.6667C32.4222 26.6667 31.9449 26.4773 31.568 26.0987C31.1893 25.7218 31 25.2444 31 24.6667V2C31 1.42222 31.1893 0.944889 31.568 0.568C31.9449 0.189333 32.4222 0 33 0C33.5778 0 34.056 0.189333 34.4347 0.568C34.8116 0.944889 35 1.42222 35 2V24.6667C35 25.2444 34.8116 25.7218 34.4347 26.0987Z'
          fill='url(#paint1_linear_1861_57548)'
        />
        <path
          d='M27.1013 20.7653C26.7227 21.144 26.2444 21.3333 25.6667 21.3333C25.0889 21.3333 24.6116 21.144 24.2347 20.7653C23.856 20.3884 23.6667 19.9111 23.6667 19.3333V7.33333C23.6667 6.75556 23.856 6.27822 24.2347 5.90133C24.6116 5.52267 25.0889 5.33333 25.6667 5.33333C26.2444 5.33333 26.7227 5.52267 27.1013 5.90133C27.4782 6.27822 27.6667 6.75556 27.6667 7.33333V19.3333C27.6667 19.9111 27.4782 20.3884 27.1013 20.7653Z'
          fill='url(#paint2_linear_1861_57548)'
        />
        <path
          d='M20.432 15.432C20.0551 15.8107 19.5778 16 19 16C18.4222 16 17.9449 15.8107 17.568 15.432C17.1893 15.0551 17 14.5778 17 14V12.6667C17 12.0889 17.1893 11.6107 17.568 11.232C17.9449 10.8551 18.4222 10.6667 19 10.6667C19.5778 10.6667 20.0551 10.8551 20.432 11.232C20.8107 11.6107 21 12.0889 21 12.6667V14C21 14.5778 20.8107 15.0551 20.432 15.432Z'
          fill='url(#paint3_linear_1861_57548)'
        />
        <path
          d='M41.768 20.7653C41.3893 21.144 40.9111 21.3333 40.3333 21.3333C39.7556 21.3333 39.2782 21.144 38.9013 20.7653C38.5227 20.3884 38.3333 19.9111 38.3333 19.3333V7.33333C38.3333 6.75556 38.5227 6.27822 38.9013 5.90133C39.2782 5.52267 39.7556 5.33333 40.3333 5.33333C40.9111 5.33333 41.3893 5.52267 41.768 5.90133C42.1449 6.27822 42.3333 6.75556 42.3333 7.33333V19.3333C42.3333 19.9111 42.1449 20.3884 41.768 20.7653Z'
          fill='url(#paint4_linear_1861_57548)'
        />
        <path
          d='M48.4347 15.432C48.056 15.8107 47.5778 16 47 16C46.4222 16 45.944 15.8107 45.5653 15.432C45.1884 15.0551 45 14.5778 45 14V12.6667C45 12.0889 45.1884 11.6107 45.5653 11.232C45.944 10.8551 46.4222 10.6667 47 10.6667C47.5778 10.6667 48.056 10.8551 48.4347 11.232C48.8116 11.6107 49 12.0889 49 12.6667V14C49 14.5778 48.8116 15.0551 48.4347 15.432Z'
          fill='url(#paint5_linear_1861_57548)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1861_57548'
          x1='59.722'
          y1='57.4368'
          x2='-25.7494'
          y2='45.9178'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1861_57548'
          x1='50.9613'
          y1='23.2184'
          x2='0.46135'
          y2='15.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1861_57548'
          x1='50.9613'
          y1='23.2184'
          x2='0.46135'
          y2='15.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1861_57548'
          x1='50.9613'
          y1='23.2184'
          x2='0.46135'
          y2='15.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1861_57548'
          x1='50.9613'
          y1='23.2184'
          x2='0.46135'
          y2='15.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_1861_57548'
          x1='50.9613'
          y1='23.2184'
          x2='0.46135'
          y2='15.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <clipPath id='clip0_1861_57548'>
          <rect width='64' height='64' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShoppingCartIcon;
