import React from 'react';

const ClockIcon: React.FC = () => {
  return (
    <svg width='59' height='44' viewBox='0 0 59 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.3332 0.458496C42.9912 0.458496 48.4174 2.70611 52.4182 6.70689C56.4189 10.7077 58.6665 16.1339 58.6665 21.7918C58.6665 27.4498 56.4189 32.876 52.4182 36.8768C48.4174 40.8776 42.9912 43.1252 37.3332 43.1252C31.6753 43.1252 26.249 40.8776 22.2483 36.8768C18.2475 32.876 15.9999 27.4498 15.9999 21.7918C15.9999 16.1339 18.2475 10.7077 22.2483 6.70689C26.249 2.70611 31.6753 0.458496 37.3332 0.458496ZM37.3332 5.79183C33.0897 5.79183 29.0201 7.47754 26.0195 10.4781C23.0189 13.4787 21.3332 17.5484 21.3332 21.7918C21.3332 26.0353 23.0189 30.105 26.0195 33.1055C29.0201 36.1061 33.0897 37.7918 37.3332 37.7918C41.5767 37.7918 45.6463 36.1061 48.6469 33.1055C51.6475 30.105 53.3332 26.0353 53.3332 21.7918C53.3332 17.5484 51.6475 13.4787 48.6469 10.4781C45.6463 7.47754 41.5767 5.79183 37.3332 5.79183ZM34.6665 11.1252H38.6665V21.2052L44.8799 27.4185L42.0532 30.2452L34.6665 22.8585V11.1252ZM2.66654 37.7918C1.9593 37.7918 1.28102 37.5109 0.780926 37.0108C0.280829 36.5107 -0.00012207 35.8324 -0.00012207 35.1252C-0.00012207 34.4179 0.280829 33.7396 0.780926 33.2395C1.28102 32.7394 1.9593 32.4585 2.66654 32.4585H12.8799C13.7065 34.3518 14.7732 36.1385 15.9999 37.7918H2.66654ZM5.33321 24.4585C4.62597 24.4585 3.94769 24.1775 3.44759 23.6774C2.9475 23.1774 2.66654 22.4991 2.66654 21.7918C2.66654 21.0846 2.9475 20.4063 3.44759 19.9062C3.94769 19.4061 4.62597 19.1252 5.33321 19.1252H10.7999L10.6665 21.7918L10.7999 24.4585H5.33321ZM7.99988 11.1252C7.29263 11.1252 6.61436 10.8442 6.11426 10.3441C5.61416 9.84402 5.33321 9.16574 5.33321 8.4585C5.33321 7.75125 5.61416 7.07298 6.11426 6.57288C6.61436 6.07278 7.29263 5.79183 7.99988 5.79183H15.9999C14.7732 7.44516 13.7065 9.23183 12.8799 11.1252H7.99988Z'
        fill='url(#paint0_linear_1234_1661)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1234_1661'
          x1='62.2622'
          y1='37.6079'
          x2='-29.5996'
          y2='20.7202'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ClockIcon;
