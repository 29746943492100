import React from 'react';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';
import Button from '../../atoms/Button/Button';
import Text from '../../atoms/Text/Text';

interface ProductDescriptionProps {
  title: string;
  subtitle: string;
  description: string;
  extendedDescription: string;
  buttonText: string;
  buttonOnClickAction: () => void;
  imageSrc: string;
  className?: string;
  isVisible: boolean;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({
  title,
  subtitle,
  description,
  extendedDescription,
  buttonText,
  buttonOnClickAction,
  imageSrc,
  className,
  isVisible,
}) => {
  return (
    <ProductDescriptionWrapper className={className} $isVisible={isVisible}>
      <ImageWrapper>
        <StyledImg src={imageSrc} width='240' height='240' />
      </ImageWrapper>
      <Wrapper>
        <TextWrapper>
          <Text type='xlText' color='lightBlue'>
            {subtitle}
          </Text>
          <TitleWrapper>
            <Text type='h2'>{title}</Text>
          </TitleWrapper>
          <Text type='xlText'>{description}</Text>
        </TextWrapper>

        <ExtendedDescriptionWrapper>
          <Text type='text'>{extendedDescription}</Text>
        </ExtendedDescriptionWrapper>
        <TitleButtonWrapper>
          <Button buttonType='regular' text={buttonText} onClickAction={buttonOnClickAction} />
        </TitleButtonWrapper>
      </Wrapper>
    </ProductDescriptionWrapper>
  );
};

const ProductDescriptionWrapper = styled.div<{ $isVisible: boolean }>`
  ${(props) => !props.$isVisible && 'visibility: hidden;'}
`;

const ImageWrapper = styled.div`
  position: absolute;
  right: 5%;
  top: 16px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;
const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`;

const Wrapper = styled.div``;

const TextWrapper = styled.div`
  max-width: 70%;
  align-self: end;

  @media only screen and ${deviceBreakpoints.mobile} {
    max-width: 100%;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 32px;
  margin-top: 8px;
`;

const TitleButtonWrapper = styled.div`
  margin-bottom: 32px;
  margin-top: 32px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 16px;
    margin-top: 16px;
  }
`;

const ExtendedDescriptionWrapper = styled.div`
  padding-right: 32px;
  margin-top: 32px;
  max-width: 70%;

  @media only screen and ${deviceBreakpoints.mobile} {
    max-width: 100%;
  }
`;

export default ProductDescription;
