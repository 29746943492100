import React from 'react';

const EnhancedGraphIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.6665 24L19.7332 17.6L13.3332 14.6667L19.7332 11.7334L22.6665 5.33337L25.5998 11.7334L31.9998 14.6667L25.5998 17.6L22.6665 24ZM39.9998 32L37.4665 26.5334L31.9998 24L37.4665 21.4667L39.9998 16L42.5332 21.4667L47.9998 24L42.5332 26.5334L39.9998 32ZM10.6665 37.3334L8.13317 31.8667L2.6665 29.3334L8.13317 26.8L10.6665 21.3334L13.1998 26.8L18.6665 29.3334L13.1998 31.8667L10.6665 37.3334ZM11.9998 54.6667L7.99984 50.6667L27.9998 30.6667L38.6665 41.3334L57.5998 20.0667L61.3332 23.8L38.6665 49.3334L27.9998 38.6667L11.9998 54.6667Z'
        fill='url(#paint0_linear_2068_46249)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2068_46249'
          x1='64.9289'
          y1='48.2874'
          x2='-27.696'
          y2='33.5605'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EnhancedGraphIcon;
