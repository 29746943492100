import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import backgroundLeft from '../../../assets/backgroundTopLeft.png';
import backgroundRight from '../../../assets/backgroundTopRight.png';
import BigArrowIcon from '../../../icons/BigArrow';
import { deviceBreakpoints, deviceSizes } from '../../../Theme';
import Text from '../../atoms/Text/Text';

interface TopIllustration {
  title: string;
  description: string;
  image?: string;
  middleIllustration?: React.ReactElement;
}

interface TopSectionProductProps {
  title: string;
  description: string;
  illustrations: TopIllustration[];
  onlyHasMiddleDescription?: boolean;
}

const TopSectionProduct: React.FC<TopSectionProductProps> = ({
  title,
  description,
  illustrations,
  onlyHasMiddleDescription = false,
}) => {
  return (
    <Wrapper className='main-container'>
      <StyledImgLeft src={backgroundLeft} />
      <ContentWrapper className='col-start-2 col-end-12 col-start-1-m col-start-none-m'>
        <Title>
          <Text type='xxlText' color='accent'>
            <Trans> {title}</Trans>
          </Text>
        </Title>

        <Description>
          <Text type='largeText' color='text'>
            <Trans> {description}</Trans>
          </Text>
        </Description>

        <IllustrationWrapper>
          <Illustration>
            <IllustrationImgWrapper>
              <img src={illustrations[0].image} />
            </IllustrationImgWrapper>

            <IllustrationTitle>
              <Text type='xlText' color='accent'>
                {illustrations[0].title}
              </Text>
            </IllustrationTitle>
            <Text type='text'>{illustrations[0].description}</Text>
          </Illustration>

          <IllustrationArrowWrapper>
            <BigArrowIcon />
          </IllustrationArrowWrapper>

          <Illustration>
            <IllustrationImgWrapper>
              {illustrations[1].image && <img src={illustrations[1].image} />}
              {illustrations[1].middleIllustration && illustrations[1].middleIllustration}
            </IllustrationImgWrapper>

            <IllustrationTitle>
              <Text type='xlText' color='accent'>
                {illustrations[1].title}
              </Text>
            </IllustrationTitle>
            <IllustrationDescription onlyHasMiddleDescription={onlyHasMiddleDescription}>
              <Text type='text'>
                <Trans> {illustrations[1].description}</Trans>
              </Text>
            </IllustrationDescription>
          </Illustration>

          <IllustrationArrowWrapper>
            <BigArrowIcon color='#6079E5' />
          </IllustrationArrowWrapper>

          <Illustration>
            <IllustrationImgWrapper>
              <img src={illustrations[2].image} />
            </IllustrationImgWrapper>
            <IllustrationTitle>
              <Text type='xlText' color='accent'>
                {illustrations[2].title}
              </Text>
            </IllustrationTitle>
            <Text type='text'> {illustrations[2].description}</Text>
          </Illustration>
        </IllustrationWrapper>
      </ContentWrapper>

      <StyledImgRight src={backgroundRight} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  position: relative;

  @media only screen and (min-width: ${deviceSizes.gridBreakPoint}) {
    position: unset;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-left: 30px;
    padding-right: 30px;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  margin-bottom: 24px;
`;

const Description = styled.div`
  max-width: 800px;
  margin-bottom: 64px;
  text-align: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    text-align: left;
  }
`;

const IllustrationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
  }
`;

const Illustration = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const IllustrationImgWrapper = styled.div`
  height: 220px;
  display: flex;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    height: unset;
  }
`;

const IllustrationArrowWrapper = styled(IllustrationImgWrapper)`
  @media only screen and ${deviceBreakpoints.mobile} {
    transform: rotate(90deg);
    width: fit-content;
    justify-content: center;
    align-self: center;
    margin-bottom: 56px;
  }
`;

const IllustrationTitle = styled.div`
  background-color: ${(props) => props.theme.colors.lightBckg};
  padding: 8px 24px;
  border-radius: 23px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-top: 64px;
  margin-bottom: 16px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 32px;
    margin-bottom: 12px;
  }
`;

const IllustrationDescription = styled.div<{ onlyHasMiddleDescription: boolean }>`
  text-align: center;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    ${(props) =>
      props.onlyHasMiddleDescription && 'position: absolute; bottom: -56px;  width: 60%;'}
  }
`;

const StyledImg = styled.img`
  position: absolute;
  z-index: -10;

  @media only screen and (max-width: 560px) {
    height: 50%;
  }
`;

const StyledImgLeft = styled(StyledImg)`
  left: -100px;
  top: 200px;

  @media only screen and ${deviceBreakpoints.mobile} {
    top: 950px;
  }
`;

const StyledImgRight = styled(StyledImg)`
  right: -100px;
  top: 10px;

  @media only screen and (max-width: 560px) {
    top: 200px;
  }
`;

export default TopSectionProduct;
