import React from 'react';

const CurvedArrowBottomIcon: React.FC = () => {
  return (
    <svg width='58' height='55' viewBox='0 0 58 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.14'
        d='M57.9219 0.133237C56.7667 24.5606 51.3515 57.9773 11.5443 48.6616C10.7685 48.5035 10.0343 48.3191 9.34846 48.1133L13.2815 54.2571C9.36359 53.7082 0.580079 47.018 0.580079 47.018C0.580079 47.018 5.55646 47.8336 9.7778 40.1782C9.97701 39.5405 10.2289 39.0315 10.5352 38.6919C10.2864 39.2198 10.0335 39.7145 9.7778 40.1782C9.19095 42.0566 9.06171 45.0509 9.34846 48.1133C10.0918 48.3103 10.8237 48.4929 11.5443 48.6616C25.5138 51.5077 52.956 45.8345 53.549 3.78176L57.9219 0.133237Z'
        fill='#424565'
      />
    </svg>
  );
};

export default CurvedArrowBottomIcon;
