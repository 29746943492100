import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import descriptionImage from '../../assets/productImages/realFds/description.png';
import extendedDescriptionImage from '../../assets/productImages/realFds/extendedDescription.png';
import topLeftImage from '../../assets/productImages/realFds/topLeft.png';
import topMiddleImage from '../../assets/productImages/realFds/topMiddle.png';
import topRightImage from '../../assets/productImages/realFds/topRight.png';
import TopSectionProduct from '../../components/organisms/TopSectionProduct/TopSectionProduct';
import { gaMeasurementId } from '../../config';
import BankIcon from '../../icons/Bank';
import CallPerson from '../../icons/CallPerson';
import ChecklistCircleIcon from '../../icons/ChecklistCircle';
import MonitorIcon from '../../icons/Monitor';
import PerformanceIcon from '../../icons/Performance';
import PersonWithMoneyIcon from '../../icons/PersonWithMoney';
import PersonWithUmbrellaIcon from '../../icons/PersonWithUmbrella';
import SimpleShoppingCartIcon from '../../icons/SimpleShoppingCart';
import WalletIcon from '../../icons/Wallet';
import { deviceBreakpoints } from '../../Theme';
import Description from './sections/Description';
import ExtendedDescription from './sections/ExtendedDescription';
import Features from './sections/Features';
import SlideShow from './sections/SlideShow';

ReactGA.initialize(gaMeasurementId);

const RealFds: React.FC = () => {
  const { t } = useTranslation(['products']);
  ReactGA.send({ hitType: 'pageview', page: '/products/real-fds', title: 'Real-FDS' });
  const illustrations = [
    {
      title: t('realFds.topIllustrationLeftTitle'),
      description: t('realFds.topIllustrationLeftDescription'),
      image: topLeftImage,
    },
    {
      title: t('realFds.topIllustrationMiddleTitle'),
      description: t('realFds.topIllustrationMiddleDescription'),
      image: topMiddleImage,
    },
    {
      title: t('realFds.topIllustrationRightTitle'),
      description: t('realFds.topIllustrationRightDescription'),
      image: topRightImage,
    },
  ];

  const features = [
    {
      title: t('realFds.featureOneTitle'),
      description: t('realFds.featureOneDescription'),
      icon: <MonitorIcon />,
    },
    {
      title: t('realFds.featureTwoTitle'),
      description: t('realFds.featureTwoDescription'),
      icon: <PerformanceIcon />,
    },
    {
      title: t('realFds.featureThreeTitle'),
      description: t('realFds.featureThreeDescription'),
      icon: <ChecklistCircleIcon />,
    },
    {
      title: t('realFds.featureFourTitle'),
      description: t('realFds.featureFourDescription'),
      icon: <CallPerson />,
    },
  ];

  const slideItems = [
    {
      icon: <BankIcon />,
      title: t('realFds.slideShowItemOneTitle'),
      description: t('realFds.slideShowItemOneDescription'),
    },
    {
      icon: <WalletIcon />,
      title: t('realFds.slideShowItemTwoTitle'),
      description: t('realFds.slideShowItemTwoDescription'),
    },
    {
      icon: <PersonWithMoneyIcon />,
      title: t('realFds.slideShowItemThreeTitle'),
      description: t('realFds.slideShowItemThreeDescription'),
    },
    {
      icon: <PersonWithUmbrellaIcon />,
      title: t('realFds.slideShowItemFourTitle'),
      description: t('realFds.slideShowItemFourDescription'),
    },
    {
      icon: <SimpleShoppingCartIcon />,
      title: t('realFds.slideShowItemFiveTitle'),
      description: t('realFds.slideShowItemFiveDescription'),
    },
  ];

  return (
    <Wrapper>
      <SectionWrapper>
        <TopSectionProduct
          title={t('realFds.topTitle')}
          description={t('realFds.topDescription')}
          illustrations={illustrations}
          onlyHasMiddleDescription
        />
      </SectionWrapper>

      <SectionWrapper>
        <Description
          secondaryTitle={t('realFds.mainDescriptionTitleBottom')}
          secondarySubtitle={t('realFds.mainDescriptionSubtitleBottom')}
          secondaryDescription={t('realFds.mainDescriptionBottom')}
          image={descriptionImage}
        />
      </SectionWrapper>

      <SectionWrapper>
        <ExtendedDescription
          title={t('realFds.extendedDescriptionTitle')}
          subTitle={t('realFds.extendedDescriptionSubtitle')}
          description={t('realFds.extendedDescription')}
          image={extendedDescriptionImage}
          buttonText={'Book a Session'}
        />
      </SectionWrapper>

      <SectionWrapper>
        <Features features={features} title={t('realFds.featuresTitle')} />
      </SectionWrapper>

      <SectionWrapper>
        <SlideShow slideItems={slideItems} title={t('useCases')} />
      </SectionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  margin-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 60px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 64px;

  &:first-child {
    margin-bottom: 110px;
  }
`;

export default RealFds;
