import React from 'react';
import styled from 'styled-components';

interface CircleProps {
  isActive: boolean;
}

const Circle: React.FC<CircleProps> = ({ isActive }) => {
  return isActive ? <ActiveCircle /> : <InactiveCircle />;
};

const StyledCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;
const ActiveCircle = styled(StyledCircle)`
  border: 4px solid #ffffff;
  background: transparent;
  box-sizing: content-box;
`;

const InactiveCircle = styled(StyledCircle)`
  background: #ffffff;
`;

export default Circle;
