import React from 'react';
import styled from 'styled-components';

import coinfirmLogo from '../../../assets/aboutUs/coinfirm.png';
import deepgramLogo from '../../../assets/aboutUs/deepgram.png';
import phonexiaLogo from '../../../assets/aboutUs/phonexia.png';
import { deviceBreakpoints } from '../../../Theme';

const PartnerLogos: React.FC = () => {
  return (
    <PartnerLogoWrapper>
      <PartnerWrapper href='https://www.phonexia.com/'>
        <StyledImg src={phonexiaLogo} width={300} />
      </PartnerWrapper>
      <PartnerWrapper href='https://deepgram.com/'>
        <StyledImg src={deepgramLogo} width={300} />
      </PartnerWrapper>

      <PartnerWrapper href='https://www.coinfirm.com/'>
        <StyledImg src={coinfirmLogo} width={300} />
      </PartnerWrapper>
    </PartnerLogoWrapper>
  );
};

const PartnerLogoWrapper = styled.div`
  display: grid;
  margin-top: 44px;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and ${deviceBreakpoints.mobile} {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 44px;
  }
`;

const PartnerWrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 44px;
`;

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`;

export default PartnerLogos;
