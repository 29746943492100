import React from 'react';

const GraphIcon: React.FC = () => {
  return (
    <svg width='55' height='46' viewBox='0 0 55 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M54.6667 43C54.6667 43.7957 54.3506 44.5587 53.788 45.1213C53.2254 45.6839 52.4623 46 51.6667 46H3.66666C2.87101 46 2.10795 45.6839 1.54534 45.1213C0.982727 44.5587 0.666656 43.7957 0.666656 43V3C0.666656 2.20435 0.982727 1.44129 1.54534 0.878679C2.10795 0.31607 2.87101 0 3.66666 0C4.46231 0 5.22537 0.31607 5.78798 0.878679C6.35059 1.44129 6.66666 2.20435 6.66666 3V27.75L17.5417 16.875C17.8204 16.5953 18.1515 16.3734 18.5162 16.222C18.8809 16.0706 19.2718 15.9926 19.6667 15.9926C20.0615 15.9926 20.4525 16.0706 20.8171 16.222C21.1818 16.3734 21.5129 16.5953 21.7917 16.875L27.6667 22.75L40.4167 10H37.6667C36.871 10 36.1079 9.68393 35.5453 9.12132C34.9827 8.55871 34.6667 7.79565 34.6667 7C34.6667 6.20435 34.9827 5.44129 35.5453 4.87868C36.1079 4.31607 36.871 4 37.6667 4H47.6667C48.4623 4 49.2254 4.31607 49.788 4.87868C50.3506 5.44129 50.6667 6.20435 50.6667 7V17C50.6667 17.7956 50.3506 18.5587 49.788 19.1213C49.2254 19.6839 48.4623 20 47.6667 20C46.871 20 46.1079 19.6839 45.5453 19.1213C44.9827 18.5587 44.6667 17.7956 44.6667 17V14.25L29.7917 29.125C29.5129 29.4047 29.1818 29.6266 28.8171 29.778C28.4525 29.9294 28.0615 30.0074 27.6667 30.0074C27.2718 30.0074 26.8809 29.9294 26.5162 29.778C26.1515 29.6266 25.8204 29.4047 25.5417 29.125L19.6667 23.25L6.66666 36.25V40H51.6667C52.4623 40 53.2254 40.3161 53.788 40.8787C54.3506 41.4413 54.6667 42.2043 54.6667 43Z'
        fill='url(#paint0_linear_202_1021)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_202_1021'
          x1='57.9763'
          y1='40.0517'
          x2='-4.38454'
          y2='30.8022'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GraphIcon;
