import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import bigGradientBackground from '../../../assets/bigGradientBackground.png';
import Button from '../../../components/atoms/Button/Button';
import Circle from '../../../components/atoms/Circle/Circle';
import Text from '../../../components/atoms/Text/Text';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ArrowIcon from '../../../icons/Arrow';
import { deviceBreakpoints } from '../../../Theme';

interface SlideItem {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface SlideShowProps {
  slideItems: SlideItem[];
  title: string;
}

const SlideShow: React.FC<SlideShowProps> = ({ slideItems, title }) => {
  const { t } = useTranslation(['products']);

  const [currentSlideShowItems, setCurrentSlideShowItems] = useState<SlideItem[][]>([
    [slideItems[0], slideItems[1]],
  ]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);

  const handleBackClick = () => {
    if (currentPage === 1) {
      setCurrentPage(numberOfPages);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextClick = () => {
    if (currentPage === numberOfPages) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const pagination = () => {
    const paginationItems = [];
    for (let index = 1; index <= numberOfPages; index++) {
      paginationItems.push(<Circle isActive={index === currentPage} />);
    }

    return paginationItems;
  };

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    const chunkSize = isMobile ? 1 : 2;
    const chunks = [];
    for (let i = 0; i < slideItems.length; i += chunkSize) {
      const chunk = slideItems.slice(i, i + chunkSize);

      chunks.push(chunk);
    }
    setCurrentSlideShowItems(chunks);
    setNumberOfPages(chunks.length);
  }, [slideItems, isMobile]);

  return (
    <SlideShowWrapper className='main-container'>
      <ContentWrapper className='col-start-1 col-end-none'>
        <SlideShowHeader className='col-start-2 col-start-1-m col-end-12'>
          <Text type='h2' color='white'>
            {title}
          </Text>
          {!isMobile && (
            <ArrowWrapper>
              <ArrowLeft onClick={handleBackClick}>
                <ArrowIcon />
              </ArrowLeft>
              <ArrowRight onClick={handleNextClick}>
                <ArrowIcon />
              </ArrowRight>
            </ArrowWrapper>
          )}
        </SlideShowHeader>
        <SlideShowSlider className='col-start-2 col-start-1-m col-end-12 col-end-none-m'>
          <SlideItemWrapper key={'slideShowItem_1'}>
            {currentSlideShowItems[currentPage - 1][0].icon}
            <Text type='h4' color='accent'>
              {currentSlideShowItems[currentPage - 1][0].title}
            </Text>
            <Text type='text' color='textLight'>
              {currentSlideShowItems[currentPage - 1][0].description}
            </Text>
          </SlideItemWrapper>

          {currentSlideShowItems[currentPage - 1][1] && !isMobile && (
            <SlideItemWrapper key={'slideShowItem_2'}>
              {currentSlideShowItems[currentPage - 1][1].icon}
              <Text type='h4' color='accent'>
                {currentSlideShowItems[currentPage - 1][1].title}
              </Text>
              <Text type='text' color='textLight'>
                {currentSlideShowItems[currentPage - 1][1].description}
              </Text>
            </SlideItemWrapper>
          )}
        </SlideShowSlider>
        <BottomWrapper className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
          <Pagination>{pagination()}</Pagination>
          <ButtonWrapper
            href='#contactForm'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e: any) => {
              e.preventDefault();

              const anchor = document.querySelectorAll('a[href^="#"]')[0];
              const selector = anchor.getAttribute('href');
              const element = selector ? document.querySelector(selector) : null;

              if (element) {
                window.scrollTo({
                  top: element.getBoundingClientRect().top + window.scrollY - 120,
                  behavior: 'smooth',
                });
              }
            }}
          >
            <Button buttonType='regular' text={t('requestDemo')} onClickAction={() => ({})} />
          </ButtonWrapper>
          {isMobile && (
            <ArrowWrapper>
              <ArrowLeft onClick={handleBackClick}>
                <ArrowIcon />
              </ArrowLeft>
              <ArrowRight onClick={handleNextClick}>
                <ArrowIcon />
              </ArrowRight>
            </ArrowWrapper>
          )}
        </BottomWrapper>
      </ContentWrapper>
    </SlideShowWrapper>
  );
};

const SlideShowWrapper = styled.div`
  display: grid;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  grid-template-columns: repeat(12, [col-start] 1fr);
  display: grid;

  background-image: url(${bigGradientBackground});
  background-size: cover;
  border-radius: 80px;
  padding-top: 85px;
  padding-bottom: 85px;
  overflow: hidden;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 36px 20px;
    border-radius: 20px;
  }
`;

const SlideShowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
`;

const SlideShowSlider = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 24px;
  min-height: 355px;
`;

const SlideItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 37px;
  padding: 32px 48px 48px;
  width: 50%;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
    min-width: initial;
    min-height: 330px;
    justify-content: space-between;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
`;

const ArrowRight = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ArrowLeft = styled(ArrowRight)`
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-right: 12px;
`;

const ButtonWrapper = styled.a`
  text-decoration: none;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 40px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

export default SlideShow;
