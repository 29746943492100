import React from 'react';

interface BigArrowIconProps {
  color?: string;
}

const BigArrowIcon: React.FC<BigArrowIconProps> = ({ color }) => {
  return (
    <svg width='67' height='60' viewBox='0 0 67 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 26C1.79086 26 0 27.7909 0 30C0 32.2091 1.79086 34 4 34V26ZM65.8284 32.8284C67.3905 31.2663 67.3905 28.7337 65.8284 27.1716L40.3726 1.71573C38.8105 0.153631 36.2778 0.153631 34.7157 1.71573C33.1536 3.27783 33.1536 5.81049 34.7157 7.37258L57.3432 30L34.7157 52.6274C33.1536 54.1895 33.1536 56.7222 34.7157 58.2843C36.2778 59.8464 38.8105 59.8464 40.3726 58.2843L65.8284 32.8284ZM4 34H63V26H4V34Z'
        fill={color || '#3DDDC0'}
      />
    </svg>
  );
};

export default BigArrowIcon;
