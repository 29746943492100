import React from 'react';

interface SheetMicrophoneIconProps {
  size?: 'small' | 'default';
}

const SheetMicrophoneIcon: React.FC<SheetMicrophoneIconProps> = ({ size = 'default' }) => {
  return size === 'default' ? (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M45.3333 26.6663C43.8667 26.6663 42.6107 26.1552 41.5653 25.133C40.52 24.1108 39.9982 22.8441 40 21.333V10.6663C40 9.15523 40.5227 7.88857 41.568 6.86635C42.6133 5.84412 43.8684 5.33301 45.3333 5.33301C46.8444 5.33301 48.1111 5.84412 49.1333 6.86635C50.1556 7.88857 50.6667 9.15523 50.6667 10.6663V21.333C50.6667 22.8441 50.1556 24.1108 49.1333 25.133C48.1111 26.1552 46.8444 26.6663 45.3333 26.6663ZM13.3333 58.6663C11.8667 58.6663 10.6107 58.1437 9.56534 57.0984C8.52 56.053 7.99823 54.7979 8 53.333V10.6663C8 9.19968 8.52267 7.94368 9.568 6.89835C10.6133 5.85301 11.8684 5.33123 13.3333 5.33301H34.6667V10.6663H13.3333V53.333H42.6667V47.9997H48V53.333C48 54.7997 47.4773 56.0557 46.432 57.101C45.3867 58.1463 44.1316 58.6681 42.6667 58.6663H13.3333ZM18.6667 47.9997V42.6663H37.3333V47.9997H18.6667ZM18.6667 39.9997V34.6663H32V39.9997H18.6667ZM48 42.6663H42.6667V35.733C39.2444 35.1108 36.3884 33.4548 34.0987 30.765C31.8089 28.0752 30.6649 24.9312 30.6667 21.333H36C36 23.9108 36.9111 26.1108 38.7333 27.933C40.5556 29.7552 42.7556 30.6663 45.3333 30.6663C47.9556 30.6663 50.1671 29.7552 51.968 27.933C53.7689 26.1108 54.6684 23.9108 54.6667 21.333H60C60 24.933 58.8667 28.0779 56.6 30.7677C54.3333 33.4575 51.4667 35.1126 48 35.733V42.6663Z'
        fill='url(#paint0_linear_2073_48299)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2073_48299'
          x1='63.1871'
          y1='51.7698'
          x2='-19.5812'
          y2='40.9803'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.1667 8.33366C13.7083 8.33366 13.3161 8.17394 12.99 7.85449C12.6633 7.53505 12.5 7.13921 12.5 6.66699V3.33366C12.5 2.86144 12.6633 2.4656 12.99 2.14616C13.3161 1.82671 13.7083 1.66699 14.1667 1.66699C14.6389 1.66699 15.0347 1.82671 15.3542 2.14616C15.6736 2.4656 15.8333 2.86144 15.8333 3.33366V6.66699C15.8333 7.13921 15.6736 7.53505 15.3542 7.85449C15.0347 8.17394 14.6389 8.33366 14.1667 8.33366ZM4.16667 18.3337C3.70833 18.3337 3.31583 18.1706 2.98917 17.8445C2.66306 17.5178 2.5 17.1253 2.5 16.667V3.33366C2.5 2.87533 2.66306 2.48283 2.98917 2.15616C3.31583 1.83005 3.70833 1.66699 4.16667 1.66699H10.8333V3.33366H4.16667V16.667H13.3333V15.0003H15V16.667C15 17.1253 14.8369 17.5178 14.5108 17.8445C14.1842 18.1706 13.7917 18.3337 13.3333 18.3337H4.16667ZM5.83333 15.0003V13.3337H11.6667V15.0003H5.83333ZM5.83333 12.5003V10.8337H10V12.5003H5.83333ZM15 13.3337H13.3333V11.167C12.2639 10.9725 11.3717 10.455 10.6567 9.61449C9.94111 8.77449 9.58333 7.79199 9.58333 6.66699H11.25C11.25 7.47255 11.5347 8.16005 12.1042 8.72949C12.6736 9.29894 13.3611 9.58366 14.1667 9.58366C14.9861 9.58366 15.6772 9.29894 16.24 8.72949C16.8022 8.16005 17.0833 7.47255 17.0833 6.66699H18.75C18.75 7.79199 18.3958 8.77449 17.6875 9.61449C16.9792 10.455 16.0833 10.9725 15 11.167V13.3337Z'
        fill='#5868F5'
      />
    </svg>
  );
};

export default SheetMicrophoneIcon;
