import React from 'react';

const AlphabetIcon: React.FC = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6667 0.166504C12.0474 0.166504 13.1667 1.28579 13.1667 2.6665C13.1667 2.7222 13.1667 2.77775 13.1667 2.83317H21.3333C22.714 2.83317 23.8333 3.95246 23.8333 5.33317C23.8333 6.71388 22.714 7.83317 21.3333 7.83317H13.1708C13.1807 11.7038 13.2145 14.7044 13.3303 17.0562C13.4982 20.4654 13.8319 22.0735 14.2361 22.8818C14.8535 24.1168 14.353 25.6184 13.118 26.2359C11.8831 26.8534 10.3814 26.3528 9.76394 25.1179C8.83477 23.2595 8.50182 20.6623 8.33636 17.3021C8.21521 14.8418 8.18055 11.7345 8.17064 7.83317H2.66667C1.28596 7.83317 0.166672 6.71388 0.166672 5.33317C0.166672 3.95246 1.28596 2.83317 2.66667 2.83317H8.16667C8.16667 2.80595 8.16667 2.7787 8.16667 2.75142V2.6665C8.16667 1.28579 9.28596 0.166504 10.6667 0.166504Z'
        fill='url(#paint0_linear_1361_1754)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.43444 17.1008C5.7353 17.7999 5.16667 19.0517 5.16667 21.333C5.16667 21.5888 5.37358 22.2909 6.168 23.0025C6.90239 23.6603 7.98324 24.1663 9.33334 24.1663C10.2377 24.1663 11.9328 23.3619 13.5661 21.3681C14.7386 19.9368 15.6232 18.1848 15.9862 16.4013C14.2474 15.9994 12.3534 15.833 10.6667 15.833C8.38537 15.833 7.13357 16.4016 6.43444 17.1008ZM20.5872 18.6071C21.2106 19.2768 21.5 20.0503 21.5 20.9517C21.5 22.6163 21.1385 23.8388 20.5573 24.7519C19.9815 25.6566 19.0763 26.4368 17.6808 27.0356C16.412 27.5801 15.8248 29.05 16.3693 30.3188C16.9137 31.5877 18.3837 32.1749 19.6525 31.6304C21.8117 30.7039 23.5732 29.3254 24.7753 27.4368C25.9722 25.5565 26.5 23.3511 26.5 20.9517C26.5 16.495 23.4603 13.9035 20.3178 12.5573C17.2144 11.2279 13.5013 10.833 10.6667 10.833C7.61464 10.833 4.86644 11.5977 2.8989 13.5652C0.931372 15.5328 0.166672 18.281 0.166672 21.333C0.166672 23.3839 1.29309 25.3484 2.83201 26.7269C4.43095 28.1591 6.68344 29.1663 9.33334 29.1663C12.429 29.1663 15.4006 27.0188 17.4339 24.5366C18.7852 22.8871 19.934 20.8448 20.5872 18.6071ZM34.6667 18.833C35.6546 18.833 36.5499 19.4148 36.9512 20.3177L47.6179 44.3177C48.1786 45.5794 47.6104 47.0568 46.3487 47.6175C45.087 48.1783 43.6096 47.6101 43.0488 46.3484L41.3383 42.4997H27.9951L26.2845 46.3484C25.7238 47.6101 24.2464 48.1783 22.9847 47.6175C21.7229 47.0568 21.1547 45.5794 21.7155 44.3177L32.3821 20.3177C32.7834 19.4148 33.6787 18.833 34.6667 18.833ZM30.2173 37.4997H39.1161L34.6667 27.4885L30.2173 37.4997Z'
        fill='url(#paint1_linear_1361_1754)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1361_1754'
          x1='25.2839'
          y1='23.0951'
          x2='-12.4812'
          y2='18.5573'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1361_1754'
          x1='50.7555'
          y1='43.0491'
          x2='-24.1825'
          y2='30.1414'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AlphabetIcon;
