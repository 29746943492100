import React from 'react';

const SpeechBubbleCheckmarkIcon: React.FC = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.4344 20.8987C41.4107 21.875 41.4107 23.458 40.4344 24.4343L27.1011 37.7676C26.1248 38.7439 24.5419 38.7439 23.5655 37.7676L15.5655 29.7676C14.5892 28.7913 14.5892 27.2084 15.5655 26.2321C16.5419 25.2558 18.1248 25.2558 19.1011 26.2321L25.3333 32.4643L36.8989 20.8987C37.8752 19.9224 39.4581 19.9224 40.4344 20.8987Z'
        fill='url(#paint0_linear_1361_13303)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.10352e-05 28C6.10352e-05 12.5356 12.5357 0 28.0001 0C43.4644 0 56.0001 12.5356 56.0001 28C56.0001 43.4631 43.4664 55.998 28.0037 56C23.3884 56.007 18.8484 54.8683 14.7889 52.6925L4.11944 54.6794C3.34165 54.8242 2.54244 54.5765 1.983 54.017C1.42357 53.4576 1.17578 52.6584 1.32062 51.8806L3.30744 41.2115C1.19626 37.2751 6.10352e-05 32.7718 6.10352e-05 28ZM28.0001 4.8C15.1867 4.8 4.80006 15.1866 4.80006 28C4.80006 32.2289 5.93002 36.1905 7.9024 39.5975C8.18954 40.0935 8.2897 40.6759 8.18478 41.2394L6.67997 49.3201L14.7607 47.8153C15.3239 47.7104 15.906 47.8104 16.4019 48.0973C19.9255 50.1357 23.9256 51.2061 27.9964 51.2L28.0001 51.2C40.8135 51.2 51.2001 40.8134 51.2001 28C51.2001 15.1866 40.8135 4.8 28.0001 4.8Z'
        fill='url(#paint1_linear_1361_13303)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1361_13303'
          x1='42.7806'
          y1='36.1291'
          x2='1.66983'
          y2='28.2341'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1361_13303'
          x1='59.4323'
          y1='48.7586'
          x2='-29.6255'
          y2='36.8515'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SpeechBubbleCheckmarkIcon;
