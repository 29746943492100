import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as transEn from './lang/en';
import * as transKo from './lang/ko';

export const languages = ['en', 'ko'];

export type Languages = (typeof languages)[number]; // 'en' | 'ko'

const resources: Resource = {
  en: {
    main: transEn.mainTranslation,
    aboutUs: transEn.aboutUsTranslation,
    products: transEn.productTranslation,
  },
  ko: {
    main: transKo.mainTranslation,
    aboutUs: transKo.aboutUsTranslation,
    products: transKo.productTranslation,
  },
};

const languageSettingInLocalStorage = localStorage.getItem('lng');

export const userLanguage =
  !localStorage.getItem('lng') ||
  (languageSettingInLocalStorage !== 'ko' && languageSettingInLocalStorage !== 'en')
    ? 'ko'
    : languageSettingInLocalStorage;

i18n.use(initReactI18next).init({
  resources,
  lng: userLanguage,
  fallbackLng: 'ko',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
