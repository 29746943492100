import React from 'react';

const ArrowIcon: React.FC = () => {
  return (
    <svg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.0667 25.9834C14.8222 25.7389 14.6942 25.4278 14.6827 25.05C14.672 24.6722 14.7889 24.3611 15.0333 24.1167L21.5667 17.5834H6.66666C6.28888 17.5834 5.972 17.4554 5.716 17.1994C5.46088 16.9442 5.33333 16.6278 5.33333 16.25C5.33333 15.8722 5.46088 15.5554 5.716 15.2994C5.972 15.0442 6.28888 14.9167 6.66666 14.9167H21.5667L15.0333 8.38336C14.7889 8.13891 14.672 7.8278 14.6827 7.45002C14.6942 7.07225 14.8222 6.76114 15.0667 6.51669C15.3111 6.27225 15.6222 6.15002 16 6.15002C16.3778 6.15002 16.6889 6.27225 16.9333 6.51669L25.7333 15.3167C25.8667 15.4278 25.9613 15.5665 26.0173 15.7327C26.0724 15.8998 26.1 16.0722 26.1 16.25C26.1 16.4278 26.0724 16.5945 26.0173 16.75C25.9613 16.9056 25.8667 17.05 25.7333 17.1834L16.9333 25.9834C16.6889 26.2278 16.3778 26.35 16 26.35C15.6222 26.35 15.3111 26.2278 15.0667 25.9834Z'
        fill='white'
      />
    </svg>
  );
};

export default ArrowIcon;
