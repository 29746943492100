import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import blogLogo from '../../../assets/sns/blog.png';
import facebookLogo from '../../../assets/sns/facebook.png';
import instagramLogo from '../../../assets/sns/instagram.png';
import CloseIcon from '../../../icons/Close';
import FlagIcon from '../../../icons/Flag';
import MenuIcon from '../../../icons/Menu';
import { userLanguage } from '../../../locales/i18n';
import { deviceBreakpoints } from '../../../Theme';
import Logo from '../../atoms/Logo/Logo';
import Text from '../../atoms/Text/Text';
import DemoDropdown from '../../molecules/DemoDropdown/DemoDropdown';
import Dropdown, { DropdownItemType } from '../../molecules/Dropdown/Dropdown';
import VerticalMenu from '../../molecules/VerticalMenu/VerticalMenu';

const HeaderComponent: React.FC = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { i18n, t } = useTranslation(['main']);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dropdownLanguageItems = [
    { key: 'ko', value: <FlagIcon language='ko' />, text: 'KO' },
    { key: 'en', value: <FlagIcon language='en' />, text: 'EN' },
  ];

  const productsDropdownItems = [
    { key: 'real-gpt', value: 'Real-GPT' },
    { key: 'real-stt', value: 'Real-STT' },
    { key: 'real-sid', value: 'Real-SID' },
    { key: 'real-fds', value: 'Real-FDS' },
    { key: 'real-si', value: 'Real-SI' },
  ];

  const snsDropdownItems = [
    {
      key: 'blog',
      value: <img width={40} height={40} src={blogLogo} />,
      url: 'https://blog.naver.com/darwinai_100',
    },
    {
      key: 'instagram',
      value: <img width={40} height={40} src={instagramLogo} />,
      url: 'https://www.instagram.com/darwinaiintelligence/',
    },
    {
      key: 'facebook',
      value: <img width={40} height={40} src={facebookLogo} />,
      url: 'https://facebook.com/darwinintelligence',
    },
  ];

  const defaultLanguage =
    dropdownLanguageItems.find((d) => d.key === userLanguage) || dropdownLanguageItems[0];

  const [currentLanguage, setCurrentLanguage] = useState<{
    key: string;
    value: string | React.ReactNode;
  }>(defaultLanguage);

  const [currentProduct, setCurrentProduct] = useState<
    | {
        key: string;
        value: string | React.ReactNode;
      }
    | undefined
  >();

  useEffect(() => {
    if (currentProduct) {
      navigate(`/products/${currentProduct.key}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct]);

  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: any) => {
      if (
        (ref.current && !ref.current.contains(event.target) && menuIsOpen) ||
        (buttonRef.current && buttonRef.current.contains(event.target) && menuIsOpen)
      ) {
        setMenuIsOpen(false);
      } else if (buttonRef.current && buttonRef.current.contains(event.target) && !menuIsOpen) {
        setMenuIsOpen(true);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuIsOpen]);

  const changeLanguage = (languageSetting: DropdownItemType) => {
    setCurrentLanguage(languageSetting);
    localStorage.setItem('lng', languageSetting.key);
    i18n.changeLanguage(languageSetting.key);
  };

  return (
    <Wrapper ref={ref}>
      <div className='main-container-header'>
        <MenuButton ref={buttonRef}>{!menuIsOpen ? <MenuIcon /> : <CloseIcon />}</MenuButton>

        {!menuIsOpen && (
          <Logo
            onClick={() => {
              if (location.pathname === '/') {
                window.scrollTo(0, 0);
              } else {
                navigate('/');
              }
            }}
            className='col-start-1 col-end-4'
            type='header'
            size={windowWidth < 1024 ? 'small' : 'default'}
          />
        )}

        <MenuWrapper className='col-start-8 col-end-none'>
          {!menuIsOpen ? (
            <>
              <DropdownWrapper>
                <Dropdown
                  items={productsDropdownItems}
                  currentlySelectedItem={currentProduct}
                  setCurrentlySelectedItem={setCurrentProduct}
                  title={t('products') || ''}
                />
              </DropdownWrapper>
              <MenuItem href='/about-us'>
                <Text type='link' color='text'>
                  {t('aboutUs')}
                </Text>
              </MenuItem>
              <DropdownWrapper>
                <Dropdown items={snsDropdownItems} title={t('sns') || ''} />
              </DropdownWrapper>

              <DropdownWrapper>
                <Dropdown
                  items={dropdownLanguageItems}
                  currentlySelectedItem={currentLanguage}
                  setCurrentlySelectedItem={changeLanguage}
                  withIcon
                />
              </DropdownWrapper>
              <DemoDropdown />
            </>
          ) : (
            <MobileDropdownWrapper>
              <Dropdown
                items={dropdownLanguageItems}
                currentlySelectedItem={currentLanguage}
                setCurrentlySelectedItem={changeLanguage}
                withIcon
              />
            </MobileDropdownWrapper>
          )}
        </MenuWrapper>

        <VerticalMenuWrapper>
          {menuIsOpen && <VerticalMenu closeMenu={() => setMenuIsOpen(false)} />}
        </VerticalMenuWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  padding: 20px 0px;
  z-index: 100;
  position: sticky;
  background-color: ${(props) => props.theme.colors.white};
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightBckg};
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  cursor: pointer;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 40px;
    height: 40px;
    display: block;
    border: none;
  }
`;

const MenuWrapper = styled.div`
  justify-self: end;
  display: flex;
`;

const MenuItem = styled.a`
  display: flex;
  align-items: center;
  width: 110px;
  cursor: pointer;
  text-decoration: none;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
  width: 133px;
  display: flex;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

const MobileDropdownWrapper = styled(DropdownWrapper)`
  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    width: unset;
  }
`;

const VerticalMenuWrapper = styled.div`
  position: absolute;
  top: 80px;
  width: 100%;
  display: none;
  left: 0;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: block;
  }
`;

export default HeaderComponent;
