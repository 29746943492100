import React from 'react';

const WalletIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M41.3333 41.3333C43.5467 41.3333 45.3333 39.5467 45.3333 37.3333C45.3333 35.12 43.5467 33.3333 41.3333 33.3333C39.12 33.3333 37.3333 35.12 37.3333 37.3333C37.3333 39.5467 39.12 41.3333 41.3333 41.3333ZM18.6667 8H45.3333C46.7478 8 48.1044 8.5619 49.1046 9.5621C50.1048 10.5623 50.6667 11.9188 50.6667 13.3333V18.6667C52.0812 18.6667 53.4377 19.2286 54.4379 20.2288C55.4381 21.229 56 22.5855 56 24V50.6667C56 53.6267 53.6267 56 50.6667 56H18.6667C12.7733 56 8 51.2267 8 45.3333V18.6667C8 12.7733 12.7733 8 18.6667 8ZM45.3333 18.6667V13.3333H18.6667C15.7333 13.3333 13.3333 15.7333 13.3333 18.6667V20.1067C14.9533 19.1655 16.7931 18.6687 18.6667 18.6667H45.3333ZM13.3333 45.3333C13.3333 46.7478 13.8952 48.1044 14.8954 49.1046C15.8956 50.1048 17.2522 50.6667 18.6667 50.6667H50.6667V24H18.6667C15.7333 24 13.3333 26.4 13.3333 29.3333V45.3333Z'
        fill='url(#paint0_linear_2068_46250)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2068_46250'
          x1='58.9419'
          y1='49.7931'
          x2='-17.3933'
          y2='39.587'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WalletIcon;
