import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import extendedDescriptionImage from '../../assets/productImages/realGpt/description.png';
import topLeftImage from '../../assets/productImages/realGpt/topLeft.png';
import topMiddleImage from '../../assets/productImages/realGpt/topMiddle.png';
import topRightImage from '../../assets/productImages/realGpt/topRight.png';
import TopSectionProduct from '../../components/organisms/TopSectionProduct/TopSectionProduct';
import { gaMeasurementId } from '../../config';
import AlphabetIcon from '../../icons/Alphabet';
import CloudIcon from '../../icons/Cloud';
import CodeBracesIcon from '../../icons/CodeBraces';
import DataGraphIcon from '../../icons/DataGraph';
import MarketingIcon from '../../icons/Marketing';
import OperationsIcon from '../../icons/Operations';
import OptimizationIcon from '../../icons/Optimization';
import PuzzleIcon from '../../icons/Puzzle';
import TuningIcon from '../../icons/Tuning';
import VoiceBotIcon from '../../icons/VoiceBot';
import { deviceBreakpoints } from '../../Theme';
import Description from './sections/Description';
import ExtendedDescription from './sections/ExtendedDescription';
import Features from './sections/Features';
import SlideShow from './sections/SlideShow';

ReactGA.initialize(gaMeasurementId);

const RealGpt: React.FC = () => {
  const { t } = useTranslation(['products']);
  ReactGA.send({ hitType: 'pageview', page: '/products/real-gpt', title: 'Real-GPT' });
  const illustrations = [
    {
      title: t('realGpt.topIllustrationLeftTitle'),
      description: t('realGpt.topIllustrationLeftDescription'),
      image: topLeftImage,
    },
    {
      title: t('realGpt.topIllustrationMiddleTitle'),
      description: t('realGpt.topIllustrationMiddleDescription'),
      image: topMiddleImage,
    },
    {
      title: t('realGpt.topIllustrationRightTitle'),
      description: t('realGpt.topIllustrationRightDescription'),
      image: topRightImage,
    },
  ];

  const descriptionBulletPoints = [
    {
      title: t('realGpt.mainDescriptionBulletPointOneTitle'),
      description: t('realGpt.mainDescriptionBulletPointOneDescription'),
    },
    {
      title: t('realGpt.mainDescriptionBulletPointTwoTitle'),
      description: t('realGpt.mainDescriptionBulletPointTwoDescription'),
    },
    {
      title: t('realGpt.mainDescriptionBulletPointThreeTitle'),
      description: t('realGpt.mainDescriptionBulletPointThreeDescription'),
    },
    {
      title: t('realGpt.mainDescriptionBulletPointFourTitle'),
      description: t('realGpt.mainDescriptionBulletPointFourDescription'),
    },
  ];

  const features = [
    {
      title: t('realGpt.featureOneTitle'),
      description: t('realGpt.featureOneDescription'),
      icon: <AlphabetIcon />,
    },
    {
      title: t('realGpt.featureTwoTitle'),
      description: t('realGpt.featureTwoDescription'),
      icon: <VoiceBotIcon />,
    },
    {
      title: t('realGpt.featureThreeTitle'),
      description: t('realGpt.featureThreeDescription'),
      icon: <DataGraphIcon />,
    },
    {
      title: t('realGpt.featureFourTitle'),
      description: t('realGpt.featureFourDescription'),
      icon: <TuningIcon />,
    },
    {
      title: t('realGpt.featureFiveTitle'),
      description: t('realGpt.featureFiveDescription'),
      icon: <PuzzleIcon />,
    },
    {
      title: t('realGpt.featureSixTitle'),
      description: t('realGpt.featureSixDescription'),
      icon: <CloudIcon />,
    },
  ];

  const slideItems = [
    {
      icon: <VoiceBotIcon />,
      title: t('realGpt.slideShowItemOneTitle'),
      description: t('realGpt.slideShowItemOneDescription'),
    },
    {
      icon: <MarketingIcon />,
      title: t('realGpt.slideShowItemTwoTitle'),
      description: t('realGpt.slideShowItemTwoDescription'),
    },
    {
      icon: <OperationsIcon />,
      title: t('realGpt.slideShowItemThreeTitle'),
      description: t('realGpt.slideShowItemThreeDescription'),
    },
    {
      icon: <CodeBracesIcon />,
      title: t('realGpt.slideShowItemFourTitle'),
      description: t('realGpt.slideShowItemFourDescription'),
    },
    {
      icon: <OptimizationIcon />,
      title: t('realGpt.slideShowItemFiveTitle'),
      description: t('realGpt.slideShowItemFiveDescription'),
    },
  ];

  return (
    <Wrapper>
      <SectionWrapper>
        <TopSectionProduct
          title={t('realGpt.topTitle')}
          description={t('realGpt.topDescription')}
          illustrations={illustrations}
          onlyHasMiddleDescription
        />
      </SectionWrapper>

      <SectionWrapper>
        <Description
          mainTitle={t('realGpt.mainDescriptionTitleTop')}
          mainSubtitle={t('realGpt.mainDescriptionSubtitleTop')}
          mainDescription={t('realGpt.mainDescriptionTop')}
          bulletPoints={descriptionBulletPoints}
        />
      </SectionWrapper>

      <SectionWrapper>
        <ExtendedDescription
          title={t('realGpt.extendedDescriptionTitle')}
          subTitle={t('realGpt.extendedDescriptionSubtitle')}
          description={t('realGpt.extendedDescription')}
          image={extendedDescriptionImage}
          buttonText={'Book a Session'}
        />
      </SectionWrapper>

      <SectionWrapper>
        <Features features={features} title={t('realGpt.featuresTitle')} />
      </SectionWrapper>

      <SectionWrapper>
        <SlideShow slideItems={slideItems} title={t('useCases')} />
      </SectionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  margin-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 60px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 64px;

  &:first-child {
    margin-bottom: 110px;
  }
`;

export default RealGpt;
