import React from 'react';

const SmileyIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1136_10018)'>
        <circle cx='32' cy='32' r='29.5' stroke='url(#paint0_linear_1136_10018)' strokeWidth='5' />
        <path
          d='M31.9999 52.4398H31.9919C27.6394 52.4392 23.3957 51.0806 19.8521 48.5533C16.3086 46.026 13.6419 42.4561 12.2239 38.3411L12.1812 38.1945C12.1037 37.8957 12.1468 37.5785 12.3011 37.3112C12.4555 37.0439 12.7087 36.848 13.0062 36.7658C13.3037 36.6835 13.6215 36.7215 13.8913 36.8716C14.161 37.0216 14.3609 37.2717 14.4479 37.5678L14.4505 37.5758C16.8959 44.9465 23.7279 50.1705 31.7812 50.1705L32.0105 50.1678H31.9999H32.0292C35.8777 50.168 39.6302 48.9672 42.7637 46.733C45.8971 44.4987 48.2553 41.3422 49.5092 37.7038L49.5492 37.5731C49.5838 37.4171 49.6499 37.2697 49.7432 37.14C49.8366 37.0103 49.9554 36.9009 50.0924 36.8185C50.2294 36.7361 50.3817 36.6825 50.54 36.6608C50.6984 36.6391 50.8595 36.6499 51.0136 36.6924C51.1677 36.735 51.3115 36.8084 51.4363 36.9083C51.5611 37.0082 51.6643 37.1324 51.7396 37.2734C51.8149 37.4144 51.8607 37.5692 51.8743 37.7285C51.8878 37.8877 51.8689 38.0481 51.8185 38.1998L51.8212 38.1918C48.9545 46.5411 41.1705 52.4371 32.0079 52.4371H31.9999V52.4398Z'
          fill='url(#paint1_linear_1136_10018)'
        />
        <path
          d='M32.0087 52.9975H31.9959C27.613 52.9978 23.3403 51.6098 19.7791 49.0287C16.2179 46.4477 13.5475 42.8036 12.1437 38.6092L12.1004 38.4601C11.9292 37.9098 11.9783 37.3136 12.2373 36.7994C12.4962 36.2853 12.9444 35.8942 13.4855 35.71C14.0267 35.5259 14.6178 35.5634 15.1319 35.8145C15.646 36.0656 16.0421 36.5103 16.2354 37.0531L16.2404 37.0686C18.4505 43.828 24.6452 48.6172 31.9475 48.6172H32.0137H32.2047C35.6596 48.6172 39.0256 47.5105 41.8186 45.4562C44.6116 43.4019 46.6879 40.5058 47.7488 37.1843L47.7819 37.0686C47.987 36.5411 48.385 36.1135 48.8936 35.8743C49.4022 35.6352 49.9823 35.6029 50.5139 35.7841C51.0454 35.9653 51.4876 36.3461 51.7488 36.8476C52.01 37.3491 52.0701 37.9329 51.9169 38.4781L51.922 38.4626C49.083 46.9762 41.2511 53.0001 32.0265 53.0001H32.0137L32.0087 52.9975ZM32.0087 51.0041H32.1818C36.1177 51.0039 39.9525 49.7451 43.1368 47.4081C46.3211 45.0711 48.6913 41.7758 49.908 37.9945L49.9462 37.8608V37.6627H49.7501C47.2243 45.2427 40.2556 50.6055 32.0443 50.6055H32.0087H32.0112H31.9781C28.0766 50.6054 24.2735 49.3692 21.104 47.0709C17.9346 44.7726 15.5586 41.528 14.3105 37.7939L14.2723 37.6601C14.2723 37.6601 14.0762 37.4621 14.0762 37.6601C14.0762 37.6601 13.8802 37.8582 14.0762 37.8582C16.5383 45.541 23.558 51.0016 31.8406 51.0016H32.0239H32.0137L32.0087 51.0041Z'
          fill='url(#paint2_linear_1136_10018)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M48.941 19.4542C48.9415 19.4537 48.9416 19.4528 48.9411 19.4522C48.5828 19.0293 48.1481 18.6918 47.6643 18.4608C47.1801 18.2297 46.6572 18.1104 46.1285 18.1104C45.5998 18.1104 45.0769 18.2297 44.5927 18.4608C44.1085 18.692 43.6735 19.0299 43.3151 19.4532L43.312 19.4565L42.8761 19.9315C42.4798 20.3631 41.799 20.3631 41.4027 19.9315L40.9668 19.4565C40.6058 19.0146 40.1658 18.6576 39.6737 18.4074C39.1815 18.1573 38.6477 18.0193 38.1049 18.0019C37.5621 17.9845 37.0217 18.088 36.517 18.3062C36.0122 18.5244 35.5536 18.8526 35.1695 19.2707C34.7853 19.6887 34.4835 20.1879 34.2827 20.7376C34.0819 21.2872 33.9862 21.8757 34.0016 22.4671C34.017 23.0584 34.1431 23.6401 34.3722 24.1765C34.6013 24.7128 34.9285 25.1925 35.3338 25.5862L35.3369 25.5895L41.4027 32.1975C41.799 32.6292 42.4798 32.6292 42.8761 32.1975L48.9419 25.5895C49.625 24.7397 50.0011 23.6481 50 22.5185C49.9989 21.3917 49.6225 20.3036 48.9409 19.4562C48.9405 19.4556 48.9405 19.4548 48.941 19.4542V19.4542ZM27.941 19.4542C27.9415 19.4537 27.9416 19.4528 27.9411 19.4522C27.5828 19.0293 27.1481 18.6918 26.6643 18.4608C26.1801 18.2297 25.6572 18.1104 25.1285 18.1104C24.5998 18.1104 24.0769 18.2297 23.5927 18.4608C23.1085 18.692 22.6734 19.0299 22.3151 19.4532L22.312 19.4565L21.8761 19.9315C21.4798 20.3631 20.799 20.3631 20.4027 19.9315L19.9668 19.4565C19.6058 19.0146 19.1658 18.6576 18.6737 18.4074C18.1815 18.1573 17.6477 18.0193 17.1049 18.0019C16.5621 17.9845 16.0217 18.088 15.517 18.3062C15.0122 18.5244 14.5537 18.8526 14.1695 19.2707C13.7853 19.6887 13.4835 20.1879 13.2827 20.7376C13.0819 21.2872 12.9862 21.8757 13.0016 22.4671C13.017 23.0584 13.1431 23.6401 13.3722 24.1765C13.6013 24.7128 13.9285 25.1925 14.3338 25.5862L14.3369 25.5895L20.4027 32.1975C20.799 32.6292 21.4798 32.6292 21.8761 32.1975L27.9419 25.5895C28.6251 24.7397 29.0011 23.6481 29 22.5185C28.9989 21.3917 28.6225 20.3036 27.9409 19.4562C27.9405 19.4556 27.9405 19.4548 27.941 19.4542V19.4542Z'
          fill='url(#paint3_linear_1136_10018)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1136_10018'
          x1='67.9226'
          y1='55.7241'
          x2='-33.8578'
          y2='42.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1136_10018'
          x1='54.3139'
          y1='50.398'
          x2='-3.46613'
          y2='30.9576'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1136_10018'
          x1='54.4516'
          y1='50.7494'
          x2='-4.7125'
          y2='32.5704'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1136_10018'
          x1='56.5'
          y1='32.5212'
          x2='-2.66451'
          y2='36.2882'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <clipPath id='clip0_1136_10018'>
          <rect width='64' height='64' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SmileyIcon;
