import React from 'react';

const UserCheckIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1816_36305)'>
        <path
          d='M60.2667 42.6667L64 46.4267L46.5867 64L37.3333 54.6667L41.0667 50.9067L46.5867 56.4533L60.2667 42.6667ZM33.3333 20C36.1623 20 38.8754 21.1238 40.8758 23.1242C42.8762 25.1246 44 27.8377 44 30.6667C44 33.4956 42.8762 36.2088 40.8758 38.2091C38.8754 40.2095 36.1623 41.3333 33.3333 41.3333C30.5044 41.3333 27.7912 40.2095 25.7909 38.2091C23.7905 36.2088 22.6667 33.4956 22.6667 30.6667C22.6667 27.8377 23.7905 25.1246 25.7909 23.1242C27.7912 21.1238 30.5044 20 33.3333 20ZM33.3333 25.3333C31.9188 25.3333 30.5623 25.8952 29.5621 26.8954C28.5619 27.8956 28 29.2522 28 30.6667C28 32.0812 28.5619 33.4377 29.5621 34.4379C30.5623 35.4381 31.9188 36 33.3333 36C34.7478 36 36.1044 35.4381 37.1046 34.4379C38.1048 33.4377 38.6667 32.0812 38.6667 30.6667C38.6667 29.2522 38.1048 27.8956 37.1046 26.8954C36.1044 25.8952 34.7478 25.3333 33.3333 25.3333ZM33.3333 44C35.1467 44 37.3333 44.24 39.76 44.6933L35.3067 49.1467L33.3333 49.0667C25.4133 49.0667 17.0667 52.96 17.0667 54.6667V57.6H33.6L38.6667 62.6667H12V54.6667C12 47.5733 26.2133 44 33.3333 44Z'
          fill='url(#paint0_linear_1816_36305)'
        />
        <path
          d='M17.4347 28.0987C17.056 28.4773 16.5778 28.6667 16 28.6667C15.4222 28.6667 14.9449 28.4773 14.568 28.0987C14.1893 27.7218 14 27.2444 14 26.6667V4C14 3.42222 14.1893 2.94489 14.568 2.568C14.9449 2.18933 15.4222 2 16 2C16.5778 2 17.056 2.18933 17.4347 2.568C17.8116 2.94489 18 3.42222 18 4V26.6667C18 27.2444 17.8116 27.7218 17.4347 28.0987Z'
          fill='url(#paint1_linear_1816_36305)'
        />
        <path
          d='M10.1013 22.7653C9.72267 23.144 9.24444 23.3333 8.66667 23.3333C8.08889 23.3333 7.61156 23.144 7.23467 22.7653C6.856 22.3884 6.66667 21.9111 6.66667 21.3333V9.33333C6.66667 8.75556 6.856 8.27822 7.23467 7.90133C7.61156 7.52267 8.08889 7.33333 8.66667 7.33333C9.24444 7.33333 9.72267 7.52267 10.1013 7.90133C10.4782 8.27822 10.6667 8.75556 10.6667 9.33333V21.3333C10.6667 21.9111 10.4782 22.3884 10.1013 22.7653Z'
          fill='url(#paint2_linear_1816_36305)'
        />
        <path
          d='M3.432 17.432C3.05511 17.8107 2.57778 18 2 18C1.42222 18 0.944889 17.8107 0.568 17.432C0.189333 17.0551 0 16.5778 0 16V14.6667C0 14.0889 0.189333 13.6107 0.568 13.232C0.944889 12.8551 1.42222 12.6667 2 12.6667C2.57778 12.6667 3.05511 12.8551 3.432 13.232C3.81067 13.6107 4 14.0889 4 14.6667V16C4 16.5778 3.81067 17.0551 3.432 17.432Z'
          fill='url(#paint3_linear_1816_36305)'
        />
        <path
          d='M24.768 22.7653C24.3893 23.144 23.9111 23.3333 23.3333 23.3333C22.7556 23.3333 22.2782 23.144 21.9013 22.7653C21.5227 22.3884 21.3333 21.9111 21.3333 21.3333V9.33333C21.3333 8.75556 21.5227 8.27822 21.9013 7.90133C22.2782 7.52267 22.7556 7.33333 23.3333 7.33333C23.9111 7.33333 24.3893 7.52267 24.768 7.90133C25.1449 8.27822 25.3333 8.75556 25.3333 9.33333V21.3333C25.3333 21.9111 25.1449 22.3884 24.768 22.7653Z'
          fill='url(#paint4_linear_1816_36305)'
        />
        <path
          d='M31.4347 17.432C31.056 17.8107 30.5778 18 30 18C29.4222 18 28.944 17.8107 28.5653 17.432C28.1884 17.0551 28 16.5778 28 16V14.6667C28 14.0889 28.1884 13.6107 28.5653 13.232C28.944 12.8551 29.4222 12.6667 30 12.6667C30.5778 12.6667 31.056 12.8551 31.4347 13.232C31.8116 13.6107 32 14.0889 32 14.6667V16C32 16.5778 31.8116 17.0551 31.4347 17.432Z'
          fill='url(#paint5_linear_1816_36305)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1816_36305'
          x1='67.1871'
          y1='58.3104'
          x2='-14.9373'
          y2='45.3339'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1816_36305'
          x1='33.9613'
          y1='25.2184'
          x2='-16.5387'
          y2='17.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1816_36305'
          x1='33.9613'
          y1='25.2184'
          x2='-16.5387'
          y2='17.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1816_36305'
          x1='33.9613'
          y1='25.2184'
          x2='-16.5387'
          y2='17.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1816_36305'
          x1='33.9613'
          y1='25.2184'
          x2='-16.5387'
          y2='17.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_1816_36305'
          x1='33.9613'
          y1='25.2184'
          x2='-16.5387'
          y2='17.1161'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <clipPath id='clip0_1816_36305'>
          <rect width='64' height='64' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserCheckIcon;
