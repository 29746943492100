import React from 'react';

const MedicalIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29.3335 48H34.6668V40H42.6668V34.6667H34.6668V26.6667H29.3335V34.6667H21.3335V40H29.3335V48ZM10.6668 58.6667C9.20017 58.6667 7.94417 58.1441 6.89883 57.0987C5.8535 56.0534 5.33172 54.7983 5.3335 53.3334V21.3334C5.3335 19.8667 5.85617 18.6107 6.9015 17.5654C7.94683 16.52 9.20195 15.9983 10.6668 16H21.3335V10.6667C21.3335 9.20005 21.8562 7.94404 22.9015 6.89871C23.9468 5.85338 25.2019 5.3316 26.6668 5.33338H37.3335C38.8002 5.33338 40.0562 5.85605 41.1015 6.90138C42.1468 7.94671 42.6686 9.20182 42.6668 10.6667V16H53.3335C54.8002 16 56.0562 16.5227 57.1015 17.568C58.1468 18.6134 58.6686 19.8685 58.6668 21.3334V53.3334C58.6668 54.8 58.1442 56.056 57.0988 57.1014C56.0535 58.1467 54.7984 58.6685 53.3335 58.6667H10.6668ZM10.6668 53.3334H53.3335V21.3334H10.6668V53.3334ZM26.6668 16H37.3335V10.6667H26.6668V16Z'
        fill='url(#paint0_linear_1814_36419)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1814_36419'
          x1='61.9357'
          y1='51.7702'
          x2='-22.8813'
          y2='40.4301'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MedicalIcon;
