import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Tag from '../../../components/atoms/Tag/Tag';
import Text from '../../../components/atoms/Text/Text';
import BankIcon from '../../../icons/Bank';
import CallPerson from '../../../icons/CallPerson';
import ControllerIcon from '../../../icons/Controller';
import GraphIcon from '../../../icons/Graph';
import SignalIcon from '../../../icons/Signal';
import SpeechBubbleIcon from '../../../icons/SpeechBubble';
import { deviceBreakpoints } from '../../../Theme';

const AreasSection: React.FC = () => {
  const { t } = useTranslation(['main']);

  const areas = [
    {
      icon: <BankIcon />,
      title: t('areaOneTitle'),
      description: t('areaOneDescription'),
    },
    {
      icon: <GraphIcon />,
      title: t('areaTwoTitle'),
      description: t('areaTwoDescription'),
    },
    {
      icon: <SpeechBubbleIcon />,
      title: t('areaThreeTitle'),
      description: t('areaThreeDescription'),
    },
    {
      icon: <CallPerson />,
      title: t('areaFourTitle'),
      description: t('areaFourDescription'),
    },
    {
      icon: <ControllerIcon />,
      title: t('areaFiveTitle'),
      description: t('areaFiveDescription'),
    },
    {
      icon: <SignalIcon />,
      title: t('areaSixTitle'),
      description: t('areaSixDescription'),
    },
  ];

  return (
    <AreasSectionWrapper className='main-container'>
      <AreasInnerContentWrapper className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
        <Tag text={t('areas')} type='dark' />
        <TitleWrapper>
          <Text type={'h2'} color={'accent'}>
            {t('areasTitle')}
          </Text>
        </TitleWrapper>
        <AreasWrapper>
          {areas.map((area, index) => (
            <Area key={index}>
              <IconWrapper>{area.icon}</IconWrapper>
              <AreaTitleWrapper>
                <Text type={'h4'}>{area.title}</Text>
              </AreaTitleWrapper>
              <Text type={'text'} color='textLight'>
                {area.description}
              </Text>
            </Area>
          ))}
        </AreasWrapper>
      </AreasInnerContentWrapper>
    </AreasSectionWrapper>
  );
};

const AreasSectionWrapper = styled.div`
  display: grid;
  width: 100%;
  margin-top: 64px;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 0px;
  }
`;

// 2 for mobile
const AreasWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 48px;
  margin-top: 44px;

  @media only screen and ${deviceBreakpoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
`;

const AreasInnerContentWrapper = styled.div``;

const Area = styled.div`
  margin-bottom: 72px;
  display: flex;
  flex-direction: column;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 36px;
  }
`;

const TitleWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 60px;
`;

const AreaTitleWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 12px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 18px;
    margin-bottom: 8px;
  }
`;

const IconWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

export default AreasSection;
