import emailjs from '@emailjs/browser';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import contactFormBackground from '../../../assets/contactForm.png';
import { emailJSPublicKey, emailJSServiceId, emailJSTemplateId } from '../../../config';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { deviceBreakpoints } from '../../../Theme';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import Modal from '../../atoms/Modal/Modal';
import Spinner from '../../atoms/Spinner/Spinner';
import Text from '../../atoms/Text/Text';
import TextField from '../../atoms/TextField/TextField';

interface ContactFormProps {
  className?: string;
}

interface FormValue {
  name: string;
  email: string;
  message: string;
  category: string[];
  phone: string;
  companyName?: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ className }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendingWasSuccessful, setSendingWasSuccessful] = useState<boolean>(false);
  const [sendingError, setSendingError] = useState<boolean>(false);
  const [conditionsAgreed, setConditionsAgreed] = useState<boolean>(false);
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  const { t } = useTranslation(['main']);

  const { control, handleSubmit, watch, register } = useForm<FormValue>({
    defaultValues: { email: '', name: '', message: '', category: [], phone: '', companyName: '' },
    mode: 'onChange',
  });

  const fieldNameValue = watch('name');
  const fieldEmailValue = watch('email');
  const fieldMessageValue = watch('message');
  const fieldCategoryValue = watch('category');
  const fieldPhoneValue = watch('phone');
  const fieldCompanyNameValue = watch('companyName');

  const validEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const tags = [
    t('contactTechnologyOne'),
    t('contactTechnologyTwo'),
    t('contactTechnologyThree'),
    t('contactTechnologyFour'),
    t('contactTechnologyFive'),
    t('contactTechnologyOther'),
  ];

  const formRef = useRef<HTMLFormElement>(null);
  const sendMessage = () => {
    const params = {
      name: fieldNameValue,
      email: fieldEmailValue,
      message: fieldMessageValue,
      category: fieldCategoryValue,
      phone: fieldPhoneValue,
      companyName: fieldCompanyNameValue,
    };
    setIsLoading(true);
    emailjs.send(emailJSServiceId, emailJSTemplateId, params, emailJSPublicKey).then(
      () => {
        setIsLoading(false);
        setSendingError(false);
        setSendingWasSuccessful(true);
      },
      () => {
        setSendingWasSuccessful(false);
        setSendingError(true);
        setIsLoading(false);
      },
    );
  };

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (modalIsVisible) {
      document.body.style.overflow = 'hidden';
      if (!isMobile) {
        document.body.style.paddingRight = '15px';
      }
    } else {
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsVisible]);

  return (
    <ContactFormWrapper ref={formRef} className={className} onSubmit={handleSubmit(sendMessage)}>
      <ContactFormLeft className='col-start-2 col-end-5'>
        <TitleWrapper>
          <Text type='h2'>{t('contactTitle')}</Text>
        </TitleWrapper>
        <Text type='link' color='textLight'>
          {t('contactDescription')}
        </Text>
        <ImgWrapper>
          <StyledImg src={contactFormBackground} height='342' width='342' />
        </ImgWrapper>
      </ContactFormLeft>

      {isLoading && (
        <ContactFormRight className='col-start-6 col-end-11'>
          <StatusWrapper>
            <Spinner />
          </StatusWrapper>
        </ContactFormRight>
      )}

      {!sendingWasSuccessful && !sendingError && !isLoading && (
        <ContactFormRight className='col-start-6 col-end-11'>
          <Text type='h3' color='textLight'>
            {t('contactStart')}
          </Text>

          <TagsWrapper className='tag-wrapper'>
            {tags.map((tag, index) => (
              <TagWrapper key={index}>
                <TagLabel $isActive={fieldCategoryValue.includes(tag)}>
                  <TagInput type='checkbox' value={tag} {...register('category')} />
                  <TagText className='tag-label'>{tag}</TagText>
                </TagLabel>
              </TagWrapper>
            ))}
          </TagsWrapper>

          <Input
            control={control}
            name='name'
            rules={{
              required: { value: true, message: t('requiredField') },
              minLength: { value: 3, message: '3 ' + t('minLength') },
            }}
            placeholder={t('contactNamePlaceholder')}
          />

          <Input
            control={control}
            name='email'
            rules={{
              required: { value: true, message: t('requiredField') },
              pattern: {
                value: validEmail,
                message: t('invalidEmailAddressFormat'),
              },
            }}
            placeholder={t('contactEmailPlaceholder')}
          />

          <Input
            control={control}
            name='companyName'
            rules={{
              required: { value: true, message: t('requiredField') },
              minLength: { value: 3, message: '3 ' + t('minLength') },
            }}
            placeholder={t('contactCompanyNamePlaceholder')}
          />
          <Input
            control={control}
            name='phone'
            rules={{
              required: { value: true, message: t('requiredField') },
              minLength: { value: 9, message: '9 ' + t('minLength') },
            }}
            placeholder={t('contactPhonePlaceholder')}
          />

          <TextField
            name='message'
            control={control}
            placeholder={t('contactTextboxPlaceholder')}
            rules={{
              required: { value: true, message: t('requiredField') },
              minLength: { value: 10, message: '20 ' + t('minLength') },
            }}
          />

          <TermsOfUseCheckbox>
            <InputCheckbox
              type='checkbox'
              checked={conditionsAgreed}
              onChange={() => setConditionsAgreed(!conditionsAgreed)}
            />
            <Text type='text' color='textLight'>
              {t('contactTermsAndConditionsAgree')}
            </Text>
            <span>&nbsp;</span>
            <SeeMore onClick={() => setModalIsVisible(true)}>{t('seeMore')}</SeeMore>
            {modalIsVisible && <Modal onClose={() => setModalIsVisible(false)} />}
          </TermsOfUseCheckbox>

          <Button
            type='submit'
            text={t('contactSend')}
            buttonType='regular'
            onClickAction={() => ({})}
            disabled={!conditionsAgreed}
          />
        </ContactFormRight>
      )}

      {sendingWasSuccessful && !sendingError && !isLoading && (
        <ContactFormRight className='col-start-5 col-end-8'>
          <StatusWrapper>
            <Text type='largeText'>{t('contactFormSuccess')}</Text>
          </StatusWrapper>
        </ContactFormRight>
      )}

      {!sendingWasSuccessful && sendingError && !isLoading && (
        <ContactFormRight className='col-start-5 col-end-8'>
          <StatusWrapper>
            <Text type='largeText'>{t('contactFormError')}</Text>
          </StatusWrapper>
        </ContactFormRight>
      )}
    </ContactFormWrapper>
  );
};

const ContactFormWrapper = styled.form`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 32px;
  padding: 78px 0px;
  width: 100%;
  grid-template-columns: repeat(12, [col-start] 1fr);
  display: grid;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
  }
`;

const ContactFormLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 48px;
  }
`;

const ContactFormRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TagsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const ImgWrapper = styled.div`
  margin-top: 32px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    justify-content: center;
  }
`;

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`;

const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;

const TagInput = styled.input`
  position: absolute;
  display: none;
  color: #fff !important;

  &:checked:has(.tag-wrapper) {
    border: ${(props) => `2px solid ${props.theme.colors.purple}`} !important;
    background-color: ${(props) => props.theme.colors.lightBckg} !important;
  }

  &:checked + .tag-label {
    color: ${(props) => props.theme.colors.purple};
  }
`;

const TagLabel = styled.label<{ $isActive: boolean }>`
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  padding: 6px 20px;
  white-space: nowrap;
  border: ${({ $isActive, theme }) =>
    $isActive ? `2px solid ${theme.colors.purple}` : `2px solid ${theme.colors.border}`};
  background-color: ${({ $isActive, theme }) =>
    $isActive ? `${theme.colors.lightBckg}` : 'transparent'};
  cursor: pointer;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 6px 20px;
  }
`;

const TagText = styled.span`
  text-align: center;
  display: block;
`;

const TagWrapper = styled.div<{ $isActive?: boolean }>``;

const StatusWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InputCheckbox = styled.input`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.theme.colors.lightBckg};
  border: ${(props) => `2px solid ${props.theme.colors.border}`} !important;
  border-radius: 4px;
  margin-right: 16px;
`;

const TermsOfUseCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

const SeeMore = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.textLight};
  text-decoration: underline;

  &:hover {
    background: linear-gradient(277.62deg, #5d6be9 -3.88%, #55b8ff 67.51%, #32e0d6 138.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export default ContactForm;
