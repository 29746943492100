import React from 'react';

const PerformanceIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.73718 10.0703C5.95617 8.85132 7.60947 8.1665 9.33337 8.1665H54.6667C56.3906 8.1665 58.0439 8.85132 59.2629 10.0703C60.4819 11.2893 61.1667 12.9426 61.1667 14.6665V26.6665C61.1667 28.0472 60.0474 29.1665 58.6667 29.1665H5.33337C3.95266 29.1665 2.83337 28.0472 2.83337 26.6665V14.6665C2.83337 12.9426 3.51819 11.2893 4.73718 10.0703ZM9.33337 13.1665C8.93555 13.1665 8.55402 13.3245 8.27271 13.6058C7.99141 13.8871 7.83337 14.2687 7.83337 14.6665V24.1665H56.1667V14.6665C56.1667 14.2687 56.0087 13.8871 55.7274 13.6058C55.4461 13.3245 55.0645 13.1665 54.6667 13.1665H9.33337ZM39.568 32.7843C40.5494 31.9264 42.0253 31.9665 42.9586 32.8765L48.932 38.7005C49.9205 39.6644 49.9406 41.2472 48.9767 42.2358C48.0128 43.2243 46.43 43.2444 45.4415 42.2805L41.1209 38.0679L32.1361 45.9221C31.1612 46.7742 29.697 46.7411 28.7618 45.8456L21.7644 39.146L7.25941 53.7986C6.28805 54.7799 4.70516 54.7879 3.72392 53.8165C2.74267 52.8452 2.73466 51.2623 3.70601 50.281L19.9407 33.881C20.9006 32.9114 22.4607 32.8904 23.4463 33.8341L30.5675 40.6522L39.568 32.7843Z'
        fill='url(#paint0_linear_1640_8873)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3134 18.6665C12.3134 17.2858 13.4327 16.1665 14.8134 16.1665H17.48C18.8608 16.1665 19.98 17.2858 19.98 18.6665C19.98 20.0472 18.8608 21.1665 17.48 21.1665H14.8134C13.4327 21.1665 12.3134 20.0472 12.3134 18.6665ZM22.9827 18.6665C22.9827 17.2858 24.102 16.1665 25.4827 16.1665H49.4827C50.8634 16.1665 51.9827 17.2858 51.9827 18.6665C51.9827 20.0472 50.8634 21.1665 49.4827 21.1665H25.4827C24.102 21.1665 22.9827 20.0472 22.9827 18.6665ZM5.33337 21.4998C6.71409 21.4998 7.83337 22.6191 7.83337 23.9998V35.9998C7.83337 37.3806 6.71409 38.4998 5.33337 38.4998C3.95266 38.4998 2.83337 37.3806 2.83337 35.9998V23.9998C2.83337 22.6191 3.95266 21.4998 5.33337 21.4998ZM58.6667 21.4998C60.0474 21.4998 61.1667 22.6191 61.1667 23.9998V49.3332C61.1667 51.0571 60.4819 52.7104 59.2629 53.9294C58.0439 55.1483 56.3906 55.8332 54.6667 55.8332H16C14.6193 55.8332 13.5 54.7139 13.5 53.3332C13.5 51.9525 14.6193 50.8332 16 50.8332H54.6667C55.0645 50.8332 55.4461 50.6751 55.7274 50.3938C56.0087 50.1125 56.1667 49.731 56.1667 49.3332V23.9998C56.1667 22.6191 57.286 21.4998 58.6667 21.4998Z'
        fill='url(#paint1_linear_1640_8873)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1640_8873'
          x1='64.742'
          y1='48.5433'
          x2='-27.0874'
          y2='33.0992'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1640_8873'
          x1='64.742'
          y1='50.7039'
          x2='-26.1703'
          y2='32.8288'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PerformanceIcon;
