import React from 'react';

const MicrophoneCircleIcon: React.FC = () => {
  return (
    <svg width='53' height='53' viewBox='0 0 53 53' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.333252 26.792C0.333252 12.4374 11.9787 0.791992 26.3333 0.791992C40.6878 0.791992 52.3333 12.4374 52.3333 26.792C52.3333 41.1466 40.6878 52.792 26.3333 52.792C11.9787 52.792 0.333252 41.1466 0.333252 26.792ZM26.3333 4.79199C14.1878 4.79199 4.33325 14.6466 4.33325 26.792C4.33325 38.9374 14.1878 48.792 26.3333 48.792C38.4787 48.792 48.3333 38.9374 48.3333 26.792C48.3333 14.6466 38.4787 4.79199 26.3333 4.79199ZM16.3333 20.8295C17.4378 20.8295 18.3333 21.7249 18.3333 22.8295V25.8195C18.3333 27.5703 19.2379 29.2861 20.7884 30.6174C22.3425 31.9519 24.3889 32.7545 26.3333 32.7545C28.2776 32.7545 30.324 31.9519 31.8781 30.6174C33.4286 29.2861 34.3333 27.5703 34.3333 25.8195V22.8295C34.3333 21.7249 35.2287 20.8295 36.3333 20.8295C37.4378 20.8295 38.3333 21.7249 38.3333 22.8295V25.8195C38.3333 28.9961 36.7017 31.7479 34.484 33.6521C32.7679 35.1258 30.6074 36.1788 28.3333 36.5779V38.792H30.3333C31.4378 38.792 32.3333 39.6874 32.3333 40.792C32.3333 41.8965 31.4378 42.792 30.3333 42.792H22.3333C21.2287 42.792 20.3333 41.8965 20.3333 40.792C20.3333 39.6874 21.2287 38.792 22.3333 38.792H24.3333V36.5779C22.0591 36.1788 19.8987 35.1258 18.1825 33.6521C15.9648 31.7479 14.3333 28.9961 14.3333 25.8195V22.8295C14.3333 21.7249 15.2287 20.8295 16.3333 20.8295ZM26.3333 10.792C29.647 10.792 32.3333 13.4783 32.3333 16.792V24.792C32.3333 28.1057 29.647 30.792 26.3333 30.792C23.0195 30.792 20.3333 28.1057 20.3333 24.792V16.792C20.3333 13.4783 23.0195 10.792 26.3333 10.792Z'
        fill='url(#paint0_linear_1234_2984)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1234_2984'
          x1='55.5203'
          y1='46.0679'
          x2='-27.1762'
          y2='35.0113'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MicrophoneCircleIcon;
