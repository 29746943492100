import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import backgroundImageLeft from '../../../assets/backgroundTopLeft.png';
import backgroundImageRight from '../../../assets/backgroundTopMain.png';
import demoItemOneImage from '../../../assets/demoItemOne.png';
import demoItemThreeImage from '../../../assets/demoItemThree.png';
import demoItemTwoImage from '../../../assets/demoItemTwo.png';
import Button from '../../../components/atoms/Button/Button';
import Text from '../../../components/atoms/Text/Text';
import { DemoWebsiteLink } from '../../../config';
import CurvedArrowBottomIcon from '../../../icons/CurvedArrowBottom';
import CurvedArrowTopIcon from '../../../icons/CurvedArrowTop';
import { deviceBreakpoints, deviceSizes } from '../../../Theme';

interface TopSectionProps {
  title: string;
  description: string;
  extendedDescription?: React.ReactElement;
  image?: string;
}

const TopSection: React.FC<TopSectionProps> = ({
  title,
  description,
  extendedDescription,
  image,
}) => {
  const { t } = useTranslation(['main']);

  const location = useLocation();

  const handleRedirectToDemoSite = (link: string) => {
    window.open(link, '_blank')?.focus();
  };

  const [currentDemoItemIndex, setCurrentDemoIndex] = useState(0);

  return (
    <TopSectionWrapper className='main-container'>
      <StyledBackgroundImgLeft src={backgroundImageLeft} />

      <TopLeftSection className='col-start-2 col-end-6 col-start-1-m col-end-none-m'>
        <ArrowButtonWrapper>
          <ArrowTopWrapper>
            <CurvedArrowTopIcon />
          </ArrowTopWrapper>
        </ArrowButtonWrapper>
        <MainTitleWrapper>
          <Text type={'xxlText'} color={'accent'}>
            <Trans i18nKey='mainTitle'>{title}</Trans>
          </Text>
        </MainTitleWrapper>
        <MainDescriptionWrapper>
          <Text type={'text'}>
            <Trans i18nKey='mainDescription'>{description}</Trans>
          </Text>
          {extendedDescription}
        </MainDescriptionWrapper>
        <ArrowButtonWrapper>
          {!location.pathname.includes('about-us') && (
            <ButtonWrapper
              href='#contactForm'
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick={(e: any) => {
                e.preventDefault();

                const anchor = document.querySelectorAll('a[href^="#"]')[0];
                const selector = anchor.getAttribute('href');
                const element = selector ? document.querySelector(selector) : null;

                if (element) {
                  window.scrollTo({
                    top: element.getBoundingClientRect().top + window.scrollY - 120,
                    behavior: 'smooth',
                  });
                }
              }}
            >
              <Button buttonType='regular' text={t('requestDemo')} onClickAction={() => ({})} />
            </ButtonWrapper>
          )}

          <ArrowWrapper>
            <CurvedArrowBottomIcon />
          </ArrowWrapper>
        </ArrowButtonWrapper>
      </TopLeftSection>
      {image ? (
        <TopRightSection className='col-start-7 col-start-1-m col-end-none' $hasImage>
          <StyledImg src={image} />
          <StyledBackgroundImg src={backgroundImageRight} width='560' height='295' />
        </TopRightSection>
      ) : (
        <TopRightSection className='col-start-8 col-start-1-m col-end-none' $hasImage={!!image}>
          <>
            {location.pathname === '/' && (
              <DemoWrapper>
                <DemoItemWrapper>
                  <DemoItem $isVisible={currentDemoItemIndex === 0}>
                    <DemoItemImageWrapper>
                      <StyledDemoImg src={demoItemOneImage} />
                    </DemoItemImageWrapper>
                    <DemoItemTitleWrapper>
                      <Text type='h4' color='accent'>
                        {t('demoItemOneTitle')}
                      </Text>
                    </DemoItemTitleWrapper>

                    <DemoBodyWrapper>
                      <Text type='text' color='accent'>
                        <Trans> {t('demoItemOneDescription')}</Trans>
                      </Text>
                    </DemoBodyWrapper>

                    <SelectButtonWrapper>
                      <Button
                        buttonType='blue'
                        text={t('visitDemoSite')}
                        onClickAction={() => handleRedirectToDemoSite(DemoWebsiteLink)}
                      />
                    </SelectButtonWrapper>
                  </DemoItem>

                  <DemoItem $isVisible={currentDemoItemIndex === 1}>
                    <DemoItemImageWrapper>
                      <StyledDemoImg src={demoItemTwoImage} />
                    </DemoItemImageWrapper>
                    <DemoItemTitleWrapper>
                      <Text type='h4' color='accent'>
                        {t('demoItemTwoTitle')}
                      </Text>
                    </DemoItemTitleWrapper>

                    <DemoBodyWrapper>
                      <Text type='text' color='accent'>
                        <Trans> {t('demoItemTwoDescription')}</Trans>
                      </Text>
                    </DemoBodyWrapper>

                    <SelectButtonWrapper>
                      <Button
                        buttonType='blue'
                        text={t('visitDemoSite')}
                        onClickAction={() => handleRedirectToDemoSite(DemoWebsiteLink)}
                      />
                    </SelectButtonWrapper>
                  </DemoItem>

                  <DemoItem $isVisible={currentDemoItemIndex === 2}>
                    <DemoItemImageWrapper>
                      <StyledDemoImg src={demoItemThreeImage} />
                    </DemoItemImageWrapper>
                    <DemoItemTitleWrapper>
                      <Text type='h4' color='accent'>
                        {t('demoItemThreeTitle')}
                      </Text>
                    </DemoItemTitleWrapper>

                    <DemoBodyWrapper>
                      <Text type='text' color='accent'>
                        <Trans> {t('demoItemThreeDescription')}</Trans>
                      </Text>
                    </DemoBodyWrapper>

                    <SelectButtonWrapper>
                      <Button
                        buttonType='blue'
                        text={t('visitDemoSite')}
                        onClickAction={() => handleRedirectToDemoSite(DemoWebsiteLink)}
                      />
                    </SelectButtonWrapper>
                  </DemoItem>
                </DemoItemWrapper>

                <DemoNavigation>
                  <DemoNavigationButton
                    $isActive={currentDemoItemIndex === 0}
                    onClick={() => {
                      setCurrentDemoIndex(0);
                    }}
                  />
                  <DemoNavigationButton
                    $isActive={currentDemoItemIndex === 1}
                    onClick={() => {
                      setCurrentDemoIndex(1);
                    }}
                  />
                  <DemoNavigationButton
                    $isActive={currentDemoItemIndex === 2}
                    onClick={() => {
                      setCurrentDemoIndex(2);
                    }}
                  />
                </DemoNavigation>
              </DemoWrapper>
            )}
          </>{' '}
          <StyledBackgroundImg src={backgroundImageRight} width='560' height='295' />
        </TopRightSection>
      )}
    </TopSectionWrapper>
  );
};

const TopRightSection = styled.div<{ $hasImage: boolean }>`
  display: flex;
  justify-self: 'start';
  align-items: center;

  @media only screen and (max-width: ${deviceSizes.gridBreakPoint}) {
    justify-self: ${(props) => (!props.$hasImage ? 'center' : 'start')};
  }
`;

const DemoItem = styled.div<{ $isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  width: 340px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 15px 40px rgba(32, 139, 185, 0.15);
  min-height: 585px;
  border-radius: 12px;
  ${(props) => !props.$isVisible && 'display: none'};

  @media only screen and ${deviceBreakpoints.mobile} {
    box-shadow: 0px 15px 40px rgba(32, 139, 185, 0.15);
    width: 100%;
    display: flex;
    min-height: fit-content;
  }
`;

const DemoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 15px 40px rgba(32, 139, 185, 0.15);
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  height: fit-content;
  gap: 20px;
  padding: 24px;

  @media only screen and ${deviceBreakpoints.mobile} {
    border: none;
    padding: 0;
    box-shadow: none;
    background: none;
  }
`;

const DemoItemWrapper = styled.div`
  display: flex;

  width: 340px;
  gap: 0px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    gap: 16px;
  }
`;

const DemoItemTitleWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
`;

const DemoBodyWrapper = styled.div`
  flex: 1;
`;

const DemoItemImageWrapper = styled.div`
  height: 230px;
  width: 100%;
`;

const DemoNavigation = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

const DemoNavigationButton = styled.div<{ $isActive: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid ${(props) => props.theme.colors.purple};
  border-radius: 50%;

  background: ${(props) => (props.$isActive ? `${props.theme.colors.purple}` : 'none')};
`;

const SelectButtonWrapper = styled.div`
  margin-top: 24px;
`;

const TopLeftSection = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and ${deviceBreakpoints.mobile} {
    max-width: 100%;
    margin-bottom: 48px;
  }
`;

const TopSectionWrapper = styled.div`
  margin-top: 24px;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const MainTitleWrapper = styled.div`
  white-space: nowrap;

  margin-top: 8px;
  margin-bottom: 16px;

  padding-right: 10px;
  padding-left: 10px;
`;

const MainDescriptionWrapper = styled.div`
  margin-bottom: 24px;

  padding-right: 10px;
  padding-left: 10px;
`;

const ArrowButtonWrapper = styled.div`
  position: relative;

  padding-right: 10px;
  padding-left: 10px;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: -25px;
  left: 180px;
`;

const ArrowTopWrapper = styled(ArrowWrapper)`
  left: -20px;

  @media only screen and ${deviceBreakpoints.mobile} {
    left: -10px;
  }
`;
const StyledDemoImg = styled.img`
  max-width: 300px;
  height: auto;
`;

const StyledImg = styled.img``;

const ButtonWrapper = styled.a`
  text-decoration: none;
`;

const StyledBackgroundImg = styled.img`
  width: 1250px;
  height: 943px;
  position: absolute;
  z-index: -10;

  @media only screen and (max-width: 580px) {
    width: 560px;
  }
`;

const StyledBackgroundImgLeft = styled.img`
  width: 500px;
  height: 943px;
  position: absolute;
  z-index: -10;
  left: 0;
  top: 300px;

  @media only screen and (max-width: 580px) {
    width: 560px;
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

export default TopSection;
