import React from 'react';

const SheetVolumeIcon: React.FC = () => {
  return (
    <svg width='57' height='57' viewBox='0 0 57 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.66668 56.6667C4.20001 56.6667 2.9449 56.1449 1.90134 55.1013C0.85601 54.056 0.333344 52.8 0.333344 51.3333V8.66667C0.333344 7.2 0.85601 5.944 1.90134 4.89867C2.9449 3.85511 4.20001 3.33333 5.66668 3.33333H29.6667L24.3333 8.66667H5.66668V51.3333H35V43.3333H40.3333V51.3333C40.3333 52.8 39.8116 54.056 38.768 55.1013C37.7227 56.1449 36.4667 56.6667 35 56.6667H5.66668ZM11 46V40.6667H29.6667V46H11ZM11 38V32.6667H24.3333V38H11ZM35 38L24.3333 27.3333H16.3333V14H24.3333L35 3.33333V38ZM40.3333 29.8667V11.4667C41.9333 12.4 43.2222 13.6667 44.2 15.2667C45.1778 16.8667 45.6667 18.6667 45.6667 20.6667C45.6667 22.6667 45.1778 24.4667 44.2 26.0667C43.2222 27.6667 41.9333 28.9333 40.3333 29.8667ZM40.3333 41.3333V35.7333C43.4445 34.6222 46 32.6996 48 29.9653C50 27.2329 51 24.1333 51 20.6667C51 17.2 50 14.0996 48 11.3653C46 8.63289 43.4445 6.71111 40.3333 5.6V0C44.9556 1.2 48.7778 3.69956 51.8 7.49867C54.8222 11.2996 56.3333 15.6889 56.3333 20.6667C56.3333 25.6444 54.8222 30.0338 51.8 33.8347C48.7778 37.6338 44.9556 40.1333 40.3333 41.3333Z'
        fill='url(#paint0_linear_1361_1746)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1361_1746'
          x1='59.7656'
          y1='49.3391'
          x2='-29.3288'
          y2='37.5673'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SheetVolumeIcon;
