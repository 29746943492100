import React from 'react';

const GenderIcon: React.FC = () => {
  return (
    <svg width='43' height='56' viewBox='0 0 43 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M40 0H30C29.2044 0 28.4413 0.316071 27.8787 0.87868C27.3161 1.44129 27 2.20435 27 3C27 3.79565 27.3161 4.55871 27.8787 5.12132C28.4413 5.68393 29.2044 6 30 6H32.75L28.84 9.9175C26.8379 8.25376 24.4804 7.07204 21.9494 6.46344C19.4183 5.85485 16.7814 5.83565 14.2417 6.40732C11.7021 6.97898 9.32767 8.12624 7.30152 9.76065C5.27538 11.3951 3.65165 13.4729 2.55552 15.8341C1.4594 18.1952 0.920154 20.7766 0.979376 23.3791C1.0386 25.9816 1.6947 28.5357 2.8971 30.8446C4.09949 33.1534 5.81607 35.1553 7.91445 36.6958C10.0128 38.2364 12.437 39.2744 15 39.73V42H10C9.20435 42 8.44129 42.3161 7.87868 42.8787C7.31607 43.4413 7 44.2043 7 45C7 45.7957 7.31607 46.5587 7.87868 47.1213C8.44129 47.6839 9.20435 48 10 48H15V53C15 53.7957 15.3161 54.5587 15.8787 55.1213C16.4413 55.6839 17.2044 56 18 56C18.7957 56 19.5587 55.6839 20.1213 55.1213C20.6839 54.5587 21 53.7957 21 53V48H26C26.7957 48 27.5587 47.6839 28.1213 47.1213C28.6839 46.5587 29 45.7957 29 45C29 44.2043 28.6839 43.4413 28.1213 42.8787C27.5587 42.3161 26.7957 42 26 42H21V39.73C23.6849 39.2511 26.215 38.1326 28.3762 36.4691C30.5374 34.8056 32.2662 32.646 33.4163 30.1731C34.5665 27.7002 35.1041 24.9867 34.9838 22.2621C34.8634 19.5375 34.0887 16.8819 32.725 14.52L37 10.25V13C37 13.7956 37.3161 14.5587 37.8787 15.1213C38.4413 15.6839 39.2043 16 40 16C40.7957 16 41.5587 15.6839 42.1213 15.1213C42.6839 14.5587 43 13.7956 43 13V3C43 2.20435 42.6839 1.44129 42.1213 0.87868C41.5587 0.316071 40.7957 0 40 0ZM18 34C15.8244 34 13.6977 33.3549 11.8887 32.1462C10.0798 30.9375 8.66989 29.2195 7.83733 27.2095C7.00476 25.1995 6.78693 22.9878 7.21136 20.854C7.6358 18.7202 8.68345 16.7602 10.2218 15.2218C11.7602 13.6834 13.7202 12.6358 15.854 12.2114C17.9878 11.7869 20.1995 12.0048 22.2095 12.8373C24.2195 13.6699 25.9375 15.0798 27.1462 16.8887C28.3549 18.6977 29 20.8244 29 23C28.9967 25.9164 27.8367 28.7123 25.7745 30.7745C23.7123 32.8367 20.9164 33.9967 18 34Z'
        fill='url(#paint0_linear_1361_1776)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1361_1776'
          x1='45.5757'
          y1='48.7586'
          x2='-21.7741'
          y2='42.0011'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GenderIcon;
