import React from 'react';
import styled from 'styled-components';

const Separator: React.FC = () => {
  return <StyledSeparator />;
};

const StyledSeparator = styled.div`
  width: 80px;
  height: 2px;
  background: linear-gradient(277.62deg, #5d6be9 -3.88%, #55b8ff 67.51%, #32e0d6 138.9%);
`;

export default Separator;
