import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../../components/atoms/Button/Button';
import Tag from '../../../components/atoms/Tag/Tag';
import Text from '../../../components/atoms/Text/Text';
import { deviceBreakpoints } from '../../../Theme';

interface ExtendedDescriptionProps {
  title: string;
  subTitle: string;
  description: string;
  image: string;
  buttonText: string;
  tags?: string[];
}

const ExtendedDescription: React.FC<ExtendedDescriptionProps> = ({
  title,
  subTitle,
  description,
  image,
  buttonText,
  tags,
}) => {
  return (
    <ExtendedDescriptionWrapper>
      <ContentWrapper className='main-container'>
        <ExtendedDescriptionLeft className='col-start-2 col-end-6'>
          <Text type='largeText' color='accent'>
            {subTitle}
          </Text>
          <TitleWrapper>
            <Text type='h2' color='accent'>
              {title}
            </Text>
          </TitleWrapper>
          <DescriptionWrapper>
            <Text type='text' color='accent'>
              <Trans>{description}</Trans>
            </Text>
          </DescriptionWrapper>
          {tags && (
            <TagWrapper>
              {tags.map((tag, index) => (
                <Tag type='light' text={tag} key={`tag_${index}`} />
              ))}
            </TagWrapper>
          )}
          <ButtonWrapper
            href='#contactForm'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e: any) => {
              e.preventDefault();

              const anchor = document.querySelectorAll('a[href^="#"]')[0];
              const selector = anchor.getAttribute('href');
              const element = selector ? document.querySelector(selector) : null;

              if (element) {
                window.scrollTo({
                  top: element.getBoundingClientRect().top + window.scrollY - 120,
                  behavior: 'smooth',
                });
              }
            }}
          >
            <Button buttonType='regular' text={buttonText} onClickAction={() => ({})} />
          </ButtonWrapper>
        </ExtendedDescriptionLeft>
        <ExtendedDescriptionRight className='col-start-7 col-end-12'>
          <StyledImg src={image} />
        </ExtendedDescriptionRight>
      </ContentWrapper>
    </ExtendedDescriptionWrapper>
  );
};

const ExtendedDescriptionWrapper = styled.div`
  display: grid;
  padding: 64px 0px;
  background: linear-gradient(
    277.68deg,
    rgba(93, 107, 233, 0.24) -3.86%,
    rgba(74, 192, 255, 0.24) 60.85%,
    rgba(50, 224, 214, 0.24) 109%
  );

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 32px;
    margin-bottom: 60px;
  }
`;

const ContentWrapper = styled.div`
  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`;

const ExtendedDescriptionLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
`;
const DescriptionWrapper = styled.div`
  margin-bottom: 24px;
`;

const TitleWrapper = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
`;

const ExtendedDescriptionRight = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`;

const ButtonWrapper = styled.a`
  text-decoration: none;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 24px;
`;

export default ExtendedDescription;
