import React from 'react';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';
import Text from '../Text/Text';

interface IllustrationBoxProps {
  title: string;
  subtitle: string;
  bodyTags: string[];
}

const IllustrationBox: React.FC<IllustrationBoxProps> = ({ title, subtitle, bodyTags }) => {
  const chunkSize = 2;
  const bodyTagChunks = [];
  for (let i = 0; i < bodyTags.length; i += chunkSize) {
    const chunk = bodyTags.slice(i, i + chunkSize);
    bodyTagChunks.push(chunk);
  }

  return (
    <BoxWrapper>
      <BoxTop>
        <Text type='text' color='text'>
          {subtitle}
        </Text>
        <Text type='text' color='accent'>
          {title}
        </Text>
      </BoxTop>

      <BoxBody>
        {bodyTagChunks.map((tag, index) => (
          <BoxBodyTagRow key={`body_tag_${index}`}>
            <BoxBodyTag>
              <Text type='text' color='text'>
                {tag[0]}
              </Text>
            </BoxBodyTag>
            {tag.length === 2 && (
              <BoxBodyTag>
                <Text type='text' color='text'>
                  {tag[1]}
                </Text>
              </BoxBodyTag>
            )}
          </BoxBodyTagRow>
        ))}
      </BoxBody>
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  padding: 24px;
  border-radius: 40px;
  border: 1px solid ${(props) => props.theme.colors.lightBckg};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const BoxBodyTag = styled.div`
  border-radius: 23px;
  background-color: ${(props) => props.theme.colors.lightBckg};
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  @media only screen and ${deviceBreakpoints.mobile} {
    & div {
      text-align: center;
    }
  }
`;

const BoxBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BoxBodyTagRow = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

const BoxTop = styled.div`
  max-width: 70%;
  text-align: center;
`;

export default IllustrationBox;
