import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import blogLogo from '../../../assets/sns/blogGrey.png';
import facebookLogo from '../../../assets/sns/facebookGrey.png';
import instagramLogo from '../../../assets/sns/instagramGrey.png';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { deviceBreakpoints, deviceSizes } from '../../../Theme';
import Logo from '../../atoms/Logo/Logo';
import Modal from '../../atoms/Modal/Modal';
import Text from '../../atoms/Text/Text';
import ContactForm from '../../molecules/ContactForm/ContactForm';

const Footer: React.FC = () => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const location = useLocation();
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (modalIsVisible) {
      document.body.style.overflow = 'hidden';
      if (!isMobile) {
        document.body.style.paddingRight = '15px';
      }
    } else {
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsVisible]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper id='contactForm'>
      <ContentWrapper className='main-container'>
        {!location.pathname.includes('about-us') && (
          <ContactForm className='col-start-1 col-end-none col-end-12-m' />
        )}
        <LowerFooter className='col-start-1 col-end-12'>
          <FooterColumn>
            <Logo
              type='footer'
              size={windowWidth < 1024 ? 'small' : 'default'}
              onClick={() => {
                if (location.pathname === '/') {
                  window.scrollTo(0, 0);
                } else {
                  navigate('/');
                }
              }}
            />

            <LinkWrapper onClick={() => setModalIsVisible(true)}>
              <Text type='smallText' color='textLight'>
                {t('termsOfPolicy')}
              </Text>
            </LinkWrapper>
            {modalIsVisible && <Modal onClose={() => setModalIsVisible(false)} />}

            <div>
              <Text type='smallText' color='textLight'>
                Copyright 2024
              </Text>
              <Text type='smallText' color='textLight'>
                Darwin Intelligence | All Rights Reserved
              </Text>
            </div>
          </FooterColumn>
          <FooterColumnWithPadding>
            <LinkWrapper href='/products/real-gpt'>
              <Text type='smallText' color='textLight'>
                {'Real-GPT'}
              </Text>
            </LinkWrapper>
            <LinkWrapper href='/products/real-stt'>
              <Text type='smallText' color='textLight'>
                {'Real-STT'}
              </Text>
            </LinkWrapper>
            <LinkWrapper href='/products/real-sid'>
              <Text type='smallText' color='textLight'>
                {'Real-SID'}
              </Text>
            </LinkWrapper>
            <LinkWrapper href='/products/real-fds'>
              <Text type='smallText' color='textLight'>
                {'Real-FDS'}
              </Text>
            </LinkWrapper>
            <LinkWrapper href='/products/real-si'>
              <Text type='smallText' color='textLight'>
                {'Real-SI'}
              </Text>
            </LinkWrapper>
          </FooterColumnWithPadding>
          <FooterColumnWithPadding>
            <LinkWrapper href='/about-us'>
              <Text type='smallText' color='textLight'>
                {t('aboutUs')}
              </Text>
            </LinkWrapper>

            <LogosWrapper>
              <img
                width={24}
                height={24}
                src={blogLogo}
                onClick={() => window.open('https://blog.naver.com/darwinai_100', '_blank')}
              />
              <img
                width={24}
                height={24}
                src={instagramLogo}
                onClick={() =>
                  window.open('https://www.instagram.com/darwinaiintelligence/', '_blank')
                }
              />
              <img
                width={24}
                height={24}
                src={facebookLogo}
                onClick={() => window.open('https://facebook.com/darwinintelligence', '_blank')}
              />
            </LogosWrapper>
          </FooterColumnWithPadding>

          <FooterColumnWithPadding>
            <Text type='smallText' color='white'>
              Tel +82 2 568 1383
            </Text>
            <Text type='smallText' color='white'>
              Fax +82 2 568 1363
            </Text>
            <Text type='smallText' color='white'>
              E-Mail: sales@darwinai.ai
            </Text>
            <Text type='smallText' color='textLight'>
              <Trans key='officeAddress'>{t('officeAddress')}</Trans>
            </Text>
          </FooterColumnWithPadding>
        </LowerFooter>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.accent};
  width: 100%;
  display: grid;
  border-radius: 80px 80px 0px 0px;
  overflow: auto;

  @media only screen and (max-width: ${deviceSizes.gridBreakPoint}) {
    border-radius: 40px 40px 0px 0px;
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    border-radius: 40px 40px 0px 0px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
  }
`;

const LowerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    gap: 48px;
    padding-left: 16px;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const FooterColumnWithPadding = styled(FooterColumn)`
  padding-top: 20px;
`;

const LinkWrapper = styled.a`
  display: flex;
  gap: 12px;
  cursor: pointer;
  text-decoration: none;
`;

const LogosWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export default Footer;
