import React from 'react';

const BoxLines: React.FC = () => {
  return (
    <svg width='49' height='49' viewBox='0 0 49 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.00008 32.792V43.4587H16.6667V48.792H0.666748V32.792H6.00008ZM48.6667 32.792V48.792H32.6667V43.4587H43.3334V32.792H48.6667ZM27.3334 8.79199V40.792H22.0001V8.79199H27.3334ZM16.6667 16.792V32.792H11.3334V16.792H16.6667ZM38.0001 16.792V32.792H32.6667V16.792H38.0001ZM16.6667 0.791992V6.12533H6.00008V16.792H0.666748V0.791992H16.6667ZM48.6667 0.791992V16.792H43.3334V6.12533H32.6667V0.791992H48.6667Z'
        fill='url(#paint0_linear_1234_1666)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1234_1666'
          x1='51.6087'
          y1='42.5851'
          x2='-24.7266'
          y2='32.379'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BoxLines;
