import { useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';

import Footer from './components/organisms/Footer/Footer';
import Header from './components/organisms/Header/Header';
import AboutUs from './pages/AboutUs/AboutUs';
import MainPage from './pages/Main/Main';
import RealFds from './pages/Products/RealFds';
import RealGpt from './pages/Products/RealGpt';
import RealSi from './pages/Products/RealSi';
import RealSid from './pages/Products/RealSid';
import RealStt from './pages/Products/RealStt';
import { deviceBreakpoints } from './Theme';

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <WholeWrapper>
      <Header />

      <BodyWrapper>
        <Outlet />
      </BodyWrapper>
      <Footer />
    </WholeWrapper>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <MainPage />,
      },
      // TBA: Products, Blog
      {
        path: 'about-us',
        element: <AboutUs />,
      },
      {
        path: '/products/real-stt',
        element: <RealStt />,
      },
      {
        path: '/products/real-sid',
        element: <RealSid />,
      },
      {
        path: '/products/real-fds',
        element: <RealFds />,
      },
      {
        path: '/products/real-si',
        element: <RealSi />,
      },
      {
        path: '/products/real-gpt',
        element: <RealGpt />,
      },
      { path: '*', element: <Navigate to={'/'} replace /> },
    ],
  },
]);

const Routing = () => {
  return <RouterProvider router={router} />;
};

const WholeWrapper = styled.div``;

const BodyWrapper = styled.div`
  overflow-x: hidden;
  position: relative;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
  }
`;

export default Routing;
