import React from 'react';
import ReactGA from 'react-ga4';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import storyImage from '../../assets/aboutUs/story.png';
import topImage from '../../assets/aboutUs/top.png';
import valuesImage from '../../assets/aboutUs/values.png';
import Tag from '../../components/atoms/Tag/Tag';
import Text from '../../components/atoms/Text/Text';
import PartnerLogos from '../../components/molecules/PartnerLogos/PartnerLogos';
import TopSection from '../../components/organisms/TopSection/TopSection';
import { gaMeasurementId } from '../../config';
import { deviceBreakpoints } from '../../Theme';
import CoreValues from './CoreValues';

ReactGA.initialize(gaMeasurementId);

const AboutUs: React.FC = () => {
  const { t } = useTranslation(['aboutUs']);
  ReactGA.send({ hitType: 'pageview', page: '/about-us', title: 'About Us' });
  return (
    <Wrapper>
      <TopSection title={t('topTitle')} description={t('topDescription')} image={topImage} />
      <StorySectionWrapper className='main-container'>
        <ImageSection className='col-start-3 col-end-6 col-start-1-m col-end-none-m'>
          <ImageWrapper>
            <StyledImg src={storyImage} />
          </ImageWrapper>
        </ImageSection>

        <DescriptionWrapperRight className='col-start-7 col-end-12 col-start-1-m col-end-none-m'>
          <Tag text={t('ourStory')} type='dark' />
          <Text type='h2' color='accent'>
            {t('ourStoryTitle')}
          </Text>
          <Trans>
            <Text type='text' color='text'>
              {t('ourStoryDescription')}
            </Text>
          </Trans>
        </DescriptionWrapperRight>
      </StorySectionWrapper>
      <SectionWrapper className='main-container'>
        <DescriptionWrapper className='col-start-2 col-end-7 col-start-1-m col-end-none-m'>
          <Tag text={t('ourValues')} type='dark' />

          <Text type='h2' color='accent'>
            {t('ourValuesTitle')}
          </Text>
          <Trans>
            <Text type='text' color='text'>
              {t('ourValuesDescription')}
            </Text>
          </Trans>
        </DescriptionWrapper>
        <ImageSection className='col-start-8 col-end-11 col-start-1-m col-end-none-m'>
          <ImageWrapper>
            <StyledImg src={valuesImage} />
          </ImageWrapper>
        </ImageSection>
      </SectionWrapper>

      <SectionWrapper>
        <CoreValues />
      </SectionWrapper>

      <SectionWrapper className='main-container'>
        <div className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
          <Text type='h2' color='accent'>
            {t('ourPartners')}
          </Text>
        </div>

        <div className='col-start-2 col-end-none col-start-1-m col-end-none-m'>
          <PartnerLogosWrapper>
            <PartnerLogos />
          </PartnerLogosWrapper>
        </div>
      </SectionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 60px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 100px;

  > div {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StorySectionWrapper = styled(SectionWrapper)`
  margin-top: 150px;
  margin-bottom: 100px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 30px;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  margin-bottom: 100px;

  @media only screen and ${deviceBreakpoints.mobile} {
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const DescriptionWrapperRight = styled(DescriptionWrapper)`
  @media only screen and ${deviceBreakpoints.mobile} {
    grid-row: 1;
    margin-top: 48px;
  }
`;

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`;

const ImageSection = styled.div`
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.div`
  display: grid;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: fit-content;
    margin: 0 auto;
    max-width: 70%;
  }
`;

const PartnerLogosWrapper = styled.div`
  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    justify-content: center;
  }
`;

export default AboutUs;
