import React from 'react';

const WorldIcon: React.FC = () => {
  return (
    <svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M38.9603 32.3335C39.1737 30.5735 39.3337 28.8135 39.3337 27.0002C39.3337 25.1868 39.1737 23.4268 38.9603 21.6668H47.9737C48.4003 23.3735 48.667 25.1602 48.667 27.0002C48.667 28.8402 48.4003 30.6268 47.9737 32.3335M34.2403 47.1602C35.8403 44.2002 37.067 41.0002 37.9203 37.6668H45.787C43.2034 42.1156 39.1046 45.4855 34.2403 47.1602ZM33.5737 32.3335H21.0937C20.827 30.5735 20.667 28.8135 20.667 27.0002C20.667 25.1868 20.827 23.4002 21.0937 21.6668H33.5737C33.8137 23.4002 34.0003 25.1868 34.0003 27.0002C34.0003 28.8135 33.8137 30.5735 33.5737 32.3335ZM27.3337 48.2268C25.1203 45.0268 23.3337 41.4802 22.2403 37.6668H32.427C31.3337 41.4802 29.547 45.0268 27.3337 48.2268ZM16.667 16.3335H8.88032C11.4373 11.8727 15.5331 8.49746 20.4003 6.84016C18.8003 9.80016 17.6003 13.0002 16.667 16.3335ZM8.88032 37.6668H16.667C17.6003 41.0002 18.8003 44.2002 20.4003 47.1602C15.5433 45.4846 11.4532 42.1141 8.88032 37.6668ZM6.69366 32.3335C6.26699 30.6268 6.00033 28.8402 6.00033 27.0002C6.00033 25.1602 6.26699 23.3735 6.69366 21.6668H15.707C15.4937 23.4268 15.3337 25.1868 15.3337 27.0002C15.3337 28.8135 15.4937 30.5735 15.707 32.3335M27.3337 5.74683C29.547 8.94683 31.3337 12.5202 32.427 16.3335H22.2403C23.3337 12.5202 25.1203 8.94683 27.3337 5.74683ZM45.787 16.3335H37.9203C37.0857 13.0307 35.8499 9.84255 34.2403 6.84016C39.147 8.52016 43.227 11.9068 45.787 16.3335ZM27.3337 0.333496C12.587 0.333496 0.666992 12.3335 0.666992 27.0002C0.666992 34.0726 3.47651 40.8554 8.47748 45.8563C10.9537 48.3326 13.8934 50.2968 17.1288 51.637C20.3641 52.9771 23.8317 53.6668 27.3337 53.6668C34.4061 53.6668 41.1889 50.8573 46.1898 45.8563C51.1908 40.8554 54.0003 34.0726 54.0003 27.0002C54.0003 23.4982 53.3106 20.0306 51.9704 16.7953C50.6303 13.5599 48.6661 10.6202 46.1898 8.14398C43.7136 5.66775 40.7739 3.7035 37.5385 2.36338C34.3032 1.02325 30.8356 0.333496 27.3337 0.333496Z'
        fill='url(#paint0_linear_1136_10074)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1136_10074'
          x1='57.2691'
          y1='46.7703'
          x2='-27.5478'
          y2='35.4302'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WorldIcon;
