import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import realFDS from '../../../assets/realFDS.png';
import realGPT from '../../../assets/realGPT.png';
import realSI from '../../../assets/realSI.png';
import realSID from '../../../assets/realSID.png';
import realVTT from '../../../assets/realVTT.png';
import ProductButton from '../../../components/atoms/ProductButton/ProductButton';
import Tag from '../../../components/atoms/Tag/Tag';
import Text from '../../../components/atoms/Text/Text';
import ProductDescription from '../../../components/molecules/ProductDescription/ProductDescription';
import BigChevronIcon from '../../../icons/BigChevron';
import { deviceBreakpoints, deviceSizes } from '../../../Theme';

const ProductsSection: React.FC = () => {
  const { t, i18n } = useTranslation(['main']);

  const navigate = useNavigate();

  const products = [
    {
      key: 'real-stt',
      title: 'Real-STT',
      subtitle: 'Speech-To-Text',
      description: t('realSTTDescription'),
      extendedDescription: t('realSTTExtendedDescription'),
      imageSrc: realVTT,
    },
    {
      key: 'real-sid',
      title: 'Real-SID',
      subtitle: 'Speaker Identification',
      description: t('realSIDDescription'),
      extendedDescription: t('realSIDExtendedDescription'),
      imageSrc: realSID,
    },
    {
      key: 'real-fds',
      title: 'Real-FDS',
      subtitle: 'Fraud Detection System',
      description: t('realFDSDescription'),
      extendedDescription: t('realFDSExtendedDescription'),
      imageSrc: realFDS,
    },
    {
      key: 'real-si',
      title: 'Real-SI',
      subtitle: 'System Integration',
      description: t('realSIDescription'),
      extendedDescription: t('realSIExtendedDescription'),
      imageSrc: realSI,
    },
    {
      key: 'real-gpt',
      title: 'Real-GPT',
      subtitle: 'Generative Pre-trained Transformer',
      description: t('realGPTDescription'),
      extendedDescription: t('realGPTExtendedDescription'),
      imageSrc: realGPT,
    },
  ];

  const [currentProduct, setCurrentProduct] = useState(products[0]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setCurrentProduct(products[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNextClick = () => {
    const currentIndex = products.findIndex((p) => p.key === currentProduct.key);

    if (currentIndex === products.length - 1) {
      setCurrentProduct(products[0]);
    } else {
      setCurrentProduct(products[currentIndex + 1]);
    }
  };

  const handleBackClick = () => {
    const currentIndex = products.findIndex((p) => p.key === currentProduct.key);

    if (currentIndex === 0) {
      setCurrentProduct(products[products.length - 1]);
    } else {
      setCurrentProduct(products[currentIndex - 1]);
    }
  };

  return (
    <ProductsSectionWrapper className='main-container'>
      <ProductContentWrapper className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
        <Tag text={t('ourProducts')} type='dark' />
        <TitleWrapper>
          <Text type={'h2'} color={'accent'}>
            {t('ourProductsTitle')}
          </Text>
        </TitleWrapper>
      </ProductContentWrapper>

      <ProductsWrapper className='col-start-2 col-end-12 col-start-1-m col-end-none-m col-start-1-t col-end-none-t'>
        <ProductMenu>
          {products.map((product, index) => (
            <ProductButton
              key={index}
              type={windowWidth < 1024 ? 'mobile' : 'desktop'}
              text={product.title}
              isActive={currentProduct.title === product.title}
              onClickAction={() => setCurrentProduct(product)}
            />
          ))}
          <ArrowLeft onClick={() => handleBackClick()}>
            <BigChevronIcon />
          </ArrowLeft>
          <ArrowRight onClick={() => handleNextClick()}>
            <BigChevronIcon />
          </ArrowRight>
        </ProductMenu>

        <ProductDescriptionsWrapper currentKey={currentProduct.key}>
          {products.map((product, index) => (
            <ProductDescriptionWrapper
              key={`product_${index}`}
              className={
                currentProduct.key !== product.key ? currentProduct.key : 'current-product'
              }
              $isVisible={currentProduct.key === product.key}
            >
              <ProductDescription
                title={product.title}
                subtitle={product.subtitle}
                description={product.description}
                extendedDescription={product.extendedDescription}
                buttonText={t('moreInfo')}
                buttonOnClickAction={() => {
                  navigate(`/products/${product.key}`);
                }}
                imageSrc={product.imageSrc}
                isVisible={currentProduct.key === product.key}
              />
            </ProductDescriptionWrapper>
          ))}
        </ProductDescriptionsWrapper>
      </ProductsWrapper>
    </ProductsSectionWrapper>
  );
};

const ProductsSectionWrapper = styled.div`
  display: grid;
  position: relative;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const ProductContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    margin-top: 24px;
  }
`;

const ProductMenu = styled.div`
  display: flex;
  flex: 0.6;
  gap: 8px;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }
`;

const ArrowRight = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(226, 229, 244, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(88, 104, 245, 0.1);

  &:hover > svg > path {
    fill: #424565;
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;
const ArrowLeft = styled(ArrowRight)`
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-right: 12px;
  margin-left: 24px;
`;

const ProductDescriptionWrapper = styled.div<{ $isVisible: boolean }>`
  flex: 2;
  position: absolute;
  background: rgba(235, 243, 255);
  border-radius: 16px 40px 80px 80px;
  padding: 56px 64px 64px;
  width: 100%;
  height: 560px;
  box-shadow: 0 2px 6px -6px #222;

  @media only screen and (max-width: ${deviceSizes.gridBreakPoint}) {
    padding: 24px 24px 32px;
    position: relative;
    height: fit-content;

    ${(props) => !props.$isVisible && 'display: none'};
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    border-radius: 8px 8px 20px 20px;
  }

  &.current-product {
    position: relative;
  }

  /*  &.current-product {
    animation-duration: 2s;
    animation-name: slideIn;
  } */

  @keyframes slideIn {
    0% {
      background: rgba(235, 243, 255);
      top: 20px;
      right: -20px;
    }
    20% {
      top: 10px;
      right: -10px;
    }
    100% {
      top: 0px;
      right: 0px;
    }
  }

  /* &:nth-child(1).real-sid,
  &:nth-child(2).real-fds,
  &:nth-child(3).real-si,
  &:nth-child(4).real-gpt {
    animation-duration: 3s;
    animation-name: slideOut;
  }
 */
  @keyframes slideOut {
    0% {
      background: rgba(235, 243, 255);
      top: 0px;
      right: 0px;
      z-index: 2;
    }

    30% {
      top: -30px;
      right: 0px;
      background: rgba(235, 243, 255);
    }

    100% {
      top: 80px;
      right: -80px;
      background: linear-gradient(
        274.41deg,
        rgba(93, 107, 233, 0) -4.71%,
        rgba(93, 107, 233, 0.18) 10.97%,
        rgba(74, 179, 255, 0.18) 76.3%,
        rgba(50, 224, 214, 0.18) 126.8%,
        rgba(50, 99, 224, 0.18) 126.8%
      );
      z-index: -4;
    }
  }
`;

const ProductDescriptionsWrapper = styled.div<{ currentKey: string }>`
  display: flex;
  position: relative;

  @media only screen and (min-width: ${deviceSizes.gridBreakPoint}) {
    ${ProductDescriptionWrapper}:nth-child(1) {
      ${(props) =>
        props.currentKey === 'real-sid' &&
        ` right: -80px;
        top: 80px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -4; `}
      ${(props) =>
        props.currentKey === 'real-fds' &&
        ` right: -60px;
        top: 60px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -3;
      `}
    ${(props) =>
        props.currentKey === 'real-si' &&
        ` right: -40px;
        top: 40px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -2;
      `}

      ${(props) =>
        props.currentKey === 'real-gpt' &&
        ` right: -20px;
        top: 20px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -1;
      `}
    }

    ${ProductDescriptionWrapper}:nth-child(2) {
      ${(props) =>
        props.currentKey === 'real-stt' &&
        ` right: -20px;
        top: 20px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -1;
    `}
      ${(props) =>
        props.currentKey === 'real-fds' &&
        ` right: -80px;
        top: 80px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -4;
    `}
      ${(props) =>
        props.currentKey === 'real-si' &&
        ` right: -60px;
        top: 60px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -3;
    `}

      ${(props) =>
        props.currentKey === 'real-gpt' &&
        ` right: -40px;
        top: 40px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -2;
    `}
    }

    ${ProductDescriptionWrapper}:nth-child(3) {
      ${(props) =>
        props.currentKey === 'real-stt' &&
        ` right: -40px;
        top: 40px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -2;
    `}
      ${(props) =>
        props.currentKey === 'real-sid' &&
        ` right: -20px;
        top: 20px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -1;
    `}
      ${(props) =>
        props.currentKey === 'real-si' &&
        ` right: -80px;
        top: 80px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -4;
    `}

      ${(props) =>
        props.currentKey === 'real-gpt' &&
        ` right: -60px;
        top: 60px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -3;
    `}
    }

    ${ProductDescriptionWrapper}:nth-child(4) {
      ${(props) =>
        props.currentKey === 'real-stt' &&
        ` right: -60px;
        top: 60px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -3;
    `}
      ${(props) =>
        props.currentKey === 'real-sid' &&
        ` right: -40px;
        top: 40px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -2;
    `}
      ${(props) =>
        props.currentKey === 'real-fds' &&
        ` right: -20px;
        top: 20px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -1;
    `}

      ${(props) =>
        props.currentKey === 'real-gpt' &&
        ` right: -80px;
        top: 80px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -4;
    `}
    }

    ${ProductDescriptionWrapper}:nth-child(5) {
      ${(props) =>
        props.currentKey === 'real-stt' &&
        ` right: -80px;
        top: 80px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -4;
    `}
      ${(props) =>
        props.currentKey === 'real-sid' &&
        ` right: -60px;
        top: 60px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -3;
    `}
      ${(props) =>
        props.currentKey === 'real-fds' &&
        ` right: -40px;
        top: 40px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -2;
    `}

      ${(props) =>
        props.currentKey === 'real-si' &&
        ` right: -20px;
        top: 20px; 
        background: linear-gradient(
          274.41deg,
          rgba(93, 107, 233, 0) -4.71%,
          rgba(93, 107, 233, 0.18) 10.97%,
          rgba(74, 179, 255, 0.18) 76.3%,
          rgba(50, 224, 214, 0.18) 126.8%,
          rgba(50, 99, 224, 0.18) 126.8%
        );
        z-index: -1;
    `}
    }
  }
`;

const TitleWrapper = styled.div`
  margin-top: 16px;
`;

export default ProductsSection;
