import React from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

import { gaMeasurementId } from '../../config';
import { deviceBreakpoints } from '../../Theme';
import AreasSection from './sections/Areas';
import PartnersSection from './sections/Partners';
import ProductsSection from './sections/Products';
import TopSection from './sections/Top';

ReactGA.initialize(gaMeasurementId);

const MainPage: React.FC = () => {
  ReactGA.send({ hitType: 'pageview', page: '/', title: 'Homepage' });

  return (
    <Wrapper>
      <SectionWrapper>
        <TopSection />
      </SectionWrapper>
      <SectionWrapper>
        <ProductsSection />
      </SectionWrapper>
      <SectionWrapper>
        <AreasSection />
      </SectionWrapper>
      <SectionWrapper>
        <PartnersSection />
      </SectionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  margin-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 60px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 64px;
`;

export default MainPage;
