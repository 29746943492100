import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Text from '../../../components/atoms/Text/Text';
import { deviceBreakpoints } from '../../../Theme';

interface FeatureType {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface FeaturesProps {
  features: FeatureType[];
  title: string;
  subTitle?: string | null;
}

const Features: React.FC<FeaturesProps> = ({ features, title }) => {
  const { i18n } = useTranslation();

  return (
    <FeaturesWrapper className='main-container'>
      <ContentWrapper className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
        <FeaturesTop>
          <FeaturesTitleDescription>
            <TitleWrapper minWidthIsSet={i18n.language === 'ko'}>
              <Text type='h2' color='accent'>
                {title}
              </Text>
            </TitleWrapper>
          </FeaturesTitleDescription>
        </FeaturesTop>

        <FeaturesListWrapper>
          {features.map((feature, key) => (
            <Feature key={`feature_${key}`}>
              <IconWrapper>{feature.icon}</IconWrapper>
              <FeatureValueTitleWrapper>
                <Text type='h4'>{feature.title}</Text>
              </FeatureValueTitleWrapper>
              <Text type='text' color='textLight'>
                {feature.description}
              </Text>
            </Feature>
          ))}
        </FeaturesListWrapper>
      </ContentWrapper>
    </FeaturesWrapper>
  );
};

const FeaturesWrapper = styled.div`
  display: grid;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeaturesTop = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 0px;
  }
`;

const FeaturesTitleDescription = styled.div`
  display: flex;
  justify-content: between;
  gap: 100px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`;

const TitleWrapper = styled.div<{ minWidthIsSet: boolean }>`
  white-space: nowrap;
  margin-bottom: 16px;
  min-width: ${(props) => (props.minWidthIsSet ? '340px' : 'unset')};
`;

const FeaturesListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 48px;
  margin-top: 44px;

  @media only screen and ${deviceBreakpoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
`;

const Feature = styled.div`
  margin-bottom: 72px;
  display: flex;
  flex-direction: column;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 36px;
  }
`;

const FeatureValueTitleWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 12px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 18px;
    margin-bottom: 8px;
  }
`;

const IconWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

export default Features;
