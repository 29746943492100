import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import Separator from '../../../components/atoms/Separator/Separator';
import Text from '../../../components/atoms/Text/Text';
import { deviceBreakpoints } from '../../../Theme';

interface BulletPoint {
  title: string;
  description: string;
}

interface DescriptionProps {
  mainTitle?: string | null;
  mainSubtitle?: string | null;
  mainDescription?: string | null;
  secondaryTitle?: string | null;
  secondarySubtitle?: string | null;
  secondaryDescription?: string | null;
  image?: string;
  bulletPoints?: BulletPoint[];
  descriptionList?: React.ReactElement;
}

const Description: React.FC<DescriptionProps> = ({
  mainTitle,
  mainSubtitle,
  mainDescription,
  secondaryTitle,
  secondarySubtitle,
  secondaryDescription,
  image,
  bulletPoints,
  descriptionList,
}) => {
  return (
    <DescriptionWrapper className='main-container'>
      {mainTitle && mainDescription && (
        <DescriptionTop className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
          <Text type='xlText' color='lightBlue'>
            {mainSubtitle}
          </Text>
          <DescriptionTopTextWrapper>
            <TopTitleWrapper>
              <Text type='h2' color='accent'>
                <Trans i18nKey='mainTitle'>{mainTitle}</Trans>
              </Text>
            </TopTitleWrapper>

            <Text type='largeText' color='text'>
              <Trans> {mainDescription}</Trans>
            </Text>
          </DescriptionTopTextWrapper>
        </DescriptionTop>
      )}

      {secondaryTitle && secondaryDescription && (
        <>
          <DescriptionBottom className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
            <DescriptionBottomTextWrapper>
              <Text type='xlText' color='lightBlue'>
                {secondarySubtitle}
              </Text>
              <TitleWrapper>
                <Text type='h2' color='accent'>
                  {secondaryTitle}
                </Text>
              </TitleWrapper>
              <Text type='largeText' color='text'>
                <Trans>{secondaryDescription}</Trans>
              </Text>

              {descriptionList}
            </DescriptionBottomTextWrapper>
            <DescriptionBottomImgWrapper>
              <StyledImg src={image} />
            </DescriptionBottomImgWrapper>
          </DescriptionBottom>
        </>
      )}

      {bulletPoints && (
        <BulletPointsWrapper className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
          {bulletPoints.map((bulletPoint, key) => (
            <BulletPoint key={`bulletPoint_${key}`}>
              <Text type='h4' color='text'>
                {bulletPoint.title}
              </Text>
              <Separator />
              <Text type='text' color='text'>
                {bulletPoint.description}
              </Text>
            </BulletPoint>
          ))}
        </BulletPointsWrapper>
      )}
    </DescriptionWrapper>
  );
};

const DescriptionWrapper = styled.div`
  position: relative;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const DescriptionTop = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @media only screen and ${deviceBreakpoints.mobile} {
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
`;

const TopTitleWrapper = styled(TitleWrapper)`
  margin-top: 0px;
  min-width: 30%;
`;

const DescriptionBottom = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    margin-top: 0;
    gap: 40px;
  }
`;

const DescriptionBottomTextWrapper = styled.div`
  width: 50%;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
  }
`;

const DescriptionBottomImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
  min-width: 460px;

  @media only screen and ${deviceBreakpoints.mobile} {
    min-width: unset;
  }
`;

const BulletPointsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 48px;
  margin-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    margin-top: 48px;
  }
`;

const BulletPoint = styled.div`
  border-radius: 40px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: 0px 20px 40px rgba(85, 184, 255, 0.1);
  border: 1px solid ${(props) => props.theme.colors.lightBckg};
`;

const DescriptionTopTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 200px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    gap: 0px;
  }
`;

export default Description;
