import React from 'react';

const CheckmarkIcon: React.FC = () => {
  return (
    <svg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.50002 9.25008C4.30119 9.24922 4.11056 9.17072 3.96877 9.03133L0.468769 5.53133C0.327873 5.39043 0.248718 5.19934 0.248718 5.00008C0.248718 4.80082 0.327873 4.60973 0.468769 4.46883C0.609666 4.32793 0.800762 4.24878 1.00002 4.24878C1.19928 4.24878 1.39037 4.32793 1.53127 4.46883L4.50002 7.43758L10.9688 0.96883C11.0385 0.899066 11.1214 0.843725 11.2125 0.805969C11.3037 0.768212 11.4014 0.748779 11.5 0.748779C11.5987 0.748779 11.6964 0.768212 11.7875 0.805969C11.8787 0.843725 11.9615 0.899066 12.0313 0.96883C12.101 1.0386 12.1564 1.12142 12.1941 1.21257C12.2319 1.30372 12.2513 1.40142 12.2513 1.50008C12.2513 1.59874 12.2319 1.69644 12.1941 1.78759C12.1564 1.87874 12.101 1.96157 12.0313 2.03133L5.03127 9.03133C4.88948 9.17072 4.69885 9.24922 4.50002 9.25008Z'
        fill='#5868F5'
      />
    </svg>
  );
};

export default CheckmarkIcon;
