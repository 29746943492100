import React from 'react';

const CodeBracesIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.3333 8C19.9188 8 18.5623 8.5619 17.5621 9.5621C16.5619 10.5623 16 11.9188 16 13.3333V24C16 25.4145 15.4381 26.771 14.4379 27.7712C13.4377 28.7714 12.0812 29.3333 10.6667 29.3333H8V34.6667H10.6667C12.0812 34.6667 13.4377 35.2286 14.4379 36.2288C15.4381 37.229 16 38.5855 16 40V50.6667C16 52.0812 16.5619 53.4377 17.5621 54.4379C18.5623 55.4381 19.9188 56 21.3333 56H26.6667V50.6667H21.3333V37.3333C21.3333 35.9188 20.7714 34.5623 19.7712 33.5621C18.771 32.5619 17.4145 32 16 32C17.4145 32 18.771 31.4381 19.7712 30.4379C20.7714 29.4377 21.3333 28.0812 21.3333 26.6667V13.3333H26.6667V8M42.6667 8C44.0812 8 45.4377 8.5619 46.4379 9.5621C47.4381 10.5623 48 11.9188 48 13.3333V24C48 25.4145 48.5619 26.771 49.5621 27.7712C50.5623 28.7714 51.9188 29.3333 53.3333 29.3333H56V34.6667H53.3333C51.9188 34.6667 50.5623 35.2286 49.5621 36.2288C48.5619 37.229 48 38.5855 48 40V50.6667C48 52.0812 47.4381 53.4377 46.4379 54.4379C45.4377 55.4381 44.0812 56 42.6667 56H37.3333V50.6667H42.6667V37.3333C42.6667 35.9188 43.2286 34.5623 44.2288 33.5621C45.229 32.5619 46.5855 32 48 32C46.5855 32 45.229 31.4381 44.2288 30.4379C43.2286 29.4377 42.6667 28.0812 42.6667 26.6667V13.3333H37.3333V8H42.6667Z'
        fill='url(#paint0_linear_2071_46232)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2071_46232'
          x1='58.9419'
          y1='49.7931'
          x2='-17.3933'
          y2='39.587'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CodeBracesIcon;
