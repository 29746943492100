import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import extendedDescriptionImage from '../../assets/productImages/realStt/extendedDescription.png';
import topLeftImage from '../../assets/productImages/realStt/topLeft.png';
import topMiddleImage from '../../assets/productImages/realStt/topMiddle.png';
import topRightImage from '../../assets/productImages/realStt/topRight.png';
import SpeechToTextBox from '../../components/molecules/SpeechToTextBox/SpeechToText';
import TopSectionProduct from '../../components/organisms/TopSectionProduct/TopSectionProduct';
import { gaMeasurementId } from '../../config';
import BoxLines from '../../icons/BoxLines';
import BrainIcon from '../../icons/Brain';
import CallPerson from '../../icons/CallPerson';
import ClockIcon from '../../icons/Clock';
import CloudIcon from '../../icons/Cloud';
import MicrophoneCircleIcon from '../../icons/MicrophoneCircle';
import PersonIcon from '../../icons/Person';
import PersonWithMonitorIcon from '../../icons/PersonWithMonitor';
import SignalIcon from '../../icons/Signal';
import TranslateIcon from '../../icons/Translate';
import { deviceBreakpoints } from '../../Theme';
import Description from './sections/Description';
import ExtendedDescription from './sections/ExtendedDescription';
import Features from './sections/Features';
import SlideShow from './sections/SlideShow';

ReactGA.initialize(gaMeasurementId);

const RealStt: React.FC = () => {
  const { t } = useTranslation(['products']);
  ReactGA.send({ hitType: 'pageview', page: '/products/real-sid', title: 'Real-STT' });
  const illustrations = [
    {
      title: t('realStt.topIllustrationLeftTitle'),
      description: t('realStt.topIllustrationLeftDescription'),
      image: topLeftImage,
    },
    {
      title: t('realStt.topIllustrationMiddleTitle'),
      description: t('realStt.topIllustrationMiddleDescription'),
      image: topMiddleImage,
    },
    {
      title: t('realStt.topIllustrationRightTitle'),
      description: t('realStt.topIllustrationRightDescription'),
      image: topRightImage,
    },
  ];

  const features = [
    {
      title: t('realStt.featureOneTitle'),
      description: t('realStt.featureOneDescription'),
      icon: <BrainIcon />,
    },
    {
      title: t('realStt.featureTwoTitle'),
      description: t('realStt.featureTwoDescription'),
      icon: <PersonIcon />,
    },
    {
      title: t('realStt.featureThreeTitle'),
      description: t('realStt.featureThreeDescription'),
      icon: <ClockIcon />,
    },
    {
      title: t('realStt.featureFourTitle'),
      description: t('realStt.featureFourDescription'),
      icon: <CloudIcon />,
    },
    {
      title: t('realStt.featureFiveTitle'),
      description: t('realStt.featureFiveDescription'),
      icon: <BoxLines />,
    },
    {
      title: t('realStt.featureSixTitle'),
      description: t('realStt.featureSixDescription'),
      icon: <MicrophoneCircleIcon />,
    },
    {
      title: t('realStt.featureSevenTitle'),
      description: t('realStt.featureSevenDescription'),
      icon: <TranslateIcon />,
    },
  ];

  const slideItems = [
    {
      icon: <PersonWithMonitorIcon />,
      title: t('realStt.slideShowItemOneTitle'),
      description: t('realStt.slideShowItemOneDescription'),
    },
    {
      icon: <CallPerson />,
      title: t('realStt.slideShowItemTwoTitle'),
      description: t('realStt.slideShowItemTwoDescription'),
    },
    {
      icon: <SignalIcon />,
      title: t('realStt.slideShowItemThreeTitle'),
      description: t('realStt.slideShowItemThreeDescription'),
    },
  ];

  return (
    <Wrapper>
      <SectionWrapper>
        <TopSectionProduct
          title={t('realStt.topTitle')}
          description={t('realStt.topDescription')}
          illustrations={illustrations}
        />
      </SectionWrapper>

      <SectionWrapper>
        <Description
          mainTitle={t('realStt.mainDescriptionTitleTop')}
          mainSubtitle={t('realStt.mainDescriptionSubtitleTop')}
          mainDescription={t('realStt.mainDescriptionTop')}
        />
      </SectionWrapper>

      <SectionWrapper>
        <SpeechToTextBoxWrapper className='main-container'>
          <div className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
            <SpeechToTextBox />
          </div>
        </SpeechToTextBoxWrapper>
      </SectionWrapper>

      <SectionWrapper>
        <ExtendedDescription
          title={t('realStt.extendedDescriptionTitle')}
          subTitle={t('realStt.extendedDescriptionSubtitle')}
          description={t('realStt.extendedDescription')}
          image={extendedDescriptionImage}
          buttonText={'Book a Session'}
        />
      </SectionWrapper>

      <SectionWrapper>
        <Features
          features={features}
          title={t('realStt.featuresTitle')}
          subTitle={t('realStt.featuresSubtitle')}
        />
      </SectionWrapper>

      <SectionWrapper>
        <SlideShow slideItems={slideItems} title={t('useCases')} />
      </SectionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  margin-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 60px;
  }
`;

const SpeechToTextBoxWrapper = styled.div`
  width: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    > div {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 64px;

  &:first-child {
    margin-bottom: 110px;
  }
`;

export default RealStt;
