import React from 'react';

const CloudIcon: React.FC = () => {
  return (
    <svg width='64' height='44' viewBox='0 0 64 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M51.6 16.5652C50.7043 12.0257 48.2603 7.93812 44.6855 5.00049C41.1107 2.06285 36.627 0.45745 32 0.458497C24.2933 0.458497 17.6 4.83183 14.2667 11.2318C10.3473 11.6554 6.72268 13.5124 4.0893 16.446C1.45592 19.3797 -0.000457871 23.183 1.07981e-07 27.1252C1.07981e-07 35.9518 7.17333 43.1252 16 43.1252H50.6667C58.0267 43.1252 64 37.1518 64 29.7918C64 22.7518 58.5333 17.0452 51.6 16.5652ZM50.6667 37.7918H16C10.1067 37.7918 5.33333 33.0185 5.33333 27.1252C5.33333 21.6585 9.41333 17.0985 14.8267 16.5385L17.68 16.2452L19.0133 13.7118C20.2397 11.3246 22.101 9.322 24.3923 7.92461C26.6837 6.52723 29.3162 5.78921 32 5.79183C38.9867 5.79183 45.0133 10.7518 46.3733 17.6052L47.1733 21.6052L51.2533 21.8985C53.258 22.0333 55.1371 22.9225 56.5126 24.387C57.8881 25.8515 58.6577 27.7827 58.6667 29.7918C58.6667 34.1918 55.0667 37.7918 50.6667 37.7918ZM26.6667 27.6052L21.0933 22.0318L17.3333 25.7918L26.6667 35.1252L42.6933 19.0985L38.9333 15.3385L26.6667 27.6052Z'
        fill='url(#paint0_linear_1234_5753)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1234_5753'
          x1='67.9226'
          y1='37.6079'
          x2='-31.6714'
          y2='17.6343'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CloudIcon;
