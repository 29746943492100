import React, { useState } from 'react';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';
import Text from '../Text/Text';

interface ProductButtonStyleProps {
  isActive: boolean;
}

interface ProductButtonProps {
  text: string;
  onClickAction: () => void;
  type: 'desktop' | 'mobile';
}

const ProductButton: React.FC<ProductButtonProps & ProductButtonStyleProps> = ({
  isActive,
  text,
  type,
  onClickAction,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  return type === 'desktop' ? (
    <StyledDesktopProductButton
      isActive={isActive}
      onClick={() => onClickAction()}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
    >
      <ProductButtonContentWrapper>
        <Text color={isActive || isHover ? 'white' : 'accent'} type={'h4'}>
          {text}
        </Text>
      </ProductButtonContentWrapper>
      <HoverBackground />
    </StyledDesktopProductButton>
  ) : (
    <StyledMobileProductButton isActive={isActive} onClick={() => onClickAction()}>
      <Text color={isActive ? 'white' : 'accent'} type='h3'>
        {text}
      </Text>
    </StyledMobileProductButton>
  );
};

const HoverBackground = styled.div`
  background: ${(props) => props.theme.colors.purple};
  border-radius: 60px;
  width: 200%;
  height: 120px;
  position: absolute;
  transform: translateX(500px);
`;

const StyledDesktopProductButton = styled.button<ProductButtonStyleProps>`
  padding: 24px 24px 24px 32px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px 32px 16px 16px;
  border: none;
  background: ${(props) =>
    props.isActive ? props.theme.colors.purple : props.theme.colors.lightBckg};

  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-size: cover;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);

  &:hover ${HoverBackground} {
    transform: translateX(-20%);
    transition: 0.5s;

    @media only screen and ${deviceBreakpoints.desktop} {
      transform: translateX(-20%);
      transition: 0.5s;
    }

    @media only screen and ${deviceBreakpoints.tablet} {
      transform: translateX(-20%);
      transition: 0.5s;
    }
  }
`;

const StyledMobileProductButton = styled.button<ProductButtonStyleProps>`
  padding: 12px 16px;
  background: ${(props) =>
    props.isActive ? props.theme.colors.purple : props.theme.colors.lightBckg};
  border-radius: 12px;
  border: none;
  white-space: nowrap;
`;

const ProductButtonContentWrapper = styled.div`
  z-index: 2;

  & div {
    white-space: nowrap;
  }
`;

export default ProductButton;
