import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import bigGradientBackground from '../../../assets/bigGradientBackground.png';
import partner1 from '../../../assets/partner1.png';
import partner2 from '../../../assets/partner2.png';
import partner3 from '../../../assets/partner3.png';
import Text from '../../../components/atoms/Text/Text';
import PartnerLogos from '../../../components/molecules/PartnerLogos/PartnerLogos';
import ArrowIcon from '../../../icons/Arrow';
import QuoteIcon from '../../../icons/Quote';
import TriangleIcon from '../../../icons/Triangle';
import { deviceBreakpoints } from '../../../Theme';

const PartnersSection: React.FC = () => {
  const { t } = useTranslation(['main']);
  const [currentPartnerIndex, setCurrentPartnerIndex] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentTimeoutId, setCurrentTimeoutId] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const partners = [
    {
      name: 'Kim Jaebum',
      role: 'CEO, Darwin Intelligence',
      quote: t('partnerOneQuote'),
      image: partner1,
    },
    {
      name: 'Chris Dyer',
      role: 'VP of Sales, Deepgram',
      quote: t('partnerTwoQuote'),
      image: partner2,
    },
    {
      name: 'Paul Morris',
      role: 'Global Sales Manager, Phonexia',
      quote: t('partnerThreeQuote'),
      image: partner3,
    },
  ];

  useEffect(() => {
    if (currentTimeoutId) {
      clearTimeout(currentTimeoutId);
    }
    const id = setTimeout(() => {
      handleNextClick();
    }, 5000);

    setCurrentTimeoutId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartnerIndex]);

  const handleBackClick = () => {
    if (currentPartnerIndex === 0) {
      setCurrentPartnerIndex(partners.length - 1);
    } else {
      setCurrentPartnerIndex(currentPartnerIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPartnerIndex === partners.length - 1) {
      setCurrentPartnerIndex(0);
    } else {
      setCurrentPartnerIndex(currentPartnerIndex + 1);
    }
  };

  return (
    <PartnersSectionWrapper className='main-container'>
      {partners.map((partner, index) => (
        <ContentWrapper
          className='col-start-1 col-end-none'
          key={index}
          $isVisible={currentPartnerIndex === index}
        >
          <PartnerSectionLeft className='col-start-2 col-end-8'>
            <PartnerSectionLeftTitleWrapper>
              {windowWidth < 1024 && (
                <QuoteIconWrapper>
                  <QuoteIcon />
                </QuoteIconWrapper>
              )}
              <PartnerSectionLeftTitle>
                <Text type='h2' color='white'>
                  {t('partnersTitle')}:
                </Text>
              </PartnerSectionLeftTitle>
            </PartnerSectionLeftTitleWrapper>
            {windowWidth > 1024 && (
              <QuoteIconWrapper>
                <QuoteIcon />
              </QuoteIconWrapper>
            )}

            <QuoteWrapper>
              <Quote>
                <Text type='text'>{partner.quote}</Text>
                <IconWrapper>
                  <TriangleIcon />
                </IconWrapper>
              </Quote>
            </QuoteWrapper>

            <PersonWrapper>
              <PartnerMobileImage src={partner.image} width='80' />

              <div>
                <Text type='largeText' color='white'>
                  {partner.name}
                </Text>
                <Text type='text' color='white'>
                  {partner.role}
                </Text>
              </div>
              <Break />
              <NavigationWrapper>
                <ArrowLeft onClick={handleBackClick}>
                  <ArrowIcon />
                </ArrowLeft>
                <ArrowRight onClick={handleNextClick}>
                  <ArrowIcon />
                </ArrowRight>
              </NavigationWrapper>
            </PersonWrapper>
          </PartnerSectionLeft>
          <PartnerSectionRight className='col-end-10'>
            <PartnerImage src={partner.image} />
          </PartnerSectionRight>
        </ContentWrapper>
      ))}

      <PartnerLogosWrapper className='col-start-2 col-end-none col-start-1-m'>
        <div className='col-start-1 col-end-none'>
          <PartnerLogos />
        </div>
      </PartnerLogosWrapper>
    </PartnersSectionWrapper>
  );
};

const PartnersSectionWrapper = styled.div`
  display: grid;
`;

const ContentWrapper = styled.div<{ $isVisible: boolean }>`
  grid-template-columns: repeat(10, [col-start] 1fr);
  display: grid;

  background-image: url(${bigGradientBackground});
  background-size: cover;
  border-radius: 80px;
  padding-top: 85px;
  padding-bottom: 85px;
  ${({ $isVisible }) => ($isVisible ? 'display: grid;' : 'display: none;')}

  @media only screen and ${deviceBreakpoints.mobile} {
    ${({ $isVisible }) => ($isVisible ? 'display: flex;' : 'display: none;')}
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
    border-radius: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const Quote = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(100px);
  border-radius: 37px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  height: 250px;
  display: flex;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 20px;
    height: 260px;
  }
`;

const QuoteIconWrapper = styled.div`
  position: absolute;
  left: -40px;
  top: 70px;

  @media only screen and ${deviceBreakpoints.mobile} {
    position: relative;
    display: inline;
    top: unset;
    left: unset;
  }
`;

const QuoteWrapper = styled.div``;

const PartnerSectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 20px;
  }
`;

const PartnerSectionLeftTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  @media only screen and ${deviceBreakpoints.mobile} {
    padding-left: 10px;
  }
`;
const PartnerSectionLeftTitle = styled.div`
  @media only screen and ${deviceBreakpoints.mobile} {
    width: 80%;
    margin-left: 16px;
  }
`;

const PartnerSectionRight = styled.div`
  justify-self: end;
  height: 100%;
  display: flex;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  right: -40px;
  bottom: 32px;

  @media only screen and ${deviceBreakpoints.mobile} {
    transform: rotate(90deg);
    bottom: -34px;
    left: 40px;
    right: unset;
  }
`;
const PartnerImage = styled.img`
  height: auto;
`;

const PartnerMobileImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-right: 20px;
  display: none;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: block;
  }
`;

const PersonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    position: relative;
    justify-content: normal;
    flex-wrap: wrap;
  }
`;

const ArrowRight = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: linear-gradient(
      194.49deg,
      rgba(93, 107, 233, 0.2) -15.04%,
      rgba(74, 192, 255, 0.2) 38.48%,
      rgba(50, 224, 214, 0.2) 98%
    );
  }
`;
const ArrowLeft = styled(ArrowRight)`
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-right: 12px;
`;

const NavigationWrapper = styled.div`
  display: flex;

  @media only screen and ${deviceBreakpoints.mobile} {
    justify-content: end;
    width: 100%;
  }
`;

const Break = styled.div`
  display: none;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: block;
    flex-basis: 100%;
    height: 0;
  }
`;

const PartnerLogosWrapper = styled.div`
  grid-template-columns: repeat(10, [col-start] 1fr);
  display: grid;

  @media only screen and ${deviceBreakpoints.mobile} {
    grid-template-columns: repeat(3, [col-start] 1fr);
    justify-self: center;
  }
`;

export default PartnersSection;
