import React from 'react';

const GovernmentIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M56.0001 18.6666C56.6798 18.6674 57.3336 18.9276 57.8278 19.3942C58.322 19.8608 58.6194 20.4985 58.6593 21.177C58.6991 21.8555 58.4783 22.5236 58.0421 23.0448C57.6059 23.5661 56.9871 23.901 56.3121 23.9813L56.0001 24V50.6666C56.6798 50.6674 57.3336 50.9276 57.8278 51.3942C58.322 51.8608 58.6194 52.4985 58.6593 53.177C58.6991 53.8555 58.4783 54.5236 58.0421 55.0448C57.6059 55.5661 56.9871 55.901 56.3121 55.9813L56.0001 56H8.00014C7.32046 55.9992 6.66672 55.7389 6.17249 55.2724C5.67826 54.8058 5.38084 54.1681 5.34101 53.4896C5.30118 52.8111 5.52194 52.1429 5.95818 51.6217C6.39442 51.1005 7.01322 50.7656 7.68814 50.6853L8.00014 50.6666V24C7.32046 23.9992 6.66672 23.7389 6.17249 23.2724C5.67826 22.8058 5.38084 22.1681 5.34101 21.4896C5.30118 20.8111 5.52194 20.1429 5.95818 19.6217C6.39442 19.1005 7.01322 18.7656 7.68814 18.6853L8.00014 18.6666H56.0001ZM50.6668 24H13.3335V50.6666H18.6668V32C18.6668 31.2927 18.9478 30.6144 19.4479 30.1143C19.948 29.6142 20.6262 29.3333 21.3335 29.3333C22.0407 29.3333 22.719 29.6142 23.2191 30.1143C23.7192 30.6144 24.0001 31.2927 24.0001 32V50.6666H29.3335V32C29.3335 31.2927 29.6144 30.6144 30.1145 30.1143C30.6146 29.6142 31.2929 29.3333 32.0001 29.3333C32.7074 29.3333 33.3857 29.6142 33.8858 30.1143C34.3859 30.6144 34.6668 31.2927 34.6668 32V50.6666H40.0001V32C40.0001 31.2927 40.2811 30.6144 40.7812 30.1143C41.2813 29.6142 41.9596 29.3333 42.6668 29.3333C43.3741 29.3333 44.0523 29.6142 44.5524 30.1143C45.0525 30.6144 45.3335 31.2927 45.3335 32V50.6666H50.6668V24ZM48.0001 10.6666C48.7074 10.6666 49.3857 10.9476 49.8858 11.4477C50.3859 11.9478 50.6668 12.626 50.6668 13.3333C50.6668 14.0405 50.3859 14.7188 49.8858 15.2189C49.3857 15.719 48.7074 16 48.0001 16H16.0001C15.2929 16 14.6146 15.719 14.1145 15.2189C13.6144 14.7188 13.3335 14.0405 13.3335 13.3333C13.3335 12.626 13.6144 11.9478 14.1145 11.4477C14.6146 10.9476 15.2929 10.6666 16.0001 10.6666H48.0001Z'
        fill='url(#paint0_linear_1814_36400)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1814_36400'
          x1='61.9323'
          y1='50.1379'
          x2='-22.3075'
          y2='36.8889'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GovernmentIcon;
