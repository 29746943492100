import React from 'react';
import { ThemeProvider } from 'styled-components';

export const deviceSizes = {
  largeDesktop: '1899px',
  desktop: '1599px',
  gridBreakPoint: '1400px',
  tablet: '1024px',
  mobile: '360px',
};

export const deviceBreakpoints = {
  largeDesktop: `(min-width: ${deviceSizes.largeDesktop})`,
  desktop: `(min-width: ${deviceSizes.desktop}) and (max-width: ${deviceSizes.largeDesktop})`,
  gridBreakPoint: `(min-width: ${deviceSizes.gridBreakPoint}) and (max-width: ${deviceSizes.desktop})`,
  tablet: `(min-width: ${deviceSizes.tablet}) and (max-width: ${deviceSizes.gridBreakPoint})`,
  mobile: `(max-width: ${deviceSizes.tablet})`,
};

const theme = {
  colors: {
    text: '#424565',
    accent: '#0B0D1E',
    accentHover: '#0F1A38',
    lightBckg: 'rgba(226, 229, 244, 0.5)',
    textLight: '#888CAB',
    border: '#888CAB',
    lightBorder: '#CFD7E2',
    linear: 'linear-gradient(277.62deg, #5D6BE9 -3.88%, #55B8FF 67.51%, #32E0D6 138.9%)',
    purple: '#5868F5',
    lightBlue: '#6DC4FF',
    cian: '#4FEEE4',
    white: '#FFFFFF',
    transparent: 'transparent',
    red: '#FF7799',
  },
  fonts: {
    desktop: {
      en: {
        logo: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 800,
        },
        link: {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 600,
        },
        h1: {
          fontSize: '36px',
          lineHeight: '60px',
          fonttWeight: 700,
        },
        h2: {
          fontSize: '48px',
          lineHeight: '60px',
          fontWeight: 700,
        },
        h3: {
          fontSize: '24px',
          lineHeight: '28px',
          fontWeight: 700,
        },
        h4: {
          fontSize: '20px',
          lineHeight: '28px',
          fontWeight: 700,
        },
        superSmallText: {
          fontSize: '12px',
          lineHeight: '18px',
          fontWeight: 400,
        },
        smallText: {
          fontSize: '14px',
          lineHeight: '22px',
          fontWeight: 400,
        },
        text: {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 400,
        },
        largeText: {
          fontSize: '20px',
          lineHeight: '36px',
          fontWeight: 400,
        },
        xlText: {
          fontSize: '24px',
          lineHeight: '36px',
          fontWeight: 600,
        },
        xxlText: {
          fontSize: '72px',
          lineHeight: '84px',
          fontWeight: 600,
        },
      },
      ko: {
        logo: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 800,
        },
        link: {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 600,
        },
        h1: {
          fontSize: '36px',
          lineHeight: '60px',
          fonttWeight: 700,
        },
        h2: {
          fontSize: '36px',
          lineHeight: '48px',
          fontWeight: 700,
        },
        h3: {
          fontSize: '24px',
          lineHeight: '28px',
          fontWeight: 700,
        },
        h4: {
          fontSize: '20px',
          lineHeight: '28px',
          fontWeight: 700,
        },
        superSmallText: {
          fontSize: '12px',
          lineHeight: '18px',
          fontWeight: 400,
        },
        smallText: {
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: 400,
        },
        text: {
          fontSize: '16px',
          lineHeight: '28px',
          fontWeight: 400,
        },
        largeText: {
          fontSize: '18px',
          lineHeight: '32px',
          fontWeight: 400,
        },
        xlText: {
          fontSize: '24px',
          lineHeight: '36px',
          fontWeight: 600,
        },
        xxlText: {
          fontSize: '72px',
          lineHeight: '84px',
          fontWeight: 600,
        },
      },
    },
    mobile: {
      en: {
        logo: {
          fontSize: '13px',
          lineHeight: '16px',
          fontWeight: 800,
        },
        link: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 600,
        },
        h1: {
          fontSize: '24px',
          lineHeight: '40px',
          fontWeight: 600,
        },
        h2: {
          fontSize: '32px',
          lineHeight: '40px',
          fontWeight: 600,
        },
        h3: {
          fontSize: '18px',
          lineHeight: '20px',
          fontWeight: 700,
        },
        h4: {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 700,
        },
        superSmallText: {
          fontSize: '10px',
          lineHeight: '14px',
          fontWeight: 400,
        },
        text: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
        },
        smallText: {
          fontSize: '12px',
          lineHeight: '20px',
          fontWeight: 400,
        },
        largeText: {
          fontSize: '18px',
          lineHeight: '32px',
          fontWeight: 400,
        },
        xlText: {
          fontSize: '20px',
          lineHeight: '28px',
          fontWeight: 600,
        },
        xxlText: {
          fontSize: '48px',
          lineHeight: '56px',
          fontWeight: 600,
        },
      },
      ko: {
        logo: {
          fontSize: '13px',
          lineHeight: '16px',
          fontWeight: 800,
        },
        link: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 600,
        },
        h1: {
          fontSize: '24px',
          lineHeight: '40px',
          fontWeight: 600,
        },
        h2: {
          fontSize: '32px',
          lineHeight: '40px',
          fontWeight: 600,
        },
        h3: {
          fontSize: '18px',
          lineHeight: '20px',
          fontWeight: 700,
        },
        h4: {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 700,
        },
        text: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
        },
        superSmallText: {
          fontSize: '10px',
          lineHeight: '14px',
          fontWeight: 400,
        },
        smallText: {
          fontSize: '12px',
          lineHeight: '20px',
          fontWeight: 400,
        },
        largeText: {
          fontSize: '18px',
          lineHeight: '32px',
          fontWeight: 400,
        },
        xlText: {
          fontSize: '20px',
          lineHeight: '28px',
          fontWeight: 600,
        },
        xxlText: {
          fontSize: '48px',
          lineHeight: '56px',
          fontWeight: 600,
        },
      },
    },
  },
  columns: {
    bigDesktop: {
      colOne: `${(240 / 1920) * 100}%`,
      colTwo: `${(362 / 1920) * 100}%`,
      colThree: `${(704 / 1920) * 100}%`,
      colFour: `${(948 / 1920) * 100}%`,
      colFive: `${(972 / 1920) * 100}%`,
      colSix: `${(1094 / 1920) * 100}%`,
      colSeven: `${(1558 / 1920) * 100}%`,
      colEight: `${(1680 / 1920) * 100}%`,
      colNine: '100%',
    },
    desktop: {
      colOne: `${(80 / 1600) * 100}%`,
      colTwo: `${(202 / 1600) * 100}%`,
      colThree: `${(544 / 1600) * 100}%`,
      colFour: `${(788 / 1600) * 100}%`,
      colFive: `${(812 / 1600) * 100}%`,
      colSix: `${(934 / 1600) * 100}%`,
      colSeven: `${(1398 / 1600) * 100}%`,
      colEight: `${(1520 / 1600) * 100}%`,
      colNine: '100%',
    },
    tablet: {
      colOne: `${(41 / 1024) * 100}%`,
      colTwo: `${(122 / 1024) * 100}%`,
      colThree: `${(342 / 1024) * 100}%`,
      colFour: `${(502 / 1024) * 100}%`,
      colFive: `${(522 / 1024) * 100}%`,
      colSix: `${(602 / 1024) * 100}%`,
      colSeven: `${(902 / 1024) * 100}%`,
      colEight: `${(982 / 1024) * 100}%`,
      colNine: '100%',
    },
    mobile: {
      colOne: `${(10 / 360) * 100}%`,
      colTwo: `${(110 / 360) * 100}%`,
      colThree: `${(130 / 360) * 100}%`,
      colFour: `${(230 / 360) * 100}%`,
      colFive: `${(250 / 360) * 100}%`,
      colSix: `${(350 / 360) * 100}%`,
      colSeven: '100%',
    },
  },
};

interface ThemeProps {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Theme: React.FC<ThemeProps> = ({ children }) => {
  return <ThemeProvider theme={theme}> {children}</ThemeProvider>;
};

export default Theme;
