import React from 'react';

const OptimizationIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M50 32C50 29 47.6 26.4 44.6 26C42.4 21.2 37.6 18 32 18C31.6 18 31 18 30.6 18.2C29.4 16.8 27.8 16 26 16C22.6 16 20 18.6 20 22C20 22.8 20.2 23.4 20.4 24.2C18.8 26.4 18 29 18 32C18 35 18.8 37.6 20.4 39.8C20.2 40.6 20 41.2 20 42C20 45.4 22.6 48 26 48C27.8 48 29.4 47.2 30.6 45.8C31 45.8 31.6 46 32 46C37.6 46 42.4 42.6 44.6 38C47.6 37.6 50 35 50 32ZM26 20C27.2 20 28 20.8 28 22C28 23.2 27.2 24 26 24C24.8 24 24 23.2 24 22C24 20.8 24.8 20 26 20ZM26 44C24.8 44 24 43.2 24 42C24 40.8 24.8 40 26 40C27.2 40 28 40.8 28 42C28 43.2 27.2 44 26 44ZM32 42C32 38.6 29.4 36 26 36C25 36 24 36.2 23.2 36.8C22.4 35.4 22 33.8 22 32C22 30.2 22.4 28.6 23.2 27.2C24 27.8 25 28 26 28C29.4 28 32 25.4 32 22C35.8 22 38.8 24 40.6 27C39 28.2 38 30 38 32C38 34 39 35.8 40.6 37C38.8 40 35.8 42 32 42ZM44 34C42.8 34 42 33.2 42 32C42 30.8 42.8 30 44 30C45.2 30 46 30.8 46 32C46 33.2 45.2 34 44 34Z'
        fill='url(#paint0_linear_2071_46233)'
      />
      <path
        d='M30.7481 62.2319L30.762 62.24L30.7764 62.2472C31.0089 62.3635 31.2069 62.4331 31.4178 62.4682C31.6092 62.5001 31.7959 62.5001 31.9795 62.5H32H32.0205C32.2041 62.5001 32.3908 62.5001 32.5822 62.4682C32.7931 62.4331 32.9911 62.3635 33.2236 62.2472L33.238 62.24L33.2519 62.2319L57.2519 48.2319L57.2649 48.2244L57.2774 48.216C57.9926 47.7392 58.5 46.994 58.5 46V30V29.5H58H54H53.5V30V44.7125L32 57.2215L10.5 44.7125V19.4876L32.0017 6.9775L54.7511 20.0337L55.1802 20.2799L55.431 19.8535L57.431 16.4535L57.6854 16.021L57.2519 15.7681L33.2641 1.77521C32.5021 1.27496 31.4979 1.27496 30.7359 1.77521L6.74806 15.7681L6.73512 15.7757L6.72265 15.784C6.00741 16.2608 5.5 17.0061 5.5 18V46C5.5 46.994 6.00741 47.7392 6.72265 48.216L6.73512 48.2244L6.74806 48.2319L30.7481 62.2319Z'
        fill='url(#paint1_linear_2071_46233)'
        stroke='url(#paint2_linear_2071_46233)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2071_46233'
          x1='51.9613'
          y1='43.8621'
          x2='1.07112'
          y2='37.058'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2071_46233'
          x1='61.1871'
          y1='54.2285'
          x2='-21.8761'
          y2='44.6196'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_2071_46233'
          x1='61.1871'
          y1='54.2285'
          x2='-21.8761'
          y2='44.6196'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default OptimizationIcon;
