import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import descriptionImage from '../../assets/productImages/realSi/description.png';
import extendedDescriptionImage from '../../assets/productImages/realSi/extendedDescription.png';
import topLeftImage from '../../assets/productImages/realSi/topLeft.png';
import topRightImage from '../../assets/productImages/realSi/topRight.png';
import IllustrationBox from '../../components/atoms/IllustrationBox/IllustrationBox';
import Text from '../../components/atoms/Text/Text';
import TopSectionProduct from '../../components/organisms/TopSectionProduct/TopSectionProduct';
import { gaMeasurementId } from '../../config';
import ConnectedCirclesIcon from '../../icons/ConnectedCircles';
import EnhancedGraphIcon from '../../icons/EnhancedGraph';
import GovernmentIcon from '../../icons/Government';
import HexagonIcon from '../../icons/Hexagon';
import LockIcon from '../../icons/Lock';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserCheckIcon from '../../icons/UserCheck';
import WalletIcon from '../../icons/Wallet';
import { deviceBreakpoints } from '../../Theme';
import Description from './sections/Description';
import ExtendedDescription from './sections/ExtendedDescription';
import Features from './sections/Features';
import SlideShow from './sections/SlideShow';

ReactGA.initialize(gaMeasurementId);

const RealSi: React.FC = () => {
  const { t } = useTranslation(['products']);
  ReactGA.send({ hitType: 'pageview', page: '/products/real-si', title: 'Real-SI' });
  const illustrations = [
    {
      title: t('realSi.topIllustrationLeftTitle'),
      description: t('realSi.topIllustrationLeftDescription'),
      image: topLeftImage,
    },
    {
      title: t('realSi.topIllustrationMiddleTitle'),
      description: t('realSi.topIllustrationMiddleDescription'),
      middleIllustration: (
        <IllustrationBox
          title={t('realSi.topIllustrationMiddleBoxTitle')}
          subtitle={t('realSi.topIllustrationMiddleBoxSubtitle')}
          bodyTags={['Planning', 'Design', 'System Compatibility', 'and etc.']}
        />
      ),
    },
    {
      title: t('realSi.topIllustrationRightTitle'),
      description: t('realSi.topIllustrationRightDescription'),
      image: topRightImage,
    },
  ];

  const features = [
    {
      title: t('realSi.featureOneTitle'),
      description: t('realSi.featureOneDescription'),
      icon: <EnhancedGraphIcon />,
    },
    {
      title: t('realSi.featureTwoTitle'),
      description: t('realSi.featureTwoDescription'),
      icon: <WalletIcon />,
    },
    {
      title: t('realSi.featureThreeTitle'),
      description: t('realSi.featureThreeDescription'),
      icon: <HexagonIcon />,
    },
    {
      title: t('realSi.featureFourTitle'),
      description: t('realSi.featureFourDescription'),
      icon: <ConnectedCirclesIcon />,
    },
    {
      title: t('realSi.featureFiveTitle'),
      description: t('realSi.featureFiveDescription'),
      icon: <LockIcon />,
    },
  ];

  const slideShowItems = [
    {
      icon: <ShoppingCartIcon />,
      title: t('realSi.slideShowItemOneTitle'),
      description: t('realSi.slideShowItemOneDescription'),
    },
    {
      icon: <GovernmentIcon />,
      title: t('realSi.slideShowItemTwoTitle'),
      description: t('realSi.slideShowItemTwoDescription'),
    },
    {
      icon: <UserCheckIcon />,
      title: t('realSi.slideShowItemThreeTitle'),
      description: t('realSi.slideShowItemThreeDescription'),
    },
  ];

  const mainDescriptionList = [
    t('realSi.mainExtendedDescriptionItemOne'),
    t('realSi.mainExtendedDescriptionItemTwo'),
    t('realSi.mainExtendedDescriptionItemThree'),
    t('realSi.mainExtendedDescriptionItemFour'),
    t('realSi.mainExtendedDescriptionItemFive'),
  ];

  const descriptionList = (
    <ul>
      {mainDescriptionList.map((elem, index) => (
        <li key={`list_element_${index}`}>
          <Text type='text'>{elem}</Text>
        </li>
      ))}
    </ul>
  );

  return (
    <Wrapper>
      <SectionWrapper>
        <TopSectionProduct
          title={t('realSi.topTitle')}
          description={t('realSi.topDescription')}
          illustrations={illustrations}
        />
      </SectionWrapper>

      <SectionWrapper>
        <Description
          secondaryTitle={t('realSi.mainDescriptionTitleBottom')}
          secondarySubtitle={t('realSi.mainDescriptionSubtitleBottom')}
          secondaryDescription={t('realSi.mainDescriptionBottom')}
          image={descriptionImage}
          descriptionList={descriptionList}
        />
      </SectionWrapper>

      <SectionWrapper>
        <ExtendedDescription
          title={t('realSi.extendedDescriptionTitle')}
          subTitle={t('realSi.extendedDescriptionSubtitle')}
          description={t('realSi.extendedDescription')}
          image={extendedDescriptionImage}
          buttonText={'Book a Session'}
        />
      </SectionWrapper>

      <SectionWrapper>
        <Features features={features} title={t('realSi.featuresTitle')} />
      </SectionWrapper>

      <SectionWrapper>
        <SlideShow slideItems={slideShowItems} title={t('ourProjects')} />
      </SectionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  margin-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 60px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 64px;

  &:first-child {
    margin-bottom: 110px;
  }
`;

export default RealSi;
