import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import descriptionImage from '../../assets/productImages/realSid/description.png';
import extendedDescriptionImage from '../../assets/productImages/realSid/extendedDescription.png';
import topLeftImage from '../../assets/productImages/realSid/topLeft.png';
import topRightImage from '../../assets/productImages/realSid/topRight.png';
import IllustrationBox from '../../components/atoms/IllustrationBox/IllustrationBox';
import TopSectionProduct from '../../components/organisms/TopSectionProduct/TopSectionProduct';
import { gaMeasurementId } from '../../config';
import AlphabetIcon from '../../icons/Alphabet';
import BankIcon from '../../icons/Bank';
import CallPerson from '../../icons/CallPerson';
import GenderIcon from '../../icons/Gender';
import GovernmentIcon from '../../icons/Government';
import LinesIcon from '../../icons/Lines';
import LockIcon from '../../icons/Lock';
import MedicalIcon from '../../icons/Medical';
import RobotIcon from '../../icons/Robot';
import SheetVolumeMicrophoneIcon from '../../icons/SheetMicrophone';
import SheetVolumeIcon from '../../icons/SheetVolume';
import SpeechBubbleCheckmarkIcon from '../../icons/SpeechBubbleCheckmark';
import SpeechBubbleReceptionIcon from '../../icons/SpeechBubbleReception';
import SpeechBubbleWavesIcon from '../../icons/SpeechBubbleWaves';
import WavesIcon from '../../icons/Waves';
import { deviceBreakpoints } from '../../Theme';
import Description from './sections/Description';
import ExtendedDescription from './sections/ExtendedDescription';
import Features from './sections/Features';
import SlideShow from './sections/SlideShow';

ReactGA.initialize(gaMeasurementId);

const RealSid: React.FC = () => {
  const { t } = useTranslation(['products']);
  ReactGA.send({ hitType: 'pageview', page: '/products/real-sid', title: 'Real-SID' });
  const illustrations = [
    {
      title: t('realSid.topIllustrationLeftTitle'),
      description: t('realSid.topIllustrationLeftDescription'),
      image: topLeftImage,
    },
    {
      title: t('realSid.topIllustrationMiddleTitle'),
      description: t('realSid.topIllustrationMiddleDescription'),
      middleIllustration: (
        <IllustrationBox
          title={t('realSid.topIllustrationMiddleBoxTitle')}
          subtitle={t('realSid.topIllustrationMiddleBoxSubtitle')}
          bodyTags={['Real-SID Engine', 'Data Base Info']}
        />
      ),
    },
    {
      title: t('realSid.topIllustrationRightTitle'),
      description: t('realSid.topIllustrationRightDescription'),
      image: topRightImage,
    },
  ];

  const features = [
    {
      title: t('realSid.featureOneTitle'),
      description: t('realSid.featureOneDescription'),
      icon: <SpeechBubbleCheckmarkIcon />,
    },
    {
      title: t('realSid.featureTwoTitle'),
      description: t('realSid.featureTwoDescription'),
      icon: <AlphabetIcon />,
    },
    {
      title: t('realSid.featureThreeTitle'),
      description: t('realSid.featureThreeDescription'),
      icon: <GenderIcon />,
    },
    {
      title: t('realSid.featureFourTitle'),
      description: t('realSid.featureFourDescription'),
      icon: <RobotIcon />,
    },
    {
      title: t('realSid.featureFiveTitle'),
      description: t('realSid.featureFiveDescription'),
      icon: <SheetVolumeIcon />,
    },
    {
      title: t('realSid.featureSixTitle'),
      description: t('realSid.featureSixDescription'),
      icon: <SheetVolumeMicrophoneIcon />,
    },
    {
      title: t('realSid.featureSevenTitle'),
      description: t('realSid.featureSevenDescription'),
      icon: <LinesIcon />,
    },
    {
      title: t('realSid.featureEightTitle'),
      description: t('realSid.featureEightDescription'),
      icon: <WavesIcon />,
    },
    {
      title: t('realSid.featureNineTitle'),
      description: t('realSid.featureNineDescription'),
      icon: <SpeechBubbleWavesIcon />,
    },
    {
      title: t('realSid.featureTenTitle'),
      description: t('realSid.featureTenDescription'),
      icon: <SpeechBubbleReceptionIcon />,
    },
  ];

  const descriptionBulletPoints = [
    {
      title: t('realSid.mainDescriptionBulletPointOneTitle'),
      description: t('realSid.mainDescriptionBulletPointOneDescription'),
    },
    {
      title: t('realSid.mainDescriptionBulletPointTwoTitle'),
      description: t('realSid.mainDescriptionBulletPointTwoDescription'),
    },
    {
      title: t('realSid.mainDescriptionBulletPointThreeTitle'),
      description: t('realSid.mainDescriptionBulletPointThreeDescription'),
    },
    {
      title: t('realSid.mainDescriptionBulletPointFourTitle'),
      description: t('realSid.mainDescriptionBulletPointFourDescription'),
    },
  ];

  const slideItems = [
    {
      icon: <BankIcon />,
      title: t('realSid.slideShowItemOneTitle'),
      description: t('realSid.slideShowItemOneDescription'),
    },
    {
      icon: <MedicalIcon />,
      title: t('realSid.slideShowItemTwoTitle'),
      description: t('realSid.slideShowItemTwoDescription'),
    },
    {
      icon: <GovernmentIcon />,
      title: t('realSid.slideShowItemThreeTitle'),
      description: t('realSid.slideShowItemThreeDescription'),
    },
    {
      icon: <CallPerson />,
      title: t('realSid.slideShowItemFourTitle'),
      description: t('realSid.slideShowItemFourDescription'),
    },
    {
      icon: <LockIcon />,
      title: t('realSid.slideShowItemFiveTitle'),
      description: t('realSid.slideShowItemFiveDescription'),
    },
  ];

  return (
    <Wrapper>
      <SectionWrapper>
        <TopSectionProduct
          title={t('realSid.topTitle')}
          description={t('realSid.topDescription')}
          illustrations={illustrations}
        />
      </SectionWrapper>

      <SectionWrapper>
        <Description
          mainTitle={t('realSid.mainDescriptionTitleTop')}
          mainSubtitle={t('realSid.mainDescriptionSubtitleTop')}
          mainDescription={t('realSid.mainDescriptionTop')}
          image={descriptionImage}
          bulletPoints={descriptionBulletPoints}
        />
      </SectionWrapper>

      <SectionWrapper>
        <ExtendedDescription
          title={t('realSid.extendedDescriptionTitle')}
          subTitle={t('realSid.extendedDescriptionSubtitle')}
          description={t('realSid.extendedDescription')}
          image={extendedDescriptionImage}
          buttonText='Book a Session'
        />
      </SectionWrapper>

      <SectionWrapper>
        <Features
          features={features}
          title={t('realSid.featuresTitle')}
          subTitle={t('realSid.featuresSubtitle')}
        />
      </SectionWrapper>

      <SectionWrapper>
        <SlideShow slideItems={slideItems} title={t('useCases')} />
      </SectionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  margin-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 60px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 64px;

  &:first-child {
    margin-bottom: 110px;
  }
`;

export default RealSid;
