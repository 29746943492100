import React from 'react';
import styled from 'styled-components';

import CheckmarkIcon from '../../../icons/Checkmark';
import ChevronIcon from '../../../icons/Chevron';
import { deviceBreakpoints } from '../../../Theme';
import Text from '../Text/Text';

interface DropdownItemWithIconProps {
  itemValue: string | React.ReactNode;
  itemText?: string;
  isSelected: boolean;
  isDefault?: boolean;
  isCentered: boolean;
  isOpen: boolean;
  delayAnimation: number;

  onClickAction: () => void;
}

const DropdownItemWithIcon: React.FC<DropdownItemWithIconProps> = ({
  itemValue,
  itemText,
  isSelected,
  isCentered,
  isDefault,
  isOpen,
  onClickAction,
  delayAnimation,
}) => {
  return (
    <DropdownItemWithIconWrapper
      delayAnimation={delayAnimation}
      isSelected={isSelected}
      isCentered={isCentered}
      onClick={() => onClickAction()}
    >
      {itemValue}
      <TextWrapper>
        <Text type='text' color='accent'>
          {itemText}
        </Text>
      </TextWrapper>
      <DropdownItemIcon>
        {isDefault ? (
          <IconWrapper isOpen={isOpen}>
            <ChevronIcon />
          </IconWrapper>
        ) : (
          <> {isSelected && <CheckmarkIcon />}</>
        )}
      </DropdownItemIcon>
    </DropdownItemWithIconWrapper>
  );
};

const DropdownItemWithIconWrapper = styled.div<Partial<DropdownItemWithIconProps>>`
  display: flex;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.lightBckg : props.theme.colors.transparent};
  border-radius: 8px;
  padding: 4px;
  justify-content: ${(props) => (props.isCentered ? 'center' : 'flex-start')};

  animation: flyIn ${(props) => props.delayAnimation}s linear;

  @keyframes flyIn {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    justify-content: start;
  }
`;

const DropdownItemIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
`;

const IconWrapper = styled.div<Partial<DropdownItemWithIconProps>>`
  display: flex;
  align-items: center;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`;

const TextWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export default DropdownItemWithIcon;
