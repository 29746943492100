import React from 'react';

const CallPerson: React.FC = () => {
  return (
    <svg width='54' height='50' viewBox='0 0 54 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M44.92 32.36C45.8533 30.0933 46.36 27.6667 46.36 25C46.36 23.08 46.0666 21.24 45.56 19.5333C43.8266 19.9333 42.0133 20.1467 40.12 20.1467C36.2426 20.1508 32.421 19.2227 28.9774 17.4406C25.5338 15.6585 22.5691 13.0746 20.3333 9.90667C17.9416 15.6938 13.4296 20.3488 7.71998 22.92C7.61331 23.5867 7.61331 24.3067 7.61331 25C7.61331 27.5459 8.11476 30.0669 9.08904 32.419C10.0633 34.7711 11.4913 36.9082 13.2915 38.7084C16.9272 42.3442 21.8583 44.3867 27 44.3867C29.8 44.3867 32.4933 43.7733 34.92 42.68C36.44 45.5867 37.1333 47.0267 37.08 47.0267C32.7066 48.4933 29.32 49.2133 27 49.2133C20.5466 49.2133 14.3866 46.68 9.85331 42.12C7.09597 39.3711 5.0463 35.9947 3.87998 32.28H0.333313V20.1467H3.23998C4.12062 15.86 6.14529 11.8918 9.09915 8.66292C12.053 5.43407 15.8259 3.06509 20.0174 1.80736C24.2089 0.549631 28.6627 0.450063 32.9063 1.51922C37.1498 2.58838 41.0248 4.78639 44.12 7.88C47.4806 11.2273 49.7728 15.4963 50.7066 20.1467H53.6666V32.28H53.5067L44.0133 41L29.88 39.4V34.9467H42.76L44.92 32.36ZM19.72 24.3867C20.52 24.3867 21.2933 24.7067 21.8533 25.2933C22.4161 25.8607 22.7319 26.6275 22.7319 27.4267C22.7319 28.2258 22.4161 28.9926 21.8533 29.56C21.2933 30.12 20.52 30.44 19.72 30.44C18.04 30.44 16.68 29.1067 16.68 27.4267C16.68 25.7467 18.04 24.3867 19.72 24.3867ZM34.2533 24.3867C35.9333 24.3867 37.2666 25.7467 37.2666 27.4267C37.2666 29.1067 35.9333 30.44 34.2533 30.44C32.5733 30.44 31.2133 29.1067 31.2133 27.4267C31.2133 26.6204 31.5336 25.8472 32.1037 25.2771C32.6738 24.707 33.4471 24.3867 34.2533 24.3867Z'
        fill='url(#paint0_linear_202_1043)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_202_1043'
          x1='56.9355'
          y1='42.951'
          x2='3.14068'
          y2='35.3382'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CallPerson;
