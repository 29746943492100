import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dawini from '../../../assets/dawini.png';
import { DemoWebsiteLink } from '../../../config';
import ChevronIcon from '../../../icons/Chevron';
import SheetMicrophoneIcon from '../../../icons/SheetMicrophone';
import WaveBoxIcon from '../../../icons/WaveBox';
import { deviceBreakpoints } from '../../../Theme';
import Button from '../../atoms/Button/Button';
import Text from '../../atoms/Text/Text';

const DemoDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation(['main']);

  const handleRedirectToDemoSite = (link: string) => {
    window.open(link, '_blank')?.focus();
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <DemoDropdownWrapper ref={ref}>
      <DemoDropdownButton onClick={() => setIsOpen(!isOpen)}>
        <Text color={'white'} type={'link'}>
          {t('visitDemoSite')}
        </Text>
        <ChevronIconWrapper $isOpen={isOpen}>
          <ChevronIcon color={'white'} />
        </ChevronIconWrapper>
      </DemoDropdownButton>
      {isOpen && (
        <DemoDropdownMenuWrapper>
          <DemoDropdownItem key={'demo'}>
            <IconContainer>
              <img src={dawini} width={64} />
              <Text type='h4' color='accent'>
                {t('demoItemOneTitle')}
              </Text>
            </IconContainer>
            <IconContainer>
              <SheetMicrophoneIcon />
              <Text type='h4' color='accent'>
                {t('demoItemTwoTitle')}
              </Text>
            </IconContainer>
            <IconContainer>
              <WaveBoxIcon />
              <Text type='h4' color='accent'>
                {t('demoItemThreeTitle')}
              </Text>
            </IconContainer>
            <Button
              buttonType='blue'
              text={t('visitDemoSite')}
              onClickAction={() => handleRedirectToDemoSite(DemoWebsiteLink)}
            />
          </DemoDropdownItem>
        </DemoDropdownMenuWrapper>
      )}
    </DemoDropdownWrapper>
  );
};

const DemoDropdownWrapper = styled.div`
  position: relative;
  align-self: center;
`;

const DemoDropdownMenuWrapper = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  gap: 16px;
  background-color: #ffffff;
  padding: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin-top: 30px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    overflow: scroll;
  }
`;

const DemoDropdownItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 32px;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBckg};
    border-radius: 8px;
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 300px;
    padding: 16px 32px;
    background-color: ${(props) => props.theme.colors.lightBckg};
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  & > * {
    margin-right: 10px; /* Add space between the elements */
  }
  & > *:last-child {
    margin-right: 0; /* Remove the margin from the last element */
  }
`;

const DemoDropdownButton = styled.div`
  padding: 16px 20px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  min-width: 155px;
  cursor: pointer;
  gap: 8px;
  background-color: ${(props) => props.theme.colors.accent};

  &:hover {
    background-color: ${(props) => props.theme.colors.accentHover};
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 12px 16px;
    min-width: 128px;
  }
`;

const ChevronIconWrapper = styled.div<{ $isOpen: boolean }>`
  display: flex;
  align-items: center;
  transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`;

export default DemoDropdown;
