import 'react-phone-number-input/style.css';

import React, { ChangeEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';

interface InputProps {
  placeholder: string;
}

export type TextFieldProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const Input = <T extends FieldValues>(props: TextFieldProps<T>) => {
  const { name, control, rules } = props;
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value);
  };

  const { i18n } = useTranslation();

  return name === 'phone' ? (
    <>
      <PhoneInput
        defaultCountry='KR'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(value: any) => field.onChange(value)}
        {...props}
      />
      <Error $isVisible={!!fieldState.error} $language={i18n.language}>
        {fieldState.error && fieldState.error.message}
      </Error>
    </>
  ) : (
    <>
      <StyledInput value={field.value || ''} onChange={handleChange} {...props} />
      <Error $isVisible={!!fieldState.error} $language={i18n.language}>
        {fieldState.error && fieldState.error.message}
      </Error>
    </>
  );
};

const StyledInput = styled.input`
  height: 50px;
  border: none;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.border}`};

  :focus-visible {
    outline: none;
    border-bottom: ${(props) => `1px solid ${props.theme.colors.accent}`};
  }
`;
const Error = styled.div<{ $isVisible: boolean; $language: string }>`
  color: ${({ theme: { colors } }) => colors.red};
  font-size: ${(props) => props.theme.fonts.desktop[props.$language].smallText.fontSize};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  height: 16px;

  @media only screen and ${deviceBreakpoints.mobile} {
    font-size: ${(props) => props.theme.fonts.mobile[props.$language].smallText.fontSize};
  }
`;

export default Input;
