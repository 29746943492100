import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import WaveSurfer from 'wavesurfer.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as MarkerPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.markers';

import PauseIcon from '../../../icons/Pause';
import PlayIcon from '../../../icons/Play';
import audio from './audioTranscript.mp3';

const AudioPlayer: React.FC = () => {
  const waveSurferRef = useRef<WaveSurfer>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    waveSurferRef.current = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#4D62FF',
      cursorColor: '#4FEEE4',
      cursorWidth: 2,
      progressColor: '#4D62FF',
      barGap: 2,
      barWidth: 2,
      barMinHeight: 1,
      height: 40,
      plugins: [MarkerPlugin.create()],
    });
    waveSurferRef.current.load(audio);

    return () => waveSurferRef.current?.destroy();
  }, []);

  useEffect(() => {
    waveSurferRef.current?.on('play', () => {
      setIsPlaying(true);
    });

    waveSurferRef.current?.on('pause', () => {
      setIsPlaying(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waveSurferRef.current]);

  const handlePlayPause = () => {
    if (!waveSurferRef.current?.isPlaying()) {
      waveSurferRef.current?.play();
      setIsPlaying(true);
    } else {
      waveSurferRef.current?.pause();
      setIsPlaying(false);
    }
  };

  return (
    <AudioWrapper>
      <WaveformWrapper>
        <IconWrapper onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </IconWrapper>
        <Waveform>
          <WaveformContainer id='waveform' />
        </Waveform>
      </WaveformWrapper>
    </AudioWrapper>
  );
};

const AudioWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

const WaveformWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.lightBckg};
  border-radius: 24px;
  height: 56px;
  padding: 4px 8px;

  @media (max-width: 765px) {
    margin: 0 0 8px 0;
  }
`;

const Waveform = styled.div`
  width: 100%;
  padding-left: 12px;
  overflow-x: hidden;
`;

const WaveformContainer = styled.div`
  overflow-x: hidden;

  & wave {
    overflow: hidden !important;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.purple};
  border-radius: 50%;
`;

export default AudioPlayer;
