import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Text from '../../components/atoms/Text/Text';
import GoalIcon from '../../icons/Goal';
import HeartWithHandsIcon from '../../icons/HeartWithHands';
import MicrophoneIcon from '../../icons/Microphone';
import PeopleIcon from '../../icons/People';
import SmileyIcon from '../../icons/Smiley';
import WorldIcon from '../../icons/World';
import { deviceBreakpoints } from '../../Theme';

const CoreValues: React.FC = () => {
  const { t } = useTranslation(['aboutUs']);

  const coreValues = [
    {
      icon: <SmileyIcon />,
      title: t('ourCoreValueTitle1'),
      description: t('ourCoreValueDescription1'),
    },
    {
      icon: <GoalIcon />,
      title: t('ourCoreValueTitle2'),
      description: t('ourCoreValueDescription2'),
    },
    {
      icon: <WorldIcon />,
      title: t('ourCoreValueTitle3'),
      description: t('ourCoreValueDescription3'),
    },
    {
      icon: <PeopleIcon />,
      title: t('ourCoreValueTitle4'),
      description: t('ourCoreValueDescription4'),
    },
    {
      icon: <MicrophoneIcon />,
      title: t('ourCoreValueTitle5'),
      description: t('ourCoreValueDescription5'),
    },
    {
      icon: <HeartWithHandsIcon />,
      title: t('ourCoreValueTitle6'),
      description: t('ourCoreValueDescription6'),
    },
  ];

  return (
    <CoreValuesWrapper className='main-container'>
      <div className='col-start-2 col-end-12 col-start-1-m col-end-none-m'>
        <SubtitleWrapper>
          <Text type='xlText' color='lightBlue'>
            {t('ourCoreValuesSubtitle')}
          </Text>
        </SubtitleWrapper>
        <TitleWrapper>
          <Text type='h2' color='accent'>
            {t('ourCoreValues')}
          </Text>
        </TitleWrapper>

        <CoreValuesList>
          {coreValues.map((coreValue, key) => (
            <CoreValue key={`coreValue_${key}`}>
              <IconWrapper> {coreValue.icon}</IconWrapper>
              <CoreValueTitleWrapper>
                <Text type='h4' color='text'>
                  {coreValue.title}
                </Text>
              </CoreValueTitleWrapper>

              <Text type='text' color='textLight'>
                {coreValue.description}
              </Text>
            </CoreValue>
          ))}
        </CoreValuesList>
      </div>
    </CoreValuesWrapper>
  );
};

const CoreValuesWrapper = styled.div`
  display: grid;
  width: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const SubtitleWrapper = styled.div`
  &:nth-child(1) {
    text-transform: uppercase;
  }
`;

const TitleWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 40px;
  }
`;

const CoreValuesList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 48px;
  margin-top: 44px;

  @media only screen and ${deviceBreakpoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
`;

const CoreValue = styled.div`
  margin-bottom: 72px;
  display: flex;
  flex-direction: column;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-bottom: 32px;
  }
`;

const CoreValueTitleWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 12px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 12px;
    margin-bottom: 8px;
  }
`;

const IconWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

export default CoreValues;
