import React from 'react';

const QuoteIcon: React.FC = () => {
  return (
    <svg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27.44 1.13599L26.8 5.808C25.136 5.68 23.92 6.02133 23.152 6.83199C22.384 7.64266 21.9147 8.73066 21.744 10.096C21.5733 11.4613 21.5307 12.9333 21.616 14.512H27.44V28.016H16.496V11.952C16.496 8.02666 17.4773 5.08266 19.44 3.12C21.4453 1.15733 24.112 0.495995 27.44 1.13599ZM11.184 1.13599L10.544 5.808C8.88 5.68 7.664 6.02133 6.896 6.83199C6.128 7.64266 5.65867 8.73066 5.488 10.096C5.31733 11.4613 5.27467 12.9333 5.36 14.512H11.184V28.016H0.240001V11.952C0.240001 8.02666 1.22133 5.08266 3.184 3.12C5.18933 1.15733 7.856 0.495995 11.184 1.13599Z'
        fill='white'
      />
    </svg>
  );
};

export default QuoteIcon;
