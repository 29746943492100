import React from 'react';

const TriangleIcon: React.FC = () => {
  return (
    <svg width='40' height='24' viewBox='0 0 40 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 0L40 24H0V0Z' fill='white' fillOpacity='0.8' />
    </svg>
  );
};

export default TriangleIcon;
