import React from 'react';

interface BigChevronIconProps {
  color?: string;
}

const BigChevronIcon: React.FC<BigChevronIconProps> = ({ color }) => {
  return (
    <svg width='11' height='19' viewBox='0 0 11 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.14961 18.575L10.5746 10.175C10.6746 10.075 10.7453 9.96667 10.7866 9.85C10.8286 9.73333 10.8496 9.60833 10.8496 9.475C10.8496 9.34167 10.8286 9.21667 10.7866 9.1C10.7453 8.98333 10.6746 8.875 10.5746 8.775L2.14961 0.35C1.91628 0.116667 1.62461 0 1.27461 0C0.924609 0 0.62461 0.125 0.37461 0.375C0.12461 0.625 -0.000390053 0.916667 -0.000390053 1.25C-0.000390053 1.58333 0.12461 1.875 0.37461 2.125L7.72461 9.475L0.37461 16.825C0.141276 17.0583 0.0246096 17.3457 0.0246096 17.687C0.0246096 18.029 0.14961 18.325 0.39961 18.575C0.64961 18.825 0.941277 18.95 1.27461 18.95C1.60794 18.95 1.89961 18.825 2.14961 18.575Z'
        fill={color || '#888CAB'}
      />
    </svg>
  );
};

export default BigChevronIcon;
