import React from 'react';

const PauseIcon: React.FC = () => {
  return (
    <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x={3} y={2} width={2} height={12} rx={1} fill='#fff' />
      <rect x={11} y={2} width={2} height={12} rx={1} fill='#fff' />
    </svg>
  );
};

export default PauseIcon;
