import React from 'react';
import styled from 'styled-components';

import Text from '../Text/Text';

interface SpeakerBoxProps {
  speakerName: string;
  text: string;
}

const SpeakerBox: React.FC<SpeakerBoxProps> = ({ speakerName, text }) => {
  return (
    <SpeakerBoxWrapper>
      <SpeakerNameWrapper>
        <Text type='largeText'>{`S${speakerName}: `}</Text>
      </SpeakerNameWrapper>
      <Text type='largeText'>{text}</Text>
    </SpeakerBoxWrapper>
  );
};

const SpeakerBoxWrapper = styled.div`
  display: flex;
  gap: 20px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07);
  border-radius: 30px 30px 30px 8px;
  padding: 16px 24px 20px;
`;

const SpeakerNameWrapper = styled.div`
  & div {
    white-space: nowrap;
  }
`;

export default SpeakerBox;
