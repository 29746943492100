import React from 'react';

const BankIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 27H11V39H8C7.20435 39 6.44129 39.3161 5.87868 39.8787C5.31607 40.4413 5 41.2043 5 42C5 42.7957 5.31607 43.5587 5.87868 44.1213C6.44129 44.6839 7.20435 45 8 45H56C56.7957 45 57.5587 44.6839 58.1213 44.1213C58.6839 43.5587 59 42.7957 59 42C59 41.2043 58.6839 40.4413 58.1213 39.8787C57.5587 39.3161 56.7957 39 56 39H53V27H58C58.6519 26.9988 59.2857 26.7853 59.8055 26.3918C60.3253 25.9983 60.7027 25.4462 60.8807 24.819C61.0587 24.1918 61.0275 23.5238 60.7919 22.9159C60.5563 22.308 60.1291 21.7934 59.575 21.45L33.575 5.45C33.1026 5.15518 32.5569 4.99889 32 4.99889C31.4431 4.99889 30.8974 5.15518 30.425 5.45L4.425 21.45C3.87086 21.7934 3.44366 22.308 3.20807 22.9159C2.97248 23.5238 2.94132 24.1918 3.1193 24.819C3.29728 25.4462 3.67472 25.9983 4.19449 26.3918C4.71426 26.7853 5.34807 26.9988 6 27ZM17 27H23V39H17V27ZM35 27V39H29V27H35ZM47 39H41V27H47V39ZM32 11.525L47.4 21H16.6L32 11.525ZM63 52C63 52.7957 62.6839 53.5587 62.1213 54.1213C61.5587 54.6839 60.7957 55 60 55H4C3.20435 55 2.44129 54.6839 1.87868 54.1213C1.31607 53.5587 1 52.7957 1 52C1 51.2043 1.31607 50.4413 1.87868 49.8787C2.44129 49.3161 3.20435 49 4 49H60C60.7957 49 61.5587 49.3161 62.1213 49.8787C62.6839 50.4413 63 51.2043 63 52Z'
        fill='url(#paint0_linear_151_9855)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_151_9855'
          x1='66.8'
          y1='48.5343'
          x2='-30.8776'
          y2='32.3409'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BankIcon;
