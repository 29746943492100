import React from 'react';

const DataGraphIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.73779 10.0708C5.95678 8.85181 7.61008 8.16699 9.33398 8.16699H54.6673C56.3912 8.16699 58.0445 8.85181 59.2635 10.0708C60.4825 11.2898 61.1673 12.9431 61.1673 14.667V26.667C61.1673 28.0477 60.048 29.167 58.6673 29.167H5.33398C3.95327 29.167 2.83398 28.0477 2.83398 26.667V14.667C2.83398 12.9431 3.5188 11.2898 4.73779 10.0708ZM9.33398 13.167C8.93616 13.167 8.55463 13.325 8.27332 13.6063C7.99202 13.8876 7.83398 14.2692 7.83398 14.667V24.167H56.1673V14.667C56.1673 14.2692 56.0093 13.8876 55.728 13.6063C55.4467 13.325 55.0651 13.167 54.6673 13.167H9.33398ZM39.5686 32.7848C40.55 31.9269 42.0259 31.967 42.9592 32.877L48.9326 38.701C49.9212 39.6649 49.9412 41.2477 48.9773 42.2362C48.0134 43.2248 46.4307 43.2449 45.4421 42.281L41.1215 38.0684L32.1367 45.9226C31.1618 46.7747 29.6976 46.7416 28.7624 45.8461L21.765 39.1464L7.26002 53.7991C6.28867 54.7804 4.70577 54.7884 3.72453 53.817C2.74328 52.8457 2.73527 51.2628 3.70662 50.2815L19.9413 33.8815C20.9012 32.9118 22.4613 32.8909 23.4469 33.8346L30.5681 40.6527L39.5686 32.7848Z'
        fill='url(#paint0_linear_2060_47500)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.314 18.667C12.314 17.2863 13.4333 16.167 14.814 16.167H17.4806C18.8614 16.167 19.9806 17.2863 19.9806 18.667C19.9806 20.0477 18.8614 21.167 17.4806 21.167H14.814C13.4333 21.167 12.314 20.0477 12.314 18.667ZM22.9833 18.667C22.9833 17.2863 24.1026 16.167 25.4833 16.167H49.4833C50.864 16.167 51.9833 17.2863 51.9833 18.667C51.9833 20.0477 50.864 21.167 49.4833 21.167H25.4833C24.1026 21.167 22.9833 20.0477 22.9833 18.667ZM5.33398 21.5003C6.7147 21.5003 7.83398 22.6196 7.83398 24.0003V36.0003C7.83398 37.381 6.7147 38.5003 5.33398 38.5003C3.95327 38.5003 2.83398 37.381 2.83398 36.0003V24.0003C2.83398 22.6196 3.95327 21.5003 5.33398 21.5003ZM58.6673 21.5003C60.048 21.5003 61.1673 22.6196 61.1673 24.0003V49.3337C61.1673 51.0576 60.4825 52.7109 59.2635 53.9299C58.0445 55.1488 56.3912 55.8337 54.6673 55.8337H16.0007C14.6199 55.8337 13.5007 54.7144 13.5007 53.3337C13.5007 51.9529 14.6199 50.8337 16.0007 50.8337H54.6673C55.0651 50.8337 55.4467 50.6756 55.728 50.3943C56.0093 50.113 56.1673 49.7315 56.1673 49.3337V24.0003C56.1673 22.6196 57.2866 21.5003 58.6673 21.5003Z'
        fill='url(#paint1_linear_2060_47500)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2060_47500'
          x1='64.7426'
          y1='48.5438'
          x2='-27.0868'
          y2='33.0996'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2060_47500'
          x1='64.7426'
          y1='50.7044'
          x2='-26.1697'
          y2='32.8293'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DataGraphIcon;
