import React from 'react';
import styled from 'styled-components';

import logoFooterDesktop from '../../../assets/logoFooterDesktop.png';
import logoFooterMobile from '../../../assets/logoFooterMobile.png';
import logoHeaderDesktop from '../../../assets/logoHeaderDesktop.png';
import logoHeaderMobile from '../../../assets/logoHeaderMobile.png';
import { deviceBreakpoints } from '../../../Theme';

interface LogoProps {
  type: 'header' | 'footer';
  size?: 'small' | 'default';
  className?: string;
  onClick?: () => void;
}

const Logo: React.FC<LogoProps> = ({ className, size, type, onClick }) => {
  let imgSrc;

  switch (type) {
    case 'header':
      imgSrc = size === 'small' ? logoHeaderMobile : logoHeaderDesktop;
      break;
    case 'footer':
      imgSrc = size === 'small' ? logoFooterMobile : logoFooterDesktop;
      break;
    default:
      imgSrc = logoHeaderDesktop;
  }

  return (
    <LogoWrapper className={className}>
      <IconWrapper onClick={onClick}>
        <img src={imgSrc} width={size === 'small' ? 140 : 240} />
      </IconWrapper>
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    justify-self: center;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

export default Logo;
