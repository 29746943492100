import React from 'react';

const CurvedArrowTopIcon: React.FC = () => {
  return (
    <svg
      width='121'
      height='39'
      viewBox='0 0 121 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.13'
        d='M1.0082e-05 38.9447C18.2981 13.6894 49.2668 -18.6493 104.596 14.1823C105.691 14.7972 106.703 15.4161 107.625 16.0298L105.689 7.24319C111.383 10.0841 120.459 22.2465 120.459 22.2465C120.459 22.2465 113.307 18.5125 101.598 24.2027C100.858 24.7645 100.124 25.1595 99.4204 25.3433C100.162 24.9266 100.888 24.5474 101.598 24.2027C103.776 22.5477 105.999 19.4448 107.625 16.0298C106.608 15.3924 105.598 14.7767 104.596 14.1823C84.8938 3.10972 38.5759 -6.68883 9.23559 37.5937L1.0082e-05 38.9447Z'
        fill='#424565'
      />
    </svg>
  );
};

export default CurvedArrowTopIcon;
