import React from 'react';

const PeopleIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1136_10035)'>
        <path
          d='M33.5499 2.40582L62.3499 18.4382C63.8899 19.2999 64.4499 21.2539 63.5899 22.797C62.7299 24.3401 60.7799 24.9013 59.2399 24.0395L31.9999 8.8789L4.7499 24.0495C3.2099 24.9113 1.2599 24.3502 0.399896 22.807C-0.460104 21.2639 0.0998958 19.31 1.6399 18.4482L30.4499 2.40582C31.4199 1.86473 32.5899 1.86473 33.5599 2.40582H33.5499Z'
          fill='url(#paint0_linear_1136_10035)'
        />
        <path
          d='M32 24.8228C29.8341 24.8228 27.7568 25.7183 26.2253 27.3123C24.6937 28.9064 23.8333 31.0684 23.8333 33.3228C23.8333 35.5771 24.6937 37.7391 26.2253 39.3332C27.7568 40.9272 29.8341 41.8228 32 41.8228C34.1659 41.8228 36.2432 40.9272 37.7747 39.3332C39.3063 37.7391 40.1667 35.5771 40.1667 33.3228C40.1667 31.0684 39.3063 28.9064 37.7747 27.3123C36.2432 25.7183 34.1659 24.8228 32 24.8228ZM32 29.6799C32.9283 29.6799 33.8185 30.0637 34.4749 30.7469C35.1313 31.43 35.5 32.3566 35.5 33.3228C35.5 34.2889 35.1313 35.2155 34.4749 35.8986C33.8185 36.5818 32.9283 36.9656 32 36.9656C31.0717 36.9656 30.1815 36.5818 29.5251 35.8986C28.8687 35.2155 28.5 34.2889 28.5 33.3228C28.5 32.3566 28.8687 31.43 29.5251 30.7469C30.1815 30.0637 31.0717 29.6799 32 29.6799ZM16.8333 32.1085C15.2862 32.1085 13.8025 32.7481 12.7085 33.8867C11.6146 35.0254 11 36.5697 11 38.1799C11 40.4628 12.2367 42.4299 14.01 43.4742C14.85 43.9599 15.8067 44.2513 16.8333 44.2513C17.86 44.2513 18.8167 43.9599 19.6567 43.4742C20.52 42.9642 21.2433 42.2356 21.78 41.3613C20.0801 39.0556 19.1612 36.2291 19.1667 33.3228V32.6428C18.4667 32.3028 17.6733 32.1085 16.8333 32.1085ZM47.1667 32.1085C46.3267 32.1085 45.5333 32.3028 44.8333 32.6428V33.3228C44.8333 36.237 43.9233 39.0542 42.22 41.3613C42.5 41.8228 42.8033 42.187 43.1533 42.5513C44.2294 43.6371 45.6679 44.2465 47.1667 44.2513C48.1933 44.2513 49.15 43.9599 49.99 43.4742C51.7633 42.4299 53 40.4628 53 38.1799C53 36.5697 52.3854 35.0254 51.2915 33.8867C50.1975 32.7481 48.7138 32.1085 47.1667 32.1085ZM32 46.6799C26.54 46.6799 15.6667 49.5213 15.6667 55.1799V58.8228H48.3333V55.1799C48.3333 49.5213 37.46 46.6799 32 46.6799ZM14.99 48.0156C10.4867 48.5742 4 50.9542 4 55.1799V58.8228H11V54.1356C11 51.6828 12.61 49.6428 14.99 48.0156ZM49.01 48.0156C51.39 49.6428 53 51.6828 53 54.1356V58.8228H60V55.1799C60 50.9542 53.5133 48.5742 49.01 48.0156ZM32 51.537C35.57 51.537 39.56 52.7513 41.87 53.9656H22.13C24.44 52.7513 28.43 51.537 32 51.537Z'
          fill='url(#paint1_linear_1136_10035)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1136_10035'
          x1='67.9161'
          y1='21.5506'
          x2='-22.5439'
          y2='-12.9206'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1136_10035'
          x1='63.4323'
          y1='54.4262'
          x2='-23.0249'
          y2='35.3872'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <clipPath id='clip0_1136_10035'>
          <rect width='64' height='64' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PeopleIcon;
