import React from 'react';

const MarketingIcon: React.FC = () => {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2071_46230)'>
        <path
          d='M57 8.39994C56.3123 7.95507 55.5259 7.68593 54.7099 7.61619C53.8939 7.54644 53.0732 7.67822 52.32 7.99995L27.64 17.7199C27.0557 17.9577 26.4308 18.08 25.8 18.0799H10C8.67392 18.0799 7.40215 18.6067 6.46447 19.5444C5.52678 20.4821 5 21.7539 5 23.0799V23.4799H0V35.4799H5V35.9999C5.03133 37.3051 5.57192 38.5463 6.50623 39.4582C7.44053 40.37 8.69445 40.8803 10 40.8799L16 53.5999C16.4063 54.4565 17.0459 55.1811 17.8454 55.6906C18.645 56.2 19.572 56.4736 20.52 56.4799H23.04C24.3591 56.4694 25.6207 55.938 26.5497 55.0014C27.4788 54.0649 28 52.7991 28 51.4799V41.3599L52.32 51.0799C52.9182 51.318 53.5562 51.4402 54.2 51.4399C55.1988 51.4238 56.1715 51.1181 57 50.5599C57.6579 50.1157 58.2006 49.5212 58.5831 48.8257C58.9657 48.1301 59.1772 47.3535 59.2 46.5599V12.5199C59.1963 11.706 58.994 10.9053 58.6106 10.1873C58.2272 9.46929 57.6744 8.85575 57 8.39994ZM23 23.0799V35.9999H10V23.0799H23ZM23 51.4799H20.48L15.56 40.8799H23V51.4799ZM29.48 36.5999C29.0051 36.3572 28.5099 36.1565 28 35.9999V22.7999C28.505 22.6958 29.0001 22.5486 29.48 22.3599L54.2 12.5199V46.4399L29.48 36.5999ZM59.32 24.4799V34.4799C60.6461 34.4799 61.9179 33.9532 62.8555 33.0155C63.7932 32.0778 64.32 30.806 64.32 29.4799C64.32 28.1539 63.7932 26.8821 62.8555 25.9444C61.9179 25.0067 60.6461 24.4799 59.32 24.4799Z'
          fill='url(#paint0_linear_2071_46230)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_2071_46230'
          x1='68.2622'
          y1='50.159'
          x2='-32.7298'
          y2='32.3918'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <clipPath id='clip0_2071_46230'>
          <rect width='64' height='64' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MarketingIcon;
