import React from 'react';
import styled from 'styled-components';

import SpeakerBox from '../../atoms/SpeakerBox/SpeakerBox';

const Transcript: React.FC = () => {
  const transcript = [
    {
      speakerName: '1',
      text: '자 손흥민 선수 안녕하십니까.',
    },
    {
      speakerName: '2',
      text: '안녕하세요.',
    },
    {
      speakerName: '1',
      text: '네 오늘 결승골 정말 축하드리고 고맙습니다.',
    },
    {
      speakerName: '2',
      text: '아닙니다. 많은 분들이 또 선수들이 고생해 준 덕분에 저한테 그런 찬스가 왔다고 생각하고 또 저희가 또 많은 찬스를 못 넣으로써 되게 그 상황이 되게 천천히 지나갔던 것 같아요.',
    },
    {
      speakerName: '3',
      text: '네, 손흥민 선수 오늘 한국 축구를 사실상 구하는 그런 꼴이었습니다. ',
    },
    {
      speakerName: '1',
      text: '맞아요.',
    },
    {
      speakerName: '3',
      text: '주장으로서 부담이 굉장히 컸을 것 같은데 오늘 종아리 쪽을 많이 좀 만졌었거든요. 그래서 그런 심리적인 부담 또 육체적인 어떤 부상에 대한 어떤 위험 그런 것들이 오늘 경기 지금 끝난 상황에서 어떻습니까 돌아보면.',
    },
    {
      speakerName: '2',
      text: '어 일단은 축구 선수라면 언제 안 아픈 상태로 경기를 뛰는 적이 한 번도 없는 것 같아요. 안타까운 소리지만 그만큼 축구를 좋아하고 있고 그만큼 축구를 열정적으로 생각하고 있기 때문에 또 더군다나 대표팀의 경기는 뭐 아파도 약도 먹고 오고 하면서 하는 게 당연하다고 생각을 하는데 뭐 일단은 몸 상태는 전체적으로 괜찮고요 많이 걱정해 주시는 것만큼 심각한 상태는 아니라고 생각합니다.',
    },
    {
      speakerName: '1',
      text: '다행이네요.',
    },
    {
      speakerName: '4',
      text: '아 일단 필드골 너무 축하드리고요 그리.',
    },
    {
      speakerName: '3',
      text: '웃음이 더 많은 게 느껴집니다.',
    },
    {
      speakerName: '4',
      text: '그 실점했을 때 그 느낌 그 느낌과 공이 정확하게 이제 실점 이후에 김민재 선수가 떨어뜨려줘서 왼발에 올 때까지 시간이 한참 있었거든요. 딱 기다리면서 슈팅을 했는데 그때 그 느낌과 두 가지의 그런 느낌이 어떻게 느껴졌는지 한번 물어보고 싶어요.',
    },
    {
      speakerName: '2',
      text: '일단은 동국이 형도 마찬가지로 경기도 이런 최종 예선을 많이 치러보셨잖아요. 그렇기 때문에 어떤 느낌인지 정확히 잘 아실 거예요. 또 저희가 또 전반부터 많은 찬스가 있었기 때문에 또 그런 상황에서 제가 또 마지막 거의 진짜 마지막 찬스라고 생각을 했고 진짜 어떤 상황보다 진짜 집중해서 진짜 볼만 골대에다 보낸다는 생각으로 진짜 살살 찼던 것 같아요. 그 상황에 또 잘 맞았고 또 좋았던 것 같습니다.',
    },
    {
      speakerName: '1',
      text: '아 정말 대한민국이 지금 완전히 흔들렸습니다. 네 지금 그 몇 분 동안 위기였기 때문에 정말 초조했었는데 결승골 그리고 또 오랜만에 또 대표팀에서 오픈 플레이 골 터뜨린 건 정말 축하드립니다.',
    },
    {
      speakerName: '2',
      text: '네 너무 감사합니다.',
    },
    {
      speakerName: '3',
      text: '저 마지막 질문 하나 있는데요. 이제 이란 원정 가야 되는데 뭐 뻔한 질문일 수 있겠지만 이란 원정을 기대하고 또 걱정하고 있는 팬들에게 주장으로서 한 말씀 부탁드리겠습니다.',
    },
    {
      speakerName: '2',
      text: '사실 참 매일 경기 홈에서도 하는 경기도 마찬가지고 어웨이에서 하는 경기도 정말 매 경기 매 경기 정말 힘든 상황인 것 같아요. 근데도 이란 원정은 특히나 정말 어려운 원정이기 때문에 저희가 또 비행기를 타고 가서 준비할 시간이 많이 부족하긴 하지만 또 모든 팀들이 그런 어웨이 경기에 왔을 때 그런 똑같은 상황이라고 생각을 해요. 하지만 그런 어려운 상황 속에서 저희가 좀 안 좋았던 그런 뭐라고 해야 되지 런들 또 흐름들 또 그런 상황을 빨리 떨쳐낼 수 있도록 선수들하고 또 많은 얘기를 하면서 좋은 경기를 치를 수 있도록 노력 많이 하겠습니다.',
    },
    {
      speakerName: '1',
      text: '알겠습니다. 오늘 풀타임 활약 정말 축하드리고 고맙습니다.',
    },
    {
      speakerName: '2',
      text: '네 감사합니다 고생하셨습니다.',
    },
  ];

  return (
    <TranscriptWrapper>
      {transcript.map((t, index) => (
        <SpeakerBox key={`key${index}`} text={t.text} speakerName={t.speakerName} />
      ))}
    </TranscriptWrapper>
  );
};

const TranscriptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 36px;
  background: ${(props) => props.theme.colors.lightBckg};
  overflow-y: scroll;
  height: 300px;
`;
export default Transcript;
