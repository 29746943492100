import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';

interface TextProps {
  type: string;
  children: React.ReactNode;
  color?: string;
  hoverColor?: string;
}

const Text: React.FC<TextProps> = ({ type, color, children, hoverColor }) => {
  const { i18n } = useTranslation();

  return (
    <TextWrapper
      type={type}
      color={color ?? 'text'}
      hoverColor={hoverColor}
      language={i18n.language}
    >
      {children}
    </TextWrapper>
  );
};

const TextWrapper = styled.div<{
  type: string;
  color: string;
  hoverColor?: string;
  language: string;
}>`
  font-family: ${(props) => (props.language === 'ko' ? 'Noto Sans KR' : 'Manrope')};

  font-size: ${(props) => props.theme.fonts.desktop[props.language][props.type].fontSize};
  font-weight: ${(props) => props.theme.fonts.desktop[props.language][props.type].fontWeight};
  line-height: ${(props) => props.theme.fonts.desktop[props.language][props.type].lineHeight};
  color: ${(props) => props.theme.colors[props.color]};

  @media only screen and ${deviceBreakpoints.mobile} {
    font-size: ${(props) => props.theme.fonts.mobile[props.language][props.type].fontSize};
    font-weight: ${(props) => props.theme.fonts.mobile[props.language][props.type].fontWeight};
    line-height: ${(props) => props.theme.fonts.mobile[props.language][props.type].lineHeight};
  }

  &:hover {
    color: ${(props) =>
      props.hoverColor ? props.theme.colors[props.hoverColor] : props.theme.colors[props.color]};
  }
`;

export default Text;
