import { createGlobalStyle } from 'styled-components';

import ManropeENBold from './fonts/Manrope-Bold.ttf';
import ManropeENLight from './fonts/Manrope-Light.ttf';
import ManropeENMedium from './fonts/Manrope-Medium.ttf';
import ManropeENRegular from './fonts/Manrope-Regular.ttf';
import NotoSansKRBold from './fonts/NotoSansKR-Bold.otf';
import NotoSansKRLight from './fonts/NotoSansKR-Light.otf';
import NotoSansKRMedium from './fonts/NotoSansKR-Medium.otf';
import NotoSansKRRegular from './fonts/NotoSansKR-Regular.otf';
import { deviceBreakpoints } from './Theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalStyle = createGlobalStyle<any>`

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
* {
  font-family: Noto Sans KR;
  font-style: normal;
  word-break: keep-all;
}

.main-container {
  grid-template-columns: repeat(12, [col-start] 1fr);
  display: grid;
  gap: 20px;
  max-width: 1440px;
  margin: 0 auto;

  @media only screen and ${deviceBreakpoints.mobile} {
    grid-template-columns: repeat(3, [col-start] 1fr);
    gap: 12px;
  }
}

.main-container-header {
  grid-template-columns: repeat(12, [col-start] 1fr);
  display: grid;
  gap: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 8px;
  padding-right: 8px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  @media only screen and ${deviceBreakpoints.largeDesktop} {
    margin: 0 auto;
  }
}

@font-face {
  font-family: Noto Sans KR;
  src: url(${NotoSansKRRegular}) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Noto Sans KR;
  src: url(${NotoSansKRBold}) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Noto Sans KR;
  src: url(${NotoSansKRMedium}) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Noto Sans KR;
  src: url(${NotoSansKRLight}) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url(${ManropeENRegular}) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url(${ManropeENBold}) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url(${ManropeENMedium}) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url(${ManropeENLight}) format('truetype');
  font-weight: 300;
  font-style: normal;
}

.col-start-1 {grid-column-start: 1}
.col-start-2 {grid-column-start: 2}
.col-start-3 {grid-column-start: 3}
.col-start-4 {grid-column-start: 4}
.col-start-5 {grid-column-start: 5}
.col-start-6 {grid-column-start: 6}
.col-start-7 {grid-column-start: 7}
.col-start-8 {grid-column-start: 8}
.col-start-9 {grid-column-start: 9}
.col-start-10 {grid-column-start: 10}
.col-start-11 {grid-column-start: 11}
.col-start-12 {grid-column-start: 12}

.col-end-1 {grid-column-end: 1}
.col-end-2 {grid-column-end: 2}
.col-end-3 {grid-column-end: 3}
.col-end-4 {grid-column-end: 4}
.col-end-5 {grid-column-end: 5}
.col-end-6 {grid-column-end: 6}
.col-end-7 {grid-column-end: 7}
.col-end-8 {grid-column-end: 8}
.col-end-9 {grid-column-end: 9}
.col-end-10 {grid-column-end: 10}
.col-end-11 {grid-column-end: 11}
.col-end-12 {grid-column-end: 12}
.col-end-none {grid-column-end: none}

@media only screen and ${deviceBreakpoints.mobile} {
  .col-start-1-m {grid-column-start: 1}
  .col-start-2-m {grid-column-start: 2}
  .col-start-3-m {grid-column-start: 3}
  .col-start-4-m {grid-column-start: 4}
  .col-start-5-m {grid-column-start: 5}
  .col-start-6-m {grid-column-start: 6}
  .col-start-7-m {grid-column-start: 7}
  .col-start-8-m {grid-column-start: 8}
  .col-start-9-m {grid-column-start: 9}
  .col-start-10-m {grid-column-start: 10}
  .col-start-11-m {grid-column-start: 11}
  .col-start-12-m {grid-column-start: 12}
  .col-end-1-m {grid-column-end: 1}
  .col-end-2-m {grid-column-end: 2}
  .col-end-3-m {grid-column-end: 3} 
  .col-end-4-m {grid-column-end: 4}
  .col-end-5-m {grid-column-end: 5}
  .col-end-6-m {grid-column-end: 6} 
  .col-end-7-m {grid-column-end: 7}
  .col-end-8-m {grid-column-end: 8}
  .col-end-9-m {grid-column-end: 9} 
  .col-end-10-m {grid-column-end: 10}
  .col-end-11-m {grid-column-end: 11}
  .col-end-12-m {grid-column-end: 12} 
  .col-end-none-m {grid-column-end: none}
}

@media only screen and ${deviceBreakpoints.tablet} {
  .col-start-1-t {grid-column-start: 1}
  .col-start-2-t {grid-column-start: 2}
  .col-start-3-t {grid-column-start: 3}
  .col-start-4-t {grid-column-start: 4}
  .col-start-5-t {grid-column-start: 5}
  .col-start-6-t {grid-column-start: 6}
  .col-start-7-t {grid-column-start: 7}
  .col-start-8-t {grid-column-start: 8}
  .col-start-9-t {grid-column-start: 9}
  .col-start-10-t {grid-column-start: 10}
  .col-start-11-t {grid-column-start: 11}
  .col-start-12-t {grid-column-start: 12}

  .col-end-1-t {grid-column-end: 1}
  .col-end-2-t {grid-column-end: 2}
  .col-end-3-t {grid-column-end: 3}
  .col-end-4-t {grid-column-end: 4}
  .col-end-5-t {grid-column-end: 5}
  .col-end-6-t {grid-column-end: 6}
  .col-end-7-t {grid-column-end: 7}
  .col-end-8-t {grid-column-end: 8}
  .col-end-9-t {grid-column-end: 9}
  .col-end-10-t {grid-column-end: 10}
  .col-end-11-t {grid-column-end: 11}
  .col-end-12-t {grid-column-end: 12}
  .col-end-none-t {grid-column-end: none}
}

@media only screen and ${deviceBreakpoints.desktop} {
  .col-start-1-d {grid-column-start: 1}
  .col-start-2-d {grid-column-start: 2}
  .col-start-3-d {grid-column-start: 3}
  .col-start-4-d {grid-column-start: 4}
  .col-start-5-d {grid-column-start: 5}
  .col-start-6-d {grid-column-start: 6}
  .col-start-7-d {grid-column-start: 7}
  .col-start-8-d {grid-column-start: 8}
  .col-start-9-d {grid-column-start: 9}

  .col-end-1-d {grid-column-end: 1}
  .col-end-2-d {grid-column-end: 2}
  .col-end-3-d {grid-column-end: 3}
  .col-end-4-d {grid-column-end: 4}
  .col-end-5-d {grid-column-end: 5}
  .col-end-6-d {grid-column-end: 6}
  .col-end-7-d {grid-column-end: 7}
  .col-end-8-d {grid-column-end: 8}
  .col-end-9-d {grid-column-end: 9}
}

@media only screen and ${deviceBreakpoints.largeDesktop} {
  .col-start-1-bd {grid-column-start: 1}
  .col-start-2-bd {grid-column-start: 2}
  .col-start-3-bd {grid-column-start: 3}
  .col-start-4-bd {grid-column-start: 4}
  .col-start-5-bd {grid-column-start: 5}
  .col-start-6-bd {grid-column-start: 6}
  .col-start-7-bd {grid-column-start: 7}
  .col-start-8-bd {grid-column-start: 8}
  .col-start-9-bd {grid-column-start: 9}

  .col-end-1-bd {grid-column-end: 1}
  .col-end-2-bd {grid-column-end: 2}
  .col-end-3-bd {grid-column-end: 3}
  .col-end-4-bd {grid-column-end: 4}
  .col-end-5-bd {grid-column-end: 5}
  .col-end-6-bd {grid-column-end: 6}
  .col-end-7-bd {grid-column-end: 7}
  .col-end-8-bd {grid-column-end: 8}
  .col-end-9-bd {grid-column-end: 9}
} 

.PhoneInputInput {
  height: 50px;
  border: none;
  border-bottom: 1px solid #888CAB;

  :focus-visible {
    outline: none;
    border-bottom: 1px solid #888CAB;
    background-color: transparent ! important;
  }
}










`;

export default GlobalStyle;
