import React from 'react';

const GoalIcon: React.FC = () => {
  return (
    <svg width='65' height='64' viewBox='0 0 65 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M55.2145 9.45199H55.2105L61.9145 10.792C62.2745 10.864 62.5705 11.128 62.6745 11.484C62.727 11.6569 62.7317 11.8409 62.688 12.0162C62.6444 12.1916 62.554 12.3519 62.4265 12.48L56.9345 17.976C56.2774 18.6319 55.3869 19.0001 54.4585 19H49.9065L38.4665 30.444C38.6848 31.2681 38.7249 32.1292 38.5842 32.97C38.4436 33.8108 38.1254 34.612 37.6508 35.3202C37.1762 36.0284 36.5562 36.6273 35.832 37.0771C35.1078 37.5269 34.296 37.8172 33.4509 37.9286C32.6057 38.0401 31.7465 37.9701 30.9304 37.7234C30.1144 37.4768 29.3603 37.0591 28.7184 36.4981C28.0764 35.9371 27.5614 35.2458 27.2076 34.4702C26.8538 33.6946 26.6693 32.8525 26.6665 32C26.6671 31.0796 26.8793 30.1716 27.2868 29.3462C27.6942 28.5209 28.2859 27.8003 29.0163 27.24C29.7466 26.6798 30.5959 26.295 31.4987 26.1154C32.4014 25.9357 33.3334 25.9661 34.2225 26.204L45.6665 14.756V10.208C45.6665 9.27999 46.0345 8.38799 46.6905 7.73199L52.1865 2.23599C52.3146 2.1085 52.4749 2.01812 52.6503 1.97445C52.8256 1.93078 53.0096 1.93546 53.1825 1.98799C53.5385 2.09199 53.8025 2.38799 53.8745 2.74799L55.2145 9.45199Z'
        fill='url(#paint0_linear_1136_10025)'
      />
      <path
        d='M8.66628 32.0002C8.67039 35.432 9.41046 38.8231 10.8366 41.9446C12.2626 45.0661 14.3416 47.8455 16.9332 50.0952C19.5248 52.3449 22.5687 54.0127 25.8596 54.986C29.1506 55.9593 32.612 56.2155 36.0103 55.7373C39.4087 55.2591 42.665 54.0577 45.5596 52.214C48.4541 50.3704 50.9196 47.9274 52.7898 45.0499C54.6599 42.1723 55.8911 38.9272 56.4005 35.5333C56.9098 32.1395 56.6854 28.6759 55.7423 25.3762C55.6099 24.9907 55.5573 24.5822 55.5878 24.1758C55.6183 23.7693 55.7311 23.3733 55.9195 23.0118C56.108 22.6504 56.368 22.331 56.6837 22.0733C56.9995 21.8155 57.3644 21.6247 57.7563 21.5125C58.1481 21.4002 58.5587 21.3689 58.9631 21.4205C59.3674 21.472 59.757 21.6052 60.1082 21.8122C60.4594 22.0191 60.7648 22.2953 61.0059 22.624C61.2469 22.9527 61.4185 23.327 61.5103 23.7242C63.3364 30.1143 62.9895 36.9298 60.5241 43.1015C58.0587 49.2732 53.6142 54.4519 47.8879 57.825C42.1617 61.1982 35.4777 62.5749 28.8845 61.7394C22.2913 60.9038 16.162 57.9031 11.4583 53.2082C6.75965 48.5056 3.75578 42.3757 2.9183 35.781C2.08081 29.1863 3.45713 22.5002 6.83117 16.7725C10.2052 11.0447 15.3859 6.59963 21.5599 4.13514C27.7338 1.67065 34.5514 1.32631 40.9423 3.15618C41.7033 3.37862 42.3452 3.89325 42.7279 4.58759C43.1106 5.28192 43.2029 6.09951 42.9846 6.86168C42.7663 7.62385 42.2552 8.26862 41.5629 8.65509C40.8707 9.04156 40.0536 9.13831 39.2903 8.92418C35.7166 7.89884 31.9536 7.71763 28.2979 8.39484C24.6423 9.07205 21.1939 10.5892 18.2246 12.8266C15.2554 15.064 12.8465 17.9605 11.1877 21.2879C9.529 24.6152 8.66583 28.2823 8.66628 32.0002Z'
        fill='url(#paint1_linear_1136_10025)'
      />
      <path
        d='M20.6665 31.9999C20.6667 34.1863 21.2639 36.3311 22.3935 38.203C23.5232 40.0749 25.1425 41.6029 27.0767 42.6221C29.011 43.6413 31.1868 44.1131 33.3695 43.9865C35.5522 43.8599 37.659 43.1399 39.4625 41.9039C41.2656 40.6649 42.6958 38.9565 43.5982 36.9634C44.5006 34.9703 44.841 32.7685 44.5825 30.5959C44.5125 30.0687 44.5844 29.5324 44.7907 29.0423C44.9969 28.5521 45.3301 28.1257 45.7559 27.8071C46.1817 27.4884 46.6847 27.2891 47.2131 27.2295C47.7416 27.1698 48.2764 27.2522 48.7625 27.4679C49.2484 27.6809 49.6692 28.019 49.9819 28.4476C50.2946 28.8762 50.488 29.3802 50.5425 29.9079C50.9771 33.6203 50.2453 37.376 48.4485 40.6536C46.6518 43.9311 43.8793 46.5681 40.5158 48.1984C37.1524 49.8288 33.3648 50.3716 29.6788 49.7516C25.9929 49.1316 22.5913 47.3796 19.9463 44.7386C17.3013 42.0976 15.5441 38.6987 14.9185 35.0137C14.2929 31.3286 14.83 27.5403 16.4552 24.1743C18.0804 20.8084 20.7132 18.0319 23.988 16.2302C27.2629 14.4285 31.0174 13.691 34.7305 14.1199C35.128 14.1568 35.5143 14.2727 35.8665 14.4608C36.2187 14.6489 36.5298 14.9054 36.7816 15.2153C37.0334 15.5252 37.2208 15.8822 37.3328 16.2655C37.4448 16.6487 37.4792 17.0505 37.434 17.4472C37.3887 17.8439 37.2647 18.2276 37.0692 18.5758C36.8738 18.9239 36.6107 19.2296 36.2956 19.4748C35.9805 19.72 35.6196 19.8998 35.2341 20.0037C34.8485 20.1076 34.4461 20.1336 34.0505 20.0799C32.3691 19.8847 30.6654 20.0474 29.0514 20.5573C27.4374 21.0673 25.9495 21.9129 24.6855 23.0386C23.4214 24.1644 22.4099 25.5448 21.7172 27.0893C21.0245 28.6338 20.6665 30.3073 20.6665 31.9999Z'
        fill='url(#paint2_linear_1136_10025)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1136_10025'
          x1='64.9273'
          y1='33.3204'
          x2='7.59534'
          y2='25.6517'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1136_10025'
          x1='66.3419'
          y1='54.2214'
          x2='-29.0548'
          y2='41.4657'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1136_10025'
          x1='52.871'
          y1='45.3458'
          x2='-4.38045'
          y2='37.6913'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5D6BE9' />
          <stop offset='0.5' stopColor='#55B8FF' />
          <stop offset='1' stopColor='#32E0D6' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GoalIcon;
