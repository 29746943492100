import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import gradientBackground from '../../../assets/gradientBackground.png';
import { deviceBreakpoints } from '../../../Theme';
import Text from '../Text/Text';

interface ButtonStyleProps {
  size?: 'large' | 'medium' | 'small';
  disabled?: boolean;
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: 'regular' | 'accent' | 'blue';
  text: string;
  onClickAction: () => void;
  className?: string;
}

const Button: React.FC<ButtonProps & ButtonStyleProps> = ({
  buttonType,
  text,
  size,
  onClickAction,
  className,
  ...other
}) => {
  if (buttonType === 'regular') {
    return (
      <StyledButton size={size} onClick={onClickAction} className={className} {...other}>
        <TextWrapper>
          <Text color={'white'} type={'link'}>
            {text}
          </Text>
        </TextWrapper>
        <HoverBackground />
      </StyledButton>
    );
  }

  if (buttonType === 'accent') {
    return (
      <StyledAccentButton size={size} onClick={onClickAction} className={className} {...other}>
        <Text color={'white'} type={'link'}>
          {text}
        </Text>
      </StyledAccentButton>
    );
  }

  if (buttonType === 'blue') {
    return (
      <StyledBlueButton size={size} onClick={onClickAction} className={className} {...other}>
        <Text color={'white'} type={'link'}>
          {text}
        </Text>
      </StyledBlueButton>
    );
  }

  return <StyledButton size={size} />;
};

const BaseButton = styled.button<ButtonStyleProps>`
  padding: 16px 20px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  min-width: 155px;
  cursor: pointer;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 12px 16px;
    min-width: 128px;
  }
`;

const TextWrapper = styled.div`
  z-index: 2;
`;

const HoverBackground = styled.div`
  background: ${(props) => props.theme.colors.purple};
  border-radius: 50px;
  width: 110%;
  height: 80px;
  position: absolute;
  transform: translateX(180px);
`;

const StyledButton = styled(BaseButton)`
  border: none;
  background-image: url(${gradientBackground});
  opacity: ${(props) => (props.disabled ? '50%' : '100%')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-position: right 20%;
  position: relative;
  overflow: hidden;
  outline: none;
  transform: translateZ(0);

  &:hover ${HoverBackground} {
    transform: translateX(0px);
    transition: 0.5s;
  }

  &:focus {
    outline: none;
  }
`;

const StyledAccentButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.accent};
`;

const StyledBlueButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.purple};
  border: none;
  padding: 12px 24px;

  &:hover {
    background-color: ${(props) => props.theme.colors.text};
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 8px 16px;
  }
`;

export default Button;
