import React from 'react';
import { useTranslation } from 'react-i18next';

import TopSection from '../../../components/organisms/TopSection/TopSection';

const Top: React.FC = () => {
  const { t } = useTranslation(['main']);

  return <TopSection title={t('mainTitle')} description={t('mainDescription')} />;
};

export default Top;
