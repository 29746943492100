import React from 'react';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';
import Text from '../Text/Text';

interface TagProps {
  text: string;
  type: 'light' | 'dark';
}

const Tag: React.FC<TagProps> = ({ text, type }) => {
  return type === 'dark' ? (
    <StyledTagDark>
      <Text color='white' type='link'>
        {text}
      </Text>
    </StyledTagDark>
  ) : (
    <StyledTagLight>
      <Text color='text' type='text'>
        {text}
      </Text>
    </StyledTagLight>
  );
};

const StyledTagDark = styled.div`
  padding: 9px 18px;
  border-radius: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.accent};
  width: fit-content;
  white-space: nowrap;
  min-width: 92px;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: '9px 12px';
    min-width: 70px;
  }
`;

const StyledTagLight = styled.div`
  padding: 8px 20px;
  background-color: ${(props) => props.theme.colors.lightBckg};
  border-radius: 20px;
`;

export default Tag;
